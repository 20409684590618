import DateTimeService, { dateTimeNow } from '../DateTimeService'
import moment from 'moment'
import _ from 'lodash'
import ParserOnSave from '@/services/tinymce/ParserOnSave'
import CoreApi from '@/api/core'
import ArticleModel from '../../model/ArticleModel'
import { uuid } from 'vue-uuid'

const prepareApiRequest = function (editedArticle) {
  const article = _.cloneDeep(editedArticle)
  if (article.rubrics) {
    article.rubrics = article.rubrics.map(record => record.id)
  }
  if (article.categories) {
    article.categories = article.expanded.categories.map(record => record.id)
  }
  if (article.owners) {
    article.owners = article.expanded.owners.map(record => record.id)
  }
  if (article.sharedUsers) {
    article.sharedUsers = article.expanded.sharedUsers.map(record => record.id)
  }
  if (article.authors) {
    article.authors = article.expanded.authors?.map(record => record.id)
  }
  if (article.doctors) {
    article.doctors = article.expanded.doctors.map(record => record.id)
  }
  article.heroImage = null
  if (article.expanded && article.expanded.heroImage) {
    article.heroImage = article.expanded.heroImage.id
  }
  article.listingImage = null
  if (article.expanded && article.expanded.listingImage) {
    article.listingImage = article.expanded.listingImage.id
  }
  if (article.gallery) {
    article.gallery = article.expanded.gallery.filter(record => Boolean(record)).map(record => record.id)
  }
  if (article.tags) {
    article.tags = article.expanded.tags.map(record => record.id)
  }
  if (article.orderDate === '') {
    article.orderDate = dateTimeNow()
  }
  if (article.publishedSince === '') {
    article.publishedSince = article.orderDate
  }
  if (article.publishedUntil === '') {
    delete article.publishedUntil
  }
  if (moment(article.orderDate) > moment(article.publishedSince)) {
    article.publishedSince = article.orderDate
  }
  if (article.flag && article.flag.updatedUntilDatetime === '') {
    article.flag.updatedUntilDatetime = DateTimeService.dateTimeMin
  }
  if (article.flag && article.flag.justNowUntilDatetime === '') {
    article.flag.justNowUntilDatetime = DateTimeService.dateTimeMin
  }
  delete article.articleEmbeds
  delete article.articleLinks
  delete article.routes
  delete article.firstPublishedDate
  if (article.setting && article.setting.scheduledPublish) {
    delete article.setting.scheduledPublish
  }
  if (article.setting && article.setting.abTested) {
    let variantA = article.abTestVariants.find(item => item.variant === 'a')
    if (variantA === undefined) {
      variantA = ArticleModel.abTestVariants[0]
      article.abTestVariants.push(variantA)
    }
    variantA.title = article.field.title
    if (!article.expanded.heroImage) {
      variantA.image = null
    } else {
      const heroImageChanged = variantA.image?.damMediaEmbed?.damId !== article.expanded.heroImage?.damMediaEmbed?.damId
      if (article.expanded && heroImageChanged) {
        variantA.image = _.cloneDeep(article.expanded.heroImage)
        variantA.image.id = null // core-cms will create new image for variantA
        variantA.image.entityUuid = uuid.v4()
        variantA.image.articleEmbed = null
      }
    }
  } else if (isEmptyAbTestVariantTitle(article.abTestVariants)) {
    delete article.abTestVariants
  }
  if (article.hpTopicOffer) {
    article.hpTopicOffer = article.hpTopicOffer.filter((hpTopicOffer) => hpTopicOffer.site !== null)
  }
  if (article.expanded) {
    delete article.expanded
  }

  return article
}

const getArticleUrl = function (article) {
  const route = article.routes.find((route) => {
    if (route.isMain) {
      return route
    }
  })
  if (route) {
    return route.absoluteUrl
  }
  return ''
}

const getGalleryUrl = function (article) {
  const route = article.routes.find((route) => {
    if (route.isMain) {
      return route
    }
  })
  if (route) {
    const url = new URL(route.absoluteUrl)
    return url.protocol + '//' + url.hostname + ':' + url.port + '/gal' + url.pathname
  }
  return ''
}

const isEmptyAbTestVariantTitle = function (variants) {
  if (variants === undefined) return true
  return variants.some((variant) => variant.title === '')
}

const getBodyBlocksFromOldEditor = async (originalArticle) => {
  const article = _.cloneDeep(originalArticle)
  article.field.bodyText = await ParserOnSave.parseAll(article.field.bodyText)
  return CoreApi().post('/articleFieldToBlocksConversion', JSON.stringify(prepareApiRequest(article)))
    .then(response => {
      return response.data.bodyBlocks
    })
    .catch(error => {
      console.error(error)
      return []
    })
}

const getBodyBlocks = async (originalArticle) => {
  let blocks = originalArticle.bodyBlocks
  if (!originalArticle.setting.editedInNewEditor && !blocks?.length && originalArticle.field.bodyText) {
    blocks = await getBodyBlocksFromOldEditor(originalArticle)
  }
  // pass empty array in case of replacing with unsaved version that has empty blocks
  return blocks ?? []
}

export default {
  prepareApiRequest,
  getArticleUrl,
  getGalleryUrl,
  getBodyBlocks
}
