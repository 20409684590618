<template>
  <div></div>
</template>

<script>
const REFRESH_TOKEN_INTERVAL = 600000

export default {
  name: 'UserRefreshToken',
  data () {
    return {
      refreshTokenId: 0
    }
  },
  methods: {
    refreshToken () {
      this.refreshTokenId = setInterval(() => {
        this.$store.dispatch('user/refreshToken')
      }, REFRESH_TOKEN_INTERVAL)
    }
  },
  created () {
    this.refreshToken()
  },
  beforeDestroy () {
    clearInterval(this.refreshTokenId)
  }
}
</script>
