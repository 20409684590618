export default {
  id: 0,
  site: null,
  hiddenInMenu: 0,
  parent: null,
  title: '',
  sorting: 0,
  meta: {
    title: '',
    description: '',
    keywords: ''
  },
  setting: {
    advertCategory: '',
    advertSection: '',
    chartbeatSection: '',
    gemiusId: '',
    prRubric: 0,
    special: 0
  },
  sportTable: {
    seasonId: 0,
    activeTab: 1
  },
  slug: '',
  routes: [],
  iptcCategories: [],
  rubricWithoutArticles: 0
}
