import { ROLE_SUPER_ADMIN, ROLE_ESHOP_ADMIN } from '../../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'feUserPollVote',
    component: () => import('../../../components/feUser/pollVote/FeUserPollVoteList'),
    name: 'feUserPollVote_list',
    meta: {
      description: 'FeUser poll votes list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_ESHOP_ADMIN
      ]
    }
  }
]

export default routes
