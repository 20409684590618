const messages = {
  filter: {
    complete: 'Complete',
    fulltext_search: 'Search',
    fulltext_search_placeholder: 'Fulltext (caption, description, headline, keywords)',
    date_from: 'Uploaded from',
    date_to: 'Uploaded to',
    description: 'Description',
    keywords: 'Keywords',
    author: 'Author',
    brand: 'Uploaded by brand',
    id: 'Id',
    reset_filter: 'Reset filter',
    upload_source: 'Upload source',
    orientation: 'Orientation',
    orientation_landscape: 'Landscape',
    orientation_portrait: 'Portrait',
    orientation_square: 'Square',
    size: 'Size',
    size_gt: 'Minimum size {size}MB',
    resolution: 'Resolution',
    resolution_gt: 'Mininum resolution {resolution} Mpx',
    created_by: 'Uploaded by',
    show_by: 'Display by',
    show_by_upload_date: 'DAM upload date',
    show_by_original_date: 'Capture date',
    search: 'Search',
    restricted_sites_placeholder: 'Restriction to web sites',
    restricted_sites: 'Restricted sites',
    show_thumbnail_placeholder: 'First page preview',
    person_in_image: 'Person in photo',
    date_origin_from: 'Captured from',
    date_origin_to: 'Captured to',
    photo_series: 'Photo series',
    pdf: {
      date_origin_from: 'Created from',
      date_origin_to: 'Created to'
    }
  },
  selected_photos_count: 'Selected photos count',
  display_type_compact: 'Compact view',
  display_type_full: 'Full view',
  load_more: 'Load more',
  asset_image_info: 'Photo detail',
  asset_pdf_info: 'PDF detail',
  dam_generic_info: 'DAM Generic info',
  update_asset_metadata: 'Update metadata',
  system_info: 'System info',
  article_reference: 'Article usage',
  id: 'Id',
  filename: 'Filename',
  file_location: 'File location',
  created: 'Uploaded to DAM',
  upload_source: 'Upload_source',
  restricted_sites: 'Restricted sites',
  person_in_image: 'Person in image',
  size: 'Size',
  dimensions: 'Dimensions',
  datetime_original: 'Capture date',
  datetime_original_pdf: 'Creation date',
  category: 'Category',
  published: 'Published',
  brand_safety: 'Brand safety',
  location_show: 'Location show',
  persion_in_image: 'Person in image',
  metadata: 'Metadata',
  headline: 'Headline',
  by_line: 'By line',
  caption: 'Caption',
  content: 'Content',
  content_load: 'Load content',
  copy_id: 'Copy ID',
  caption_writer: 'Caption writer',
  city: 'City',
  country: 'Country',
  country_code: 'Country code',
  description: 'Description',
  keywords: 'Keywords',
  credit: 'Credit',
  rights: 'Rights',
  source: 'Source',
  delete_modal_header: 'Delete this {{recordTypeName}}?',
  delete_more_modal_image: 'images',
  delete_more_modal_documents: 'documents',
  delete_more_modal_header: 'Do you really want to delete all selected {{recordTypeName}}? This action cannot be undone.',
  delete_more_modal_body: '<p>Naozaj si želáte vymazať {{recordTypeName}} z Eagle CMS? Táto operácia je nezvratná a {{recordTypeName}} budú vymazané z DAM a zo všetkých článkov, kde sú použité.</p>' +
    '<p><strong>Táto operácia sa dotkne týchto článkov.</strong> V rámci korektnosti, prosím, informujte autorov článkov, že dané {{recordTypeName}} boli zmazané!</p>',
  delete_modal_close: 'Close',
  delete_modal_yes: 'Yes, delete this record',
  crop_modal_header: 'Crop photo',
  info_modal_header: 'Primary information',
  crop_modal_face_detect: 'Face detect',
  crop_modal_save: 'Save Crop',
  crop_modal_was_set: 'Crop was set.',
  image_check_info_max_size: 'Obrázok presahuje maximálne povolenú veľkosť 99 MB',
  image_check_info_low_quality: 'Pridaná fotografia má nízku kvalitu.',
  drop_files_here: 'Drop files here',
  rotate_plus_90: 'Rotate +90°',
  rotate_minus_90: 'Rotate -90°',
  rotate_error: 'Rotate error.',
  download_original: 'Download original',
  insert_into_article: 'Insert photos into article',
  create_gallery_insert_into_article: 'Create gallery and insert into article',
  asset_exists_in_gallery: 'Asset {title} already exists in gallery and will not be added',
  public_info: 'Public gallery is accessible for whole NMH after joined article is published, non-public is accessible for the concrete brand.'
}

export default messages
