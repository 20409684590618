import { ROLE_RESTAURANT_HOTEL_EDITOR, ROLE_SUPER_ADMIN } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'articleTest/restaurant/new',
    component: () => import('../../views/article/ArticleTestRestaurantNewView'),
    name: 'restaurant_new',
    meta: {
      description: 'Restaurant new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_RESTAURANT_HOTEL_EDITOR
      ]
    }
  },
  {
    path: 'articleTest/restaurant/:id/edit',
    component: () => import('../../views/article/ArticleTestRestaurantEditView'),
    name: 'restaurant_edit',
    meta: {
      description: 'Restaurant edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_RESTAURANT_HOTEL_EDITOR
      ]
    }
  },
  {
    path: 'articleTest/restaurant/:id',
    component: () => import('../../views/article/ArticleTestRestaurantView'),
    name: 'restaurant_detail',
    meta: {
      description: 'Restaurant detail',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_RESTAURANT_HOTEL_EDITOR
      ]
    }
  },
  {
    path: 'articleTest/restaurant',
    component: () => import('../../views/article/ArticleTestRestaurantListView'),
    name: 'restaurant_list',
    meta: {
      description: 'Restaurant list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_RESTAURANT_HOTEL_EDITOR
      ]
    }
  }
]

export default routes
