import appConfig from '../config'
import Store from '@/store'

// NMH - "dev" "staging" "production"
// VLM - "dev" "beta" "staging" "production"

const getEnvironmentVisualSettings = () => {
  const vlm = Store.getters.vlm
  const environmentFromConfig = appConfig.environment()
  const devColor = '#FF3455'
  const devTestColor = '#9923fe'
  const customerTestColor = '#ffa200'
  let environmentVisualSettings = {
    color: '',
    name: ''
  }

  if (vlm) {
    switch (environmentFromConfig) {
      case 'dev':
        environmentVisualSettings.color = devColor
        environmentVisualSettings.name = 'Dev'
        break
      case 'beta':
        environmentVisualSettings.color = devTestColor
        environmentVisualSettings.name = 'Beta'
        break
      case 'staging03':
        environmentVisualSettings.color = devTestColor
        environmentVisualSettings.name = 'Staging-03'
        break
      case 'staging':
        environmentVisualSettings.color = customerTestColor
        environmentVisualSettings.name = 'Staging'
        break
      case 'gamma':
        environmentVisualSettings.color = customerTestColor
        environmentVisualSettings.name = 'Gamma'
        break

      default:
        environmentVisualSettings = {
          color: '',
          name: ''
        }
    }
  } else {
    switch (environmentFromConfig) {
      case 'dev':
        environmentVisualSettings.color = devColor
        environmentVisualSettings.name = 'Dev'
        break
      case 'staging':
        environmentVisualSettings.color = devTestColor
        environmentVisualSettings.name = 'Staging'
        break

      default:
        environmentVisualSettings = {
          color: '',
          name: ''
        }
    }
  }

  if (environmentFromConfig === 'dev') {
    environmentVisualSettings.coreApiUrl = new URL(appConfig.core.apiUrl() ?? '')?.host ?? ''
  } else {
    environmentVisualSettings.coreApiUrl = ''
  }

  return environmentVisualSettings
}

export default {
  getEnvironmentVisualSettings
}
