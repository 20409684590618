import { ROLE_SUPER_ADMIN, ROLE_ESHOP_ADMIN } from '../../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'shop/subscriber',
    component: () => import('../../../components/eshop/SubscriberList'),
    name: 'eshopSubscriber_list',
    meta: {
      description: 'Subscriber list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_ESHOP_ADMIN
      ]
    }
    // },
    // {
    //   path: 'shop/subscriber/:id',
    //   component: () => import('../../components/eshop/Subscriber'),
    //   name: 'eshopSubscriptionOrder_detail',
    //   meta: {
    //     description: 'Subscription Order detail',
    //     requiresAuth: true,
    //     requiredRoles: [
    //       ROLE_SUPER_ADMIN
    //     ]
    //   }
  }
]

export default routes
