import { ROLE_SUPER_ADMIN, ROLE_DON_USER } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'nativePerfArticle/new',
    component: () => import('../../components/don/NativePerfArticleNew'),
    name: 'don_npa_new',
    meta: {
      description: 'Create a Native Perf Article',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_DON_USER
      ]
    }
  },
  {
    path: 'nativePerfArticle/:id/edit',
    component: () => import('../../components/don/NativePerfArticleEdit'),
    name: 'don_npa_edit',
    meta: {
      description: 'Edit a Native Perf Article',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_DON_USER
      ]
    }
  },
  {
    path: 'nativePerfArticle/:id/duplicate',
    component: () => import('../../components/don/NativePerfArticleDuplicate'),
    name: 'don_npa_duplicate',
    meta: {
      description: 'Duplicate a Native Perf Article',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_DON_USER
      ]
    }
  },
  {
    path: 'nativePerfArticle/:id/detail',
    component: () => import('../../components/don/NativePerfArticle'),
    name: 'don_npa_detail',
    meta: {
      description: 'Get a Native Perf Article detail',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_DON_USER
      ]
    }
  },
  {
    path: 'nativePerfArticle',
    component: () => import('../../components/don/NativePerfArticleList'),
    name: 'don_npa_list',
    meta: {
      description: 'Get a Native Perf Article list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_DON_USER
      ]
    }
  },
  {
    path: 'nativePerfArticle/config',
    component: () => import('../../components/don/NativePerfConfig'),
    name: 'don_npa_config',
    meta: {
      description: 'Configure Native Perf Article settings',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_DON_USER
      ]
    }
  },
  {
    path: 'nativePerfPredictions',
    component: () => import('../../components/don/NativePerfPredictions'),
    name: 'don_npa_predictions',
    meta: {
      description: 'Get Native Perf Article predictions',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_DON_USER
      ]
    }
  }
]

export default routes
