<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterUserLoginAttempts">
          <div class="row">
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.userId" id="filter_userId"
                         :label="$t('userLoginAttempt.filter.userId')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.username" id="filter_username"
                         :label="$t('userLoginAttempt.filter.username')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.responseStatus" id="filter_responseStatus"
                         :label="$t('userLoginAttempt.filter.responseStatus')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.clientIp" id="filter_clientIp"
                         :label="$t('userLoginAttempt.filter.clientIp')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-datepicker
                type="date"
                v-model="filter.createdAtFrom"
                id="filter_createdAtFrom"
                :label="$t('userLoginAttempt.filter.created_at_from')"
                disable-form-group
              >
              </app-datepicker>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-datepicker
                type="date"
                v-model="filter.createdAtTo"
                id="filter_createdAtTo"
                :label="$t('userLoginAttempt.filter.created_at_to')"
                disable-form-group
              >
              </app-datepicker>
            </div>
            <div class="col-12 text-right">
              <button class="btn btn-success pull-right m-t-30">
                {{ $t('userLoginAttempt.filter.search') }}
              </button>
              <button
                type="button"
                class="btn btn-secondary m-t-30"
                data-test="reset_filter"
                @click="resetFilter"
              >
                {{ $t('buttons.reset_filter') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Input from '../form/inputs/Input'
import UserLoginAttemptFilter from '../../model/UserLoginAttemptFilter'
import Datepicker from '../form/Datepicker'

export default {
  name: 'UserLoginAttemptFilter',
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(UserLoginAttemptFilter)
    }
  },
  components: {
    appInput: Input,
    appDatepicker: Datepicker
  },
  methods: {
    filterUserLoginAttempts () {
      this.resetPagination()
      this.$store.commit('userLoginAttempt/setPage', 1)
      this.$store.commit('userLoginAttempt/setFilter', this.filter)
      this.$store.dispatch('userLoginAttempt/fetch')
    },
    resetFilter () {
      this.resetPagination()
      this.filter = this._.cloneDeep(UserLoginAttemptFilter)
      this.$store.commit('userLoginAttempt/setPage', 1)
      this.$store.commit('userLoginAttempt/setFilter', this.filter)
      this.$store.dispatch('userLoginAttempt/fetch')
    },
    resetPagination () {
      this.$store.commit('userLoginAttempt/setLastCreatedAt', '')
      this.$store.commit('userLoginAttempt/setFirstCreatedAt', '')
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['userLoginAttempt/filter']
  }
}
</script>
