import Config from '../../config/'

export const PREDEFINED_DAM_USERS = {
  'nmh.tasr': 'TASR'
}

export const PREDEFINED_DAM_PDF_USERS = {
  'nmh.tasr.pdf': 'TASR'
}

const mimeTypeFileExtensionMap = {
  'image/jpeg': 'jpg',
  'image/png': 'png',
  'image/gif': 'gif',
  'image/tiff': 'tiff',
  'image/x-ms-bmp': 'bmp'
}

const getImagePath = function (basename, width = 800, height = '') {
  if (height !== '') {
    height = '/' + height
  }
  return Config.dam.baseUrl() + '/image/' + basename + '/' + width + height
}

const getFileExtensionByMimeType = function (mimeType) {
  if (!Object.prototype.hasOwnProperty.call(mimeTypeFileExtensionMap, mimeType)) {
    return null
  }
  return mimeTypeFileExtensionMap[mimeType]
}

export default {
  getImagePath,
  getFileExtensionByMimeType
}
