import CoreApi from '../../api/core'
import SiteModel from '../../model/SiteModel.js'
import SiteFilterService from '../../services/site/SiteFilterService'
import Config from '../../config'

const API_NAME = '/site'
const LIMIT = 200

const state = {
  error: null,
  detail: SiteModel,
  list: [],
  all: [],
  allAsMap: {},
  totalCount: 0,
  page: 1,
  filter: {
    id: '',
    name: '',
    title: '',
    site: 0
  },
  width: null
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  },
  storeAll (state, responseData) {
    state.all = responseData.data
    state.allAsMap = responseData.data.reduce((acc, site) => {
      acc[site.name] = site
      return acc
    }, {})
  },
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  setPage (state, page) {
    state.page = page
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  setWidth (state, width) {
    state.width = width
  }
}

const actions = {
  fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.page * Config.defaults.list.limit) - Config.defaults.list.limit
    const url = API_NAME + '?limit=' + Config.defaults.list.limit + '&offset=' + offset + '&order[id]=asc' +
      SiteFilterService.buildFilterQuery(store.state.filter)
    CoreApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  async fetchOne (store, id) {
    await CoreApi().get(API_NAME + '/' + id)
      .then(response => {
        store.commit('storeDetail', response.data)
      })
      .catch(error => console.log(error))
  },
  async fetchAll ({ commit }) {
    CoreApi().get(API_NAME + '?limit=' + LIMIT)
      .then(res => {
        commit('storeAll', res.data)
      })
      .catch(error => console.log(error))
  },
  async update (store, record) {
    return await CoreApi().put(API_NAME + '/' + record.id, JSON.stringify(record))
      .then(response => {
        if (response.status === 200) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  }
}

const getters = {
  detail (state) {
    return state.detail
  },
  list (state) {
    return state.list
  },
  all (state) {
    return state.all
  },
  allSorted (state) {
    return (compareFn = (a, b) => a.title.localeCompare(b.title)) => state.all.sort(compareFn)
  },
  allEnabledSorted (state) {
    return (compareFn = (a, b) => a.title.localeCompare(b.title)) => state.all.filter(site => site.enabled).sort(compareFn)
  },
  allAsMap (state) {
    return state.allAsMap
  },
  getSiteTitle (state) {
    return siteName => {
      if (!siteName) {
        return ''
      }
      return state.allAsMap[siteName]?.title ??
        siteName.charAt(0).toUpperCase() + siteName.slice(1)
    }
  },
  mapNamesToSites (state) {
    return siteNames => siteNames.map(siteName => state.allAsMap[siteName])
  },
  totalCount (state) {
    return state.totalCount
  },
  page (state) {
    return state.page
  },
  error (state) {
    return state.error
  },
  filter (state) {
    return state.filter
  },
  siteById (state) {
    return (id, idParam = 'id') => state.all.find(site => id === site[idParam])
  },
  enabledSites (state, getters, rootState, rootGetters) {
    return () => state.all.filter(site => {
      const currentUser = rootGetters['user/currentUser']
      if (currentUser.roles.includes('ROLE_SUPER_ADMIN') && site.enabled) {
        return site
      }
      if (site.enabled && currentUser.sites.includes(site.id)) {
        return site
      }
    })
  },
  userSites (state, getters, rootState, rootGetters) {
    return state.all.filter(site => {
      const currentUser = rootGetters['user/currentUser']
      if (currentUser.roles.includes('ROLE_SUPER_ADMIN')) {
        return site
      }
      if (currentUser.sites.includes(site.id)) {
        return site
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
