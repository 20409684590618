import { ROLE_EDITOR, ROLE_JOURNALIST, ROLE_SUPER_ADMIN } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'dashboard',
    alias: '', // this redirects empty route '/' to dashboard
    component: () => import('../../views/dashboard/DashboardViewNew'),
    name: 'dashboard',
    meta: {
      description: 'Dashboard',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'dashboardOld',
    component: () => import('../../views/dashboard/DashboardViewOld'),
    name: 'dashboard_old',
    meta: {
      description: 'Dashboard (old)',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  }
]

export default routes
