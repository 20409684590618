const messages = {
  list: {
    id: 'Id',
    title: 'Titulok',
    slug: 'Slug',
    site: 'Stránka',
    parent: 'Parent',
    created_at: 'Dátum vytvorenia',
    modified_at: 'Dátum poslednej zmeny',
    created_by: 'Vytvoril',
    absolute_url: 'Absolutná url',
    sorting: 'Zoradenie subrubrík'
  },
  filter: {
    title: 'Titulok',
    id: 'Id',
    slug: 'Slug',
    site: 'Stránka',
    parent: 'Parent',
    search: 'Hľadať'
  },
  id: 'Id',
  title: 'Titulok',
  slug: 'Slug',
  site: 'Stránka',
  parent: 'Rodič',
  iptcCategory: 'IPTC kategórie',
  meta_title: 'Meta titulok',
  meta_description: 'Meta popis',
  meta_keywords: 'Meta kľúčové slová',
  setting_advertCategory: 'ITM area parameter (advertCategory)',
  setting_chartbeatSection: 'Chartbeat section',
  setting_gemiusId: 'Gemius Id',
  setting_prRubric: 'PR rubric',
  setting_special: 'Special rubric',
  setting_advertSection: 'Unit section for Google ads (advertSection)',
  sportTable_seasonId: 'Sport table Season Id',
  sportTable_activeTab: 'Sport table Active Tab (first = 1)',
  rubric_without_articles: 'Rubric without articles (disable possibility to set this rubric to articles, used for custom listing rubrics)',
  hiddenInMenu: 'Hidden in autogenerated menu',
  sorting: 'Zoradenie subrubrík',
  product: 'Produkt'
}

export default messages
