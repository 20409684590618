import { ROLE_EDITOR, ROLE_JOURNALIST, ROLE_SUPER_ADMIN } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'gallery/new',
    component: () => import('../../views/gallery/GalleryNewView'),
    name: 'gallery_new',
    meta: {
      description: 'Gallery new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'gallery/:id/edit',
    component: () => import('../../views/gallery/GalleryEditView'),
    name: 'gallery_edit',
    meta: {
      description: 'Gallery edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'gallery/:id',
    component: () => import('../../views/gallery/GalleryView'),
    name: 'gallery_detail',
    meta: {
      description: 'Gallery detail',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'gallery',
    component: () => import('../../views/gallery/GalleryListView'),
    name: 'gallery_list',
    meta: {
      description: 'Gallery list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  }
]

export default routes
