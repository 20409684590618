import { ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR } from '@/model/ValueObject/UserRole'

const routes = [
  {
    path: 'article/:id/edit',
    component: () => import('../../views/article/ArticleEditView'),
    name: 'article_edit',
    meta: {
      description: 'Article edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'article/:id',
    component: () => import('../../views/article/ArticleView'),
    name: 'article_detail',
    meta: {
      description: 'Article detail',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'article',
    component: () => import('../../views/article/ArticleListView'),
    name: 'article_list',
    meta: {
      description: 'List of articles',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'articleByDocumentId/:id',
    component: () => import('../../views/article/ArticleByDocumentIdView'),
    name: 'articleByDocumentId',
    meta: {
      description: 'Article by document id',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  }
]

export default routes
