<template>
  <app-module-view>
    <template slot="body">
      <app-userLoginAttempt-filter></app-userLoginAttempt-filter>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <app-data-table
            :data="userLoginAttempts"
            :config="dataTableConfig"
            :pagination=false
          >
          </app-data-table>
          <div class="row">
            <div class="col-12">
              <p class="m-t-20">{{ $t('found') }}: {{ totalCount }}</p>
            </div>
            <div class="col-12 d-flex justify-content-center">
              <button
                v-if="!firstPage"
                @click="prev"
                type="button"
                class="btn waves-effect waves-light btn-secondary"
              >
                {{ $t("page_prev") }}
              </button>
              <button
                v-if="!lastPage"
                @click="next"
                type="button"
                class="btn waves-effect waves-light btn-secondary ml-1"
              >
                {{ $t("page_next") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import { mapState } from 'vuex'
import Preloader from '../preloader/Preloader'
import UserLoginAttemptFilter from './UserLoginAttemptFilter'
import DataTable from '../shared/DataTable'
import config from '../../config'

export default {
  name: 'UserLoginAttemptList',
  data () {
    return {
      dataLoaded: false,
      dataTableConfig: {
        fields: {
          userId: this.$t('userLoginAttempt.list.userId'),
          username: this.$t('userLoginAttempt.list.username'),
          responseStatus: this.$t('userLoginAttempt.list.responseStatus'),
          clientIp: this.$t('userLoginAttempt.list.clientIp'),
          adminVersion: this.$t('userLoginAttempt.list.adminVersion'),
          createdAt: this.$t('userLoginAttempt.list.created_at')
        }
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    userLoginAttempts () {
      return this.$store.getters['userLoginAttempt/list']
    },
    totalCount () {
      return this.$store.getters['userLoginAttempt/totalCount']
    },
    firstPage () {
      return this.$store.getters['userLoginAttempt/firstPage']
    },
    lastPage () {
      return this.$store.getters['userLoginAttempt/lastPage']
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appUserLoginAttemptFilter: UserLoginAttemptFilter,
    appDataTable: DataTable
  },
  methods: {
    getUserLoginAttempts () {
      this.$store.dispatch('userLoginAttempt/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.$store.commit('userLoginAttempt/setPage', page)
      this.getUserLoginAttempts()
    },
    next () {
      if (typeof this.userLoginAttempts[config.defaults.list.limit - 1] !== 'undefined') {
        const lastCreatedAt = this.userLoginAttempts[config.defaults.list.limit - 1].createdAt
        this.$store.commit('userLoginAttempt/setLastCreatedAt', lastCreatedAt)
        this.$store.commit('userLoginAttempt/setFirstCreatedAt', '')
      }
      this.getUserLoginAttempts()
    },
    prev () {
      if (typeof this.userLoginAttempts[0] !== 'undefined') {
        const firstCreatedAt = this.userLoginAttempts[0].createdAt
        this.$store.commit('userLoginAttempt/setFirstCreatedAt', firstCreatedAt)
        this.$store.commit('userLoginAttempt/setLastCreatedAt', '')
      }
      this.getUserLoginAttempts()
    }
  },
  mounted () {
    this.$store.commit('userLoginAttempt/setFirstCreatedAt', '')
    this.$store.commit('userLoginAttempt/setLastCreatedAt', '')
    this.getUserLoginAttempts()
  }
}
</script>

<style lang="scss" scoped>
  .table-responsive {
    th, td {
      font-size: 14px
    }
  }
</style>
