import authApi from '../../api/auth'
import coreApi from '../../api/core'
import UserModel from '../../model/User'
import VueCookies from 'vue-cookies'
import appConfig from '@/config'
import UserService from '../../services/user/UserService'

const API_NAME = '/user'
const LIMIT = 2000

const state = {
  token: null,
  detail: UserModel,
  list: [],
  all: [],
  totalCount: 0,
  currentUser: {
    username: '',
    email: 'anonymous@gmail.com',
    roles: [],
    sites: []
  }
}

const mutations = {
  authUser (state, userData) {
    state.token = userData.token
  },
  storeCurrentUser (state, currentUser) {
    state.currentUser = currentUser
  },
  clearAuthData (state) {
    state.token = null
  },
  storeAll (state, responseData) {
    state.all = responseData.data
  },
  storeList (state, items) {
    state.list = items
  }
}

const actions = {
  setLogoutTimer ({ commit }, expirationTime) {
    setTimeout(() => {
      commit('clearAuthData')
    }, expirationTime * 1000)
  },
  async login ({ commit, dispatch }, authData) {
    await authApi().post('oauth2/', {
      username: authData.username,
      password: authData.password,
      grant_type: 'password',
      admin_version: appConfig.appVersion()
    })
      .then(res => {
        const now = new Date()
        const expirationDate = new Date(now.getTime() + res.data.expires_in * 1000)
        VueCookies.set('access_token', 'Bearer ' + res.data.access_token)
        VueCookies.set('refresh_token', res.data.refresh_token)
        VueCookies.set('expirationDate', expirationDate)
        commit('authUser', {
          token: res.data.access_token
        })
        dispatch('setLogoutTimer', res.data.expires_in)
        dispatch('fetchAll')
        dispatch('site/fetchAll', null, { root: true })
        dispatch('safetyTopic/fetchAll', null, { root: true })
        dispatch('category/fetchAll', null, { root: true })
      })
      .catch(error => console.log(error))
  },
  async tryAutoLogin ({ commit, dispatch, state }) {
    const token = VueCookies.get('access_token')
    if (!token) {
      return
    }
    const expirationDate = new Date(VueCookies.get('expirationDate'))
    const now = new Date()
    if (now > expirationDate || state.token) {
      return
    }
    commit('authUser', {
      token: token
    })
    dispatch('setLogoutTimer', expirationDate)
    await dispatch('fetchAndStoreCurrentUser')
    dispatch('fetchAll')
    dispatch('site/fetchAll', null, { root: true })
    dispatch('safetyTopic/fetchAll', null, { root: true })
    dispatch('category/fetchAll', null, { root: true })
    await dispatch('department/fetchAll', null, { root: true })
  },
  async refreshToken ({ commit, dispatch }) {
    await authApi().post('oauth2/', {
      access_token: VueCookies.get('token'),
      refresh_token: VueCookies.get('refresh_token'),
      grant_type: 'refresh_token'
    })
      .then(res => {
        const now = new Date()
        const expirationDate = new Date(now.getTime() + res.data.expires_in * 1000)
        VueCookies.set('access_token', 'Bearer ' + res.data.access_token)
        VueCookies.set('refresh_token', res.data.refresh_token)
        VueCookies.set('expirationDate', expirationDate)
        commit('authUser', {
          token: res.data.access_token
        })
        dispatch('setLogoutTimer', res.data.expires_in)
        dispatch('fetchAndStoreCurrentUser')
      })
      .catch(error => console.log(error))
  },
  async logout ({ commit }) {
    commit('clearAuthData')
    VueCookies.remove('access_token')
    VueCookies.remove('refresh_token')
    VueCookies.remove('expirationDate')
  },
  async fetchAndStoreCurrentUser ({ commit, state }) {
    if (!state.token) {
      return
    }
    await coreApi().get('/user-current')
      .then(res => {
        commit('storeCurrentUser', res.data)
      })
      .catch(error => console.log(error))
  },
  fetchAll ({ commit }) {
    coreApi().get(API_NAME + '?limit=' + LIMIT)
      .then(res => {
        commit('storeAll', res.data)
      })
      .catch(error => console.log(error))
  }
}

const getters = {
  detail (state) {
    return state.detail
  },
  list (state) {
    return state.list
  },
  all (state) {
    return state.all
  },
  totalCount (state) {
    return state.totalCount
  },
  userById (state) {
    return id => state.all.find(user => {
      if (id === user.id) {
        user.fullName = UserService.getUserInfo(user)
        return user
      }
    })
  },
  currentUser (state) {
    return state.currentUser
  },
  isAuthenticated (state) {
    return state.token !== null
  },
  hasRole (state) {
    return role => state.currentUser.roles.includes(role)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
