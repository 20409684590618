const messages = {
  original: {
    body_text: 'Original article text',
    title: 'Original article title',
    first_paragraph: 'Original article first paragraph',
    content_ratio: 'Orig. article match'
  },
  show: 'Show',
  status: {
    new: 'New',
    transfered: 'Transfered'
  },
  transfer: 'Transfer',
  transfer_article: 'Transfer article'
}

export default messages
