<template>
  <aside
    class="sidebar"
    @mouseover="mouseOver"
    @mouseleave="mouseLeave"
  >
    <DashTitle v-if="siteWidth > 767" />
    <nav
      class="sidebar__navigation"
      ref="dashSidebarNavigation"
    >
      <ul class="sidebar__list">
        <template
          v-for="item in menu"
        >
          <li
            v-if="isAllowedToDisplayThisArray(item.isAllowedToDisplay)"
            :key="item.id"
            :class="[
              'sidebar__list-item',
              (item.subMenu ? 'sidebar__list-item--submenu' : ''),
              (item.id === 'language' ? 'sidebar__list-item--separator' : ''),
              (activeItem(item) ? 'sidebar__list-item--active' : false)
            ]"
            @click="clickHandler($event, item.id)"
          >
            <SidebarListItemMain :item="item" />
            <SidebarUlNested :is-allowed-to-display="isAllowedToDisplay" :item="item" />
          </li>
        </template>
      </ul>
    </nav>
    <p class="sidebar__app-version">{{ $t('version.current_version') }}: {{ appVersionClient }}</p>
  </aside>
</template>

<script>
import appConfig from '@/config'
import { mapGetters, mapState } from 'vuex'
import SidebarDataNmh from '@/model/ValueObject/SidebarDataNmh'
import SidebarDataVlm from '@/model/ValueObject/SidebarDataVlm'
import SidebarListItemMain from '@/components/dashboard/DashSidebarLiMain'
import DashTitle from '@/components/dashboard/DashTitle'
import SidebarUlNested from '@/components/dashboard/DashSidebarUlNested'
import UserMixin from '@/components/mixins/User'
export default {
  name: 'DashSidebar',
  mixins: [UserMixin],
  components: {
    SidebarListItemMain,
    DashTitle,
    SidebarUlNested
  },
  data () {
    return {
      appVersionClient: appConfig.appVersion(),
      isArticleListActive: false,
      sidebarData: {
        nmh: SidebarDataNmh,
        vlm: SidebarDataVlm
      }
    }
  },
  computed: {
    ...mapState(['config', 'user']),
    ...mapGetters(['vlm']),
    currentUser () {
      return this.$store.getters['user/currentUser']
    },
    dashCompact () {
      return this.$store.state.dash.compact
    },
    lastFiltered () {
      return this.$store.getters['article/lastFiltered']
    },
    siteWidth () {
      return this.$store.state.site.width
    },
    dashSidebarMobileOpen () {
      return this.$store.state.dash.sidebar.isOpen
    },
    menu () {
      return this.vlm ? this.sidebarData.vlm : this.sidebarData.nmh
    }
  },
  methods: {
    activeItem (item) {
      if (!item.nestedListItems.length) {
        if (typeof item.isAllowedToDisplay === 'boolean' && item.routeName === '') {
          return false
        }
        if (typeof item.isAllowedToDisplay === 'boolean' && item.routeName !== '') {
          return item.routeName === this.$route.name
        }
        return item.isAllowedToDisplay.includes(this.$route.name)
      }
      if (item.nestedListItems.length) {
        const arr = []
        item.nestedListItems.forEach(data => arr.push(data.routeName))
        return arr.includes(this.$route.name)
      }
    },
    clickHandler (event, id) {
      const idOfClickedElement = this.menu.filter(item => item.id === id)[0]
      if (idOfClickedElement.id.startsWith('dashboard') || idOfClickedElement.id === 'videoTutorial') {
        return
      }
      const listItemMain = event.target.classList.contains('sidebar-li-main__item')
      if (listItemMain) {
        idOfClickedElement.subMenu = !idOfClickedElement.subMenu
      }
    },
    isAllowedToDisplay (routeName) {
      let isAllowed = false
      const route = this.$router.options.routes[2].children.find(route => {
        if (route.name === routeName) {
          return route
        }
      })
      if (route && route.meta.requiredRoles === undefined) {
        isAllowed = true
      }
      if (route && route.meta.requiredRoles !== undefined) {
        route.meta.requiredRoles.forEach(requiredRole => {
          if (this.user.currentUser.roles.includes(requiredRole)) {
            isAllowed = true
          }
        })
      }
      return isAllowed
    },
    isAllowedToDisplayThisArray (items) {
      if (!Array.isArray(items) && items === true) {
        return true
      }
      const data = []
      items.forEach(item => data.push(this.isAllowedToDisplay(item)))
      return data.includes(true)
    },
    openSubmenuAfterRefresh () {
      setTimeout(() => {
        try {
          if (this.$route.name === 'article_list') {
            const listOfArticles = this.menu.filter(item => item.id === 'listOfArticles')[0]
            listOfArticles.subMenu = true
            return
          }
          this.menu.forEach(item => {
            item.subMenuSelected = false
          })
          const listItemId = this.$el.querySelector('.active[data-name]').dataset.name
          const id = this.menu.filter(item => item.id === listItemId)[0]
          id.subMenu = true
          id.subMenuSelected = true
        } catch (e) {
          return false
        }
      }, 500)
    },
    mouseOver (e) {
      if (!this.dashCompact) {
        return
      }
      if (!e.target.classList.contains('dash-title')) {
        this.$store.commit('dash/setNavigationHover', true)
      }
      if (e.target.classList.contains('dash-title')) {
        this.$store.commit('dash/setNavigationHover', false)
      }
    },
    mouseLeave () {
      if (!this.dashCompact) {
        return
      }
      this.$store.commit('dash/setNavigationHover', false)
    },
    sidebarWidth () {
      this.$store.commit('dash/setSidebarOpen', false)
      if (this.siteWidth <= 1200) {
        this.$store.commit('dash/setCompactness', true)
      }
      if (this.siteWidth > 1200) {
        this.$store.commit('dash/setCompactness', false)
      }
    },
    setDashSidebarMobile () {
      this.$store.commit('dash/setSidebarMobile', this.siteWidth < 768)
    }
  },
  mounted () {
    this.openSubmenuAfterRefresh()
    this.sidebarWidth()
    this.setDashSidebarMobile()
  },
  watch: {
    '$route' (to, from) {
      this.$store.commit('dash/setSidebarOpen', false)
      if (to.name === 'article_list' || to.name === 'article_edit' || to.name === 'article_detail') {
        this.isArticleListActive = true
      }
    },
    lastFiltered () {
      const articleFilter = this.$store.getters['article/filter']
      this.articleSiteFilter = parseInt(articleFilter.site)
    },
    siteWidth () {
      this.sidebarWidth()
      this.setDashSidebarMobile()
    },
    dashSidebarMobileOpen (isOpen) {
      isOpen ? document.body.style.overflowY = 'hidden' : document.body.style.overflowY = 'scroll'
    }
  }
}
</script>

<style lang="scss">
  .sidebar {
    background: #fff;
    overflow-y: scroll;
    direction: rtl;
    @include bp(0 7) {
      @include fixed(60px _ _ 0, 3);
      @include size(100% calc(100vh - 60px));
    }
    @include bp(7) {
      @include size(240px calc(100vh - 60px));
      @include fixed(_ _ 0 0, 3);
      transition: width 200ms;
    }
    .dash--compact & {
      @include bp(7) {
        @include size(60px _);
      }
    }
    .dash--nav-hover & {
      @include bp(7) {
        @include size(240px _);
      }
    }
    .dash--nav-not-hover & {
      @include bp(7) {
        padding: 0;
      }
    }
    .dash--sidebar-mob & {
      @include bp(0 7) {
        display: none;
      }
    }
    .dash--sidebar-mob-open & {
      @include bp(0 7) {
        display: block;
      }
    }
    &--skinny {
      @include bp(7) {
        @include size(60px _)
      }
    }
    &__navigation {
      @include padding(30px 15px 20px);
      direction: ltr;
      @include bp(0 7) {
        @include padding(15px 15px 20px);
      }
      .dash--nav-not-hover & {
        @include bp(7) {
          padding: 0;
        }
      }
    }
    &__list {
      margin: 0;
      padding: 0;
    }
    &__list-item {
      @include font(500 15px "Roboto");
      list-style: none;
      cursor: pointer;
      color: #465674;
      &:not(.sidebar__list-item--submenu) {
        &:hover {
          .sidebar-li-main__main-icon,
          .sidebar-li-main__text,
          .sidebar-li-main__arrow-icon {
            color: #404856;
            fill: #404856;
          }
        }
      }
      &--separator {
        @include margin(10px _ _);
        @include padding(10px _ _);
        border-top: 1px solid rgba(71,134,255,.15);
      }
    }
    &__app-version {
      @include font(500 14px "Roboto");
      @include margin(40px _ 20px _);
      @include padding(_ 15px);
      color: #465674;
      opacity: .7;
      text-align: center;
      .dash--compact.dash--nav-not-hover & {
        @include bp(7) {
          width: 0;
          opacity: 0;
          overflow: hidden;
        }
      }
    }
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-track {
      background-color: rgba(101, 153, 254, .3);
    }
    &::-webkit-scrollbar-thumb {
      background-color: #6599fe;
      border-radius: 20px;
      border-left: 1px solid #fff;
    }
  }
</style>
