const CONTENT_BLOCK_TYPE_ARTICLE = 'article'

export default {
  id: null,
  type: CONTENT_BLOCK_TYPE_ARTICLE,
  title: '',
  hidden: 0,
  site: null,
  category: '',
  identifier: '',
  settings: '',
  items: [],
  internalFallback: 0,
  falbackSite: null,
  fallbackRubrics: [],
  fallbackTags: [],
  fallbackCategories: [],
  fallbackFlag: {
    video: 0,
    imageGallery: 0
  },
  fallbackSetting: {
    prArticle: 0
  },
  fallbackArticleType: {
    article: 0,
    external: 0,
    video: 0,
    quiz: 0,
    photostory: 0
  },
  externalFallback: 0,
  numberOfItems: 0,
  position: 0,
  displayPosition: 0,
  daysLimit: 365,
  disabledPositions: '0',
  fallbackPositions: '0',
  expanded: {
    fallbackRubrics: [],
    fallbackTags: [],
    fallbackCategories: []
  }
}
