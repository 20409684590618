import { ROLE_SUPER_ADMIN } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'feature/:id/edit',
    component: () => import('../../views/feature/FeatureEditView'),
    name: 'feature_edit',
    meta: {
      description: 'Feature edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'feature',
    component: () => import('../../views/feature/FeatureListView'),
    name: 'feature_list',
    meta: {
      description: 'Feature list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  }
]

export default routes
