export default {
  email: '',
  username: '',
  firstName: '',
  lastName: '',
  fullName: '',
  position: '',
  defaultSite: null,
  defaultRubric: null,
  permissions: [],
  sites: []
}
