import CoreApi from '../../api/core'
import TagModel from '../../model/Tag.js'
import Config from '../../config'
import TagFilterService from '../../services/tag/TagFilterService'

const API_NAME = '/tag'
const SEARCH_API_NAME = '/suggest-tag'
const API_NAME_GENEEA = '/geneeaTag'

const state = {
  error: null,
  detail: TagModel,
  list: [],
  listGeneea: [],
  totalCount: 0,
  page: 1,
  filterQuery: '',
  filter: {
    id: '',
    title: ''
  }
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  },
  storeListGeneea (state, responseData) {
    state.listGeneea = responseData.data
  },
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  setPage (state, page) {
    state.page = page
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  setFilterQuery (state, filterQuery) {
    state.filterQuery = filterQuery
  }
}

const actions = {
  fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.page * Config.defaults.list.limit) - Config.defaults.list.limit
    const url = API_NAME +
      '?limit=' + Config.defaults.list.limit +
      '&offset=' + offset +
      '&order[id]=desc' + TagFilterService.buildFilterQuery(store.state.filter)
    CoreApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  async fetchByTitle ({ commit }, { query, view = 'article' }) {
    if (query) {
      await CoreApi().get(`${SEARCH_API_NAME}?view=${view}&search=${query}`)
        .then(res => {
          commit('storeList', { data: res.data, totalCount: 0 })
        })
        .catch(error => console.log(error))
    }
  },
  async fetchByTitleGeneea ({ commit }, { query, view = 'article' }) {
    await CoreApi().get(API_NAME_GENEEA + `?view=${view}&filter_contains[title]=${query}`)
      .then(res => {
        commit('storeListGeneea', res.data)
      })
      .catch(error => console.log(error))
  },
  fetchByIds (store, ids) {
    const url = API_NAME + '?view=minimal&limit=1000&filter_in[id]=' + ids.join(',')
    return CoreApi().get(url)
      .then(res => {
        return Promise.resolve(res.data)
      })
      .catch(error => {
        console.log(error)
      })
  },
  async fetchOne (store, id) {
    await CoreApi().get(API_NAME + '/' + id)
      .then(response => {
        store.commit('storeDetail', response.data)
      })
      .catch(error => console.log(error))
  },
  async create (store, record) {
    return await CoreApi().post(API_NAME, JSON.stringify(record))
      .then(response => {
        if (response.status === 201) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async update (store, record) {
    return await CoreApi().put(API_NAME + '/' + record.id, JSON.stringify(record))
      .then(response => {
        if (response.status === 200) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async deleteRecord (store, record) {
    return await CoreApi().delete(API_NAME + '/' + record.id)
      .then(response => {
        if (response.status === 204) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  }
}

const getters = {
  detail (state) {
    return state.detail
  },
  list (state) {
    return state.list
  },
  totalCount (state) {
    return state.totalCount
  },
  page (state) {
    return state.page
  },
  error (state) {
    return state.error
  },
  filter (state) {
    return state.filter
  },
  filterQuery (state) {
    return state.filterQuery
  },
  listGeneea (state) {
    return state.listGeneea
  },
  tagsWithRecipeCount (state) {
    return state.list.map(tag => {
      tag.title = `${tag.title} (${tag.recipeCount})`
      return tag
    })
  },
  tagsWithGalleryCount (state) {
    return state.list.map(tag => {
      tag.title = `${tag.title} (${tag.galleryCount})`
      return tag
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
