import CoreApi from '../../api/core'
import TestFilterService from '../../services/articleTest/ArticleTestFilterService'
import config from '../../config'

const API_NAME = '/articleTest'

const state = {
  error: null,
  list: [],
  totalCount: 0,
  page: 1,
  filter: {
    id: '',
    title: ''
  }
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  },
  setPage (state, page) {
    state.page = page
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
  }
}

const actions = {
  async fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.page * config.defaults.list.limit) - config.defaults.list.limit
    const url = API_NAME +
      '?limit=' + config.defaults.list.limit +
      '&offset=' + offset +
      '&order[id]=desc' + TestFilterService.buildFilterQuery(store.state.filter)
    return await CoreApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  }
}

const getters = {
  list (state) {
    return state.list
  },
  totalCount (state) {
    return state.totalCount
  },
  page (state) {
    return state.page
  },
  error (state) {
    return state.error
  },
  filter (state) {
    return state.filter
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
