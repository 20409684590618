import { POLL_TYPE_DEFAULT } from '../components/mixins/valueObject/PollTypeMixin'
import { CATEGORY_OTHER } from './ValueObject/PollCategory'
import { DISPLAY_TYPE_PERCENTAGE } from './ValueObject/PollDisplayType'
import DateTimeService, { dateTimeNow } from '../services/DateTimeService'
import moment from 'moment'

export default {
  id: null,
  hidden: false,
  orderDate: dateTimeNow(),
  publishedSince: dateTimeNow(),
  publishedUntil: DateTimeService.dateTimeMax,
  endOfVoting: moment().add(7, 'days').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z',
  title: '',
  slug: {},
  description: '',
  image: null,
  imageInfo: null,
  type: POLL_TYPE_DEFAULT,
  category: CATEGORY_OTHER,
  displayType: DISPLAY_TYPE_PERCENTAGE,
  restrictionType: '',
  totalVotes: 0,
  enabledVoting: true,
  migratedId: 0,
  options: [],
  city: ''
}
