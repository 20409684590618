import { ROLE_SUPER_ADMIN } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'department/:id/edit',
    component: () => import('../../components/department/DepartmentEdit'),
    name: 'department_edit',
    meta: {
      description: 'Department edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'department/new',
    component: () => import('../../components/department/DepartmentNew'),
    name: 'department_new',
    meta: {
      description: 'Department new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'department',
    component: () => import('../../components/department/DepartmentList'),
    name: 'department_list',
    meta: {
      description: 'Department list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  }
]

export default routes
