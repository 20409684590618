import { dateTimeNow } from '../services/DateTimeService'

export default {
  id: null,
  title: '',
  public: true,
  description: '',
  site: null,
  expanded: {
    site: null,
    tags: [],
    medias: []
  },
  tags: [],
  medias: [],
  createdAt: dateTimeNow(),
  modifiedAt: dateTimeNow(),
  createdBy: 1,
  modifiedBy: null
}
