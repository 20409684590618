import axios from 'axios'
import appConfig from '../config'
import VueCookies from 'vue-cookies'
import Store from '@/store'

const damApi = function (timeout = appConfig.dam.apiTimeout) {
  Store.commit('SET_OFFLINE', false)
  const _client = axios.create({
    baseURL: appConfig.dam.apiUrl(),
    timeout: timeout * 1000,
    headers: {
      Authorization: VueCookies.get('token'),
      'Content-Type': 'application/json'
    }
  })

  /*
   * Axios Request Interceptor.
   * Before each request, use the values in local storage to set new headers.
   */
  _client.interceptors.request.use(
    config => {
      if (config.baseURL === appConfig.dam.apiUrl()) {
        config.headers.Authorization = VueCookies.get('access_token')
      }
      return config
    },
    error => Promise.reject(error)
  )

  return _client
}

export default damApi
