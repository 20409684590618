import { ROLE_SUPER_ADMIN } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'author/new',
    component: () => import('../../views/author/AuthorNewView'),
    name: 'author_new',
    meta: {
      description: 'Author new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'author/:id/edit',
    component: () => import('../../views/author/AuthorEditView'),
    name: 'author_edit',
    meta: {
      description: 'Author edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'author/:id',
    component: () => import('../../views/author/AuthorView'),
    name: 'author_detail',
    meta: {
      description: 'Author detail',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'author',
    component: () => import('../../views/author/AuthorListView'),
    name: 'author_list',
    meta: {
      description: 'Author list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  }
]

export default routes
