import axios from 'axios'
import appConfig from '@/config'
import VueCookies from 'vue-cookies'

const oneSignalApi = function (timeout = appConfig.oneSignal.apiTimeout) {
  const _client = axios.create({
    baseURL: appConfig.oneSignal.apiUrl(),
    timeout: timeout * 1000,
    headers: {
      // TODO CMS-1280 fix for SSO localStorage
      Authorization: VueCookies.get('token'),
      'Content-Type': 'application/json'
    }
  })

  /*
   * Axios Request Interceptor.
   * Before each request, use the values in local storage to set new headers.
   */
  _client.interceptors.request.use(
    config => {
      if (config.baseURL === appConfig.oneSignal.apiUrl()) {
        // TODO CMS-1280 fix for SSO localStorage
        config.headers.Authorization = VueCookies.get('access_token')
      }
      return config
    },
    error => Promise.reject(error)
  )

  return _client
}

export default oneSignalApi
