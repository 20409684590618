import { ROLE_SUPER_ADMIN, ROLE_ESHOP_ADMIN } from '../../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'shop/subscription/subscription/:id/edit',
    component: () => import('../../../components/eshop/SubscriptionEdit'),
    name: 'eshopSubscription_edit',
    meta: {
      description: 'Subscription edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_ESHOP_ADMIN
      ]
    }
  },
  {
    path: 'shop/subscription/subscription/new',
    component: () => import('../../../components/eshop/SubscriptionNew'),
    name: 'eshopSubscription_new',
    meta: {
      description: 'Subscription new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_ESHOP_ADMIN
      ]
    }
  },
  {
    path: 'shop/subscription/subscription',
    component: () => import('../../../components/eshop/SubscriptionList'),
    name: 'eshopSubscription_list',
    meta: {
      description: 'Subscription list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_ESHOP_ADMIN
      ]
    }
  }
]

export default routes
