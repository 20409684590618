import { ROLE_SUPER_ADMIN } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'safetyTopic/:id/edit',
    component: () => import('../../views/topic/TopicSafetyEditView'),
    name: 'safetyTopic_edit',
    meta: {
      description: 'Safety topic edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'safetyTopic/new',
    component: () => import('../../views/topic/TopicSafetyNewView'),
    name: 'safetyTopic_new',
    meta: {
      description: 'Safety topic new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'safetyTopic',
    component: () => import('../../views/topic/TopicSafetyListView'),
    name: 'safetyTopic_list',
    meta: {
      description: 'Safety topic list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  }
]

export default routes
