const routes = [
  {
    path: 'welcome',
    alias: '',
    component: () => import('../../views/app/AppWelcomeView'),
    name: 'welcome',
    meta: {
      description: 'Welcome',
      requiresAuth: true,
      requiredRoles: []
    }
  }
]

export default routes
