import { ROLE_SUPER_ADMIN, ROLE_VIDEO_ADMIN } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'videoManagement',
    component: () => import('../../views/video/VideoManagementView'),
    name: 'videoManagement',
    meta: {
      description: 'Video management',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_VIDEO_ADMIN
      ]
    }
  }
]

export default routes
