import { ROLE_SUPER_ADMIN, ROLE_ANALYST, ROLE_JOURNALIST } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'historicalDashboard',
    component: () => import('../../views/dashboard/DashboardStatisticsHistoricalView'),
    name: 'historical_dashboard',
    meta: {
      description: 'Historical Dashboard',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_ANALYST, ROLE_JOURNALIST
      ]
    }
  }
]

export default routes
