import {
  ROLE_SUPER_ADMIN,
  ROLE_DAM_ADMIN, ROLE_DAM_USER,
  ROLE_DAM_PDF_ADMIN, ROLE_DAM_PDF_USER,
  ROLE_JOURNALIST, ROLE_EDITOR
} from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'eagle-dam/image',
    component: () => import('../../components/dam/DamList'),
    name: 'dam_list',
    meta: {
      description: 'Dam list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_DAM_ADMIN, ROLE_DAM_USER, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'eagle-dam/pdf',
    component: () => import('../../components/dam/DamPdfList'),
    name: 'dam_pdf_list',
    meta: {
      description: 'Dam list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_DAM_PDF_ADMIN, ROLE_DAM_PDF_USER, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  }
]

export default routes
