import { ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'sport24/:id',
    component: () => import('../../views/sport/SportTeamSeasonView'),
    name: 'sport24_detail',
    meta: {
      description: 'Sport detail',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'sport24',
    component: () => import('../../views/sport/SportSeasonListView'),
    name: 'sport24_list',
    meta: {
      description: 'Sport tables',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  }
]

export default routes
