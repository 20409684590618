import moment from 'moment'
import Store from '../../store'

const buildFilterQuery = function (filter) {
  let filterQuery = '&filter_eq[exported]=0'
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
    return filterQuery
  }
  if (filter.title) {
    filterQuery += '&filter_contains[field.title]=' + filter.title
  }
  if (filter.site) {
    filterQuery += '&filter_eq[site]=' + filter.site
  } else {
    const enabledSites = Store.getters['site/enabledSites']()
    if (enabledSites.length && Store.getters['user/hasRole']('ROLE_SUPER_ADMIN') === false) {
      filterQuery += '&filter_in[site]=' + enabledSites.map(site => site.id)
    }
  }
  if (filter.issue) {
    filterQuery += '&filter_eq[issue]=' + filter.issue
  }
  if (filter.dateFrom) {
    filterQuery += '&filter_gte[date]=' + moment(filter.dateFrom).startOf('day').utc().toJSON()
  }
  if (filter.dateTo) {
    filterQuery += '&filter_lte[date]=' + moment(filter.dateTo).endOf('day').utc().toJSON()
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
