import { ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'woodWing/:id',
    component: () => import('../../components/woodWing/WoodWing'),
    name: 'woodWing_detail',
    meta: {
      description: 'WoodWing article detail',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'woodWing',
    component: () => import('../../components/woodWing/WoodWingList'),
    name: 'woodWing_list',
    meta: {
      description: 'WoodWing article list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  }
]

export default routes
