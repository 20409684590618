const routes = [
  {
    path: 'nativeCampaignManagement',
    component: () => import('../../views/donBox/NativeCampaignManagementListView'),
    name: 'native_campaign_list',
    meta: {
      description: 'Get a Native Campaign list'
    }
  },
  {
    path: 'nativeCampaignManagement/new',
    component: () => import('../../views/donBox/NativeCampaignManagementNewView'),
    name: 'native_campaign_new',
    meta: {
      description: 'Create a Native Campaign'
    }
  },
  {
    path: 'nativeCampaignManagement/:id',
    component: () => import('../../views/donBox/NativeCampaignManagementView'),
    name: 'native_campaign_detail',
    meta: {
      description: 'View a Native Campaign'
    }
  },
  {
    path: 'nativeCampaignManagement/:id/edit',
    component: () => import('../../views/donBox/NativeCampaignManagementEditView'),
    name: 'native_campaign_edit',
    meta: {
      description: 'Edit a Native Campaign'
    }
  },
  {
    path: 'nativeCampaignManagement/:id/duplicate',
    component: () => import('../../views/donBox/NativeCampaignManagementDuplicateView'),
    name: 'native_campaign_duplicate',
    meta: {
      description: 'Duplicate a Native Campaign'
    }
  },
  {
    path: 'nativeCampaignManagement/fromArticle/:id',
    component: () => import('../../views/donBox/NativeCampaignManagementFromArticleView'),
    name: 'native_campaign_from_article',
    meta: {
      description: 'Crate a Native Campaign from article'
    }
  }
]

export default routes
