import UserService from '../../services/user/UserService'

const DEFAULT_SITE = 1

export default {
  methods: {
    getUserInfo (user) {
      return UserService.getUserInfo(user)
    },
    logout () {
      this.$store.dispatch('user/logout')
        .then(() => {
          this.$store.commit('article/resetFilter')
          this.$router.push('/login')
        })
        .catch((error) => {
          this.response = error.response
        })
    },
    getUserSite () {
      const currentUser = this.$store.getters['user/currentUser']
      if (currentUser.defaultSite !== null) {
        return currentUser.defaultSite
      }
      if (currentUser.sites.length > 0) {
        return currentUser.sites[0]
      }
      return DEFAULT_SITE
    },
    async getUserRubric () {
      const currentUser = this.$store.getters['user/currentUser']
      if (currentUser.defaultRubric !== null) {
        return currentUser.defaultRubric
      }
      const userSite = this.getUserSite()
      // this is fetching only rubrics that are not fetched yet
      await this.$store.dispatch('rubric/fetchRubricsBySiteId', userSite)
      const rubrics = this.$store.getters['rubric/all']
      const rubric = rubrics.find(rubric => rubric.site === userSite)
      return rubric.id
    },
    userNameById (id) {
      const user = this.$store.getters['user/userById'](id)
      if (user) {
        return UserService.getUserInfo(user)
      }
      return id
    }
  }
}
