import axios from 'axios'
import appConfig from '../config'
import VueCookies from 'vue-cookies'

const edonApi = function (timeout = appConfig.edon.apiTimeout) {
  const _client = axios.create({
    baseURL: appConfig.edon.apiUrl(),
    timeout: timeout * 1000,
    headers: {
      Authorization: VueCookies.get('access_token'),
      'Content-Type': 'application/json'
    }
  })

  /*
   * Axios Request Interceptor.
   * Before each request, use the values in local storage to set new headers.
   */
  _client.interceptors.request.use(
    config => {
      if (config.baseURL === appConfig.edon.apiUrl()) {
        config.headers.Authorization = VueCookies.get('access_token')
      }
      return config
    },
    error => Promise.reject(error)
  )

  return _client
}

export default edonApi
