import { ROLE_SUPER_ADMIN, ROLE_DEPARTMENT_MANAGER } from '@/model/ValueObject/UserRole'

const routes = [
  {
    path: 'hpTopicOffer',
    component: () => import('../../views/hp/HpTopicOfferList'),
    name: 'hp_topic_offer_list',
    meta: {
      description: 'HP topic offer',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_DEPARTMENT_MANAGER
      ]
    }
  },
  {
    path: 'hpTopicOfferOld',
    component: () => import('../../components/hpTopicOffer/HpTopicOfferList'),
    name: 'hp_topic_offer_list_old',
    meta: {
      description: 'HP topic offer(old)',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_DEPARTMENT_MANAGER
      ]
    }
  }

]

export default routes
