import { QUIZ_TYPE_DEFAULT } from '../components/mixins/valueObject/QuizTypeMixin'
import { TYPE_TEXT_ANSWERS } from './ValueObject/QuizLayout'

const DEFAULT_QUESTION_PER_PAGE = 2

export default {
  id: null,
  type: QUIZ_TYPE_DEFAULT,
  title: '',
  description: '',
  enabled: false,
  image: null,
  imageInfo: null,
  setting: {
    layout: TYPE_TEXT_ANSWERS,
    questionsPerPage: DEFAULT_QUESTION_PER_PAGE,
    showImage: true
  },
  questions: [],
  results: [],
  categories: [],
  aiGenerated: false
}
