import { ROLE_SUPER_ADMIN, ROLE_ESHOP_ADMIN } from '@/model/ValueObject/UserRole'

const routes = [
  {
    path: 'shop/top-product-setting',
    component: () => import('@/components/eshop/TopProductSetting'),
    name: 'eshopTopProductSetting_edit',
    meta: {
      description: 'TOP Product Setting edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_ESHOP_ADMIN
      ]
    }
  }
]

export default routes
