const messages = {
  blocks: {
    carTableBlock: {
      title: 'Car table',
      empty: 'Insert vehicle table',
      notEmpty: 'Is included table with ID:'
    },
    codeBlock: {
      title: 'Embed source code',
      placeholder: 'Write or paste your code...'
    },
    thirdPartyEmbedBlock: {
      title: 'Social media embed',
      empty: 'Insert a social media embed',
      notEmpty: 'Social media embed'
    },
    headingBlock: {
      title: 'Headings',
      placeholder: 'Your intermediate title...'
    },
    imageBlock: {
      deletePhoto: 'Change image',
      selectPhoto: 'Select image',
      title: 'Image',
      uploadPhoto: 'Upload image',
      uploadPhotoFromUrl: 'photo URL',
      author: 'Author'
    },
    infoBoxBlock: {
      title: 'Infobox',
      empty: 'Insert infobox',
      notEmpty: 'Infobox is inserted'
    },
    infographicBlock: {
      title: 'Infographic',
      empty: 'Insert infographic'
    },
    linkToGalleryBlock: {
      title: 'Link to gallery',
      page: 'Page Number - The serial number of the image in the image gallery in this article',
      text: 'Text of the link to the given page - the text of the link to the entered page number'
    },
    onlineSportMatchBlock: {
      title: 'Online match',
      matchId: 'Match ID',
      matchType: 'Category',
      categoryOther: 'Other',
      categoryHockey: 'Hockey',
      categoryFootball: 'Football',
      categoryTennis: 'Tennis'
    },
    orderedListBlock: {
      title: 'Ordered list'
    },
    pageBreakBlock: {
      title: 'Page break',
      notEmpty: 'A page break is inserted',
      desc: 'Page break (continued on next page)'
    },
    paragraphBlock: {
      title: 'Text',
      placeholder: 'Your text...'
    },
    placeholderBlock: {
      title: 'Placeholder',
      start: 'Start typing or add a content block...',
      end: 'Continue writing or add a content block...'
    },
    paidContentBreakBlock: {
      title: 'Paid content',
      notEmpty: 'Paid break is inserted',
      desc: 'Break - paid content'
    },
    pollBlock: {
      title: 'Poll',
      empty: 'Insert a poll',
      notEmpty: 'The poll is inserted'
    },
    quizBlock: {
      title: 'Quiz',
      empty: 'Insert a quiz',
      notEmpty: 'The quiz is inserted'
    },
    quoteBlock: {
      title: 'Quote',
      author: 'Name of author...',
      text: 'Your text...'
    },
    sportTableBlock: {
      title: 'Sport table',
      seasonId: 'Table ID',
      activeTab: 'Active tab (1 = first)'
    },
    relatedArticleBlock: {
      title: 'Related article',
      empty: 'Insert a related article'
    },
    unorderedListBlock: {
      title: 'Unordered list'
    },
    sourceBlock: {
      title: 'Sources'
    },
    videoBlock: {
      title: 'Video',
      empty: 'Insert a video'
    },
    youtubeBlock: {
      title: 'Youtube video',
      empty: 'Insert a youtube video'
    },
    tableBlock: {
      title: 'Table',
      empty: 'Insert a table'
    }
  },
  loading: 'Loading...',
  btns: {
    btnAddBlock: 'Add a new content block',
    close: 'Close',
    delete: 'Delete'
  },
  modals: {
    btn: {
      confirm: 'Confirm',
      cancel: 'Cancel'
    },
    copyPaste: {
      title: 'Paste your content from the clipboard',
      btnSend: 'Confirm'
    },
    inlineQuote: {
      title: 'Insert cited source',
      citeLabel: 'Source'
    },
    deleteBlock: {
      title: {
        all: 'Are you sure you want to delete <strong>all</strong> blocks?',
        single: 'Are you sure you want to delete this block?'
      }
    },
    selectBlock: {
      title: 'Select the content block for the article'
    },
    link: {
      title: 'Enter the url address',
      urlLabel: 'Url',
      titleLabel: 'Text',
      bodyBlockLabel: 'Link from article',
      invalidUrl: 'Invalid url address',
      invalidTitle: 'Text cannot be empty',
      targetLabel: 'Target',
      newWindow: 'In a new window',
      currentWindow: 'In the same window',
      relLabel: 'rel',
      notFilled: 'not filled out',
      nofollow: 'nofollow',
      btn: {
        confirm: 'Confirm',
        cancel: 'Cancel'
      }
    },
    thirdPartyEmbed: {
      titleSocialEmbed: 'Social media embed',
      titleInfographic: 'Infographic',
      storyError: 'You cannot insert a Story type Embed into an article. Insert only Post type Embed.',
      inputLabel: 'Paste the URL',
      btn: {
        confirm: 'Insert into the article'
      }
    },
    source: {
      title: 'Article sources',
      name: 'Source name',
      url: 'Source link',
      addSource: 'Add source'
    },
    htmlIdAttr: {
      titleRest: 'Insert html block <a href="https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/id" target="_blank">identifier</a>',
      info: 'Technically, the value for an id attribute may contain any character, except whitespace characters. However, to avoid inadvertent errors, only ASCII letters, digits, \'_\', and \'-\' should be used and the value for an id attribute should start with a letter. For example, . has a special meaning in CSS (it acts as a class selector). Unless you are careful to escape it in the CSS, it won\'t be recognized as part of the value of an id attribute. It is easy to forget to do this, resulting in bugs in your code that could be hard to detect.',
      existingLinkWarning: 'This ID is referenced by the text "{text}" in the "{htmlId}" block. If you edit the ID, the link will stop working.',
      duplicateId: 'This ID already exists in the article',
      generateUniqueId: 'Generate unique ID'
    }
  },
  tooltips: {
    addBlock: 'Add a content block',
    deleteBlock: 'Delete block',
    deleteBlocks: 'Delete all blocks',
    editBlock: 'Edit block',
    moveBlock: 'Move block',
    changeBlock: 'Change type',
    copyPaste: 'Import from your clipboard',
    htmlIdAttr: 'HTML ID attribute',
    undo: 'Undo',
    redo: 'Redo',
    bold: 'Bold',
    code: 'Code',
    italic: 'Italic',
    link: 'Link',
    unlink: 'Unlink',
    inlineQuote: 'Cited source',
    strike: 'Strike',
    underline: 'Underline',
    insertedTag: 'Text update',
    subscript: 'Subscript',
    superscript: 'Superscript',
    headingTitle: 'Heading {level}',
    table: {
      insertRowBefore: 'Insert row before',
      insertRowAfter: 'Insert row after',
      deleteRow: 'Delete row',
      insertColumnBefore: 'Insert column before',
      insertColumnAfter: 'Insert column after',
      deleteColumn: 'Delete column',
      mergeCells: 'Merge cells',
      splitCells: 'Split cells',
      toggleHeaderRow: 'Toggle header row',
      toggleHeaderColumn: 'Toggle header column'
    },
    align: {
      left: 'Align left',
      center: 'Align center',
      right: 'Align right'
    }
  }
}

export default messages
