import { ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'quote/new',
    component: () => import('../../views/quote/QuoteNewView'),
    name: 'quote_new',
    meta: {
      description: 'Quote new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'quote/:id/edit',
    component: () => import('../../views/quote/QuoteEditView'),
    name: 'quote_edit',
    meta: {
      description: 'Quote edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'quote/:id',
    component: () => import('../../views/quote/QuoteView'),
    name: 'quote_detail',
    meta: {
      description: 'Quote detail',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'quote',
    component: () => import('../../views/quote/QuoteListView'),
    name: 'quote_list',
    meta: {
      description: 'Quote list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  }
]

export default routes
