import MediaModel from '../../model/WidenMedia'
import CoreApi from '../../api/core'

const state = {
  detail: MediaModel,
  selectedGalleryMedia: [],
  selectedHeroImage: null,
  selectedListingImage: null
}

const mutations = {
  storeDetail (state, media) {
    state.detail = media
  },
  storeSelectedGalleryMedia (state, selectedGalleryMedia) {
    state.selectedGalleryMedia = selectedGalleryMedia
  },
  storeSelectedHeroImage (state, selectedHeroImage) {
    state.selectedHeroImage = selectedHeroImage
  },
  storeSelectedListingImage (state, selectedListingImage) {
    state.selectedListingImage = selectedListingImage
  }
}

const actions = {
  async fetchOne (store, id) {
    return await CoreApi().get('/media/' + id)
      .then(response => {
        store.commit('storeDetail', response.data)
      })
      .catch(error => console.log(error))
  },
  async updateMedia (store, media) {
    return await CoreApi().put('/media/' + media.id, JSON.stringify(media))
      .then(response => {
        if (response.status === 200) {
          store.commit('storeMedia', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  }
}

const getters = {
  detail (state) {
    return state.detail
  },
  selectedGalleryMedia (state) {
    return state.selectedGalleryMedia
  },
  selectedHeroImage (state) {
    return state.selectedHeroImage
  },
  selectedListingImage (state) {
    return state.selectedListingImage
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
