import ArticlePreview from '@/views/article/ArticlePreview'
import DashView from '@/components/dashboard/Dash.vue'
import LoginView from '@/components/user/UserLogin.vue'
import NotFoundView from '@/components/404.vue'

// Modules routes
import DashboardRoutes from '@/router/components/dashboardRoutes'
import WelcomeRoutes from '@/router/components/welcomeRoutes'
import ArticleRoutes from '@/router/components/articleRoutes'
import InfoboxRoutes from '@/router/components/infoboxRoutes'
import JokeRoutes from '@/router/components/jokeRoutes'
import QuoteRoutes from '@/router/components/quoteRoutes'
import AuthorRoutes from '@/router/components/authorRoutes'
import QuizRoutes from '@/router/components/quizRoutes'
import StatisticRoutes from '@/router/components/statisticRoutes'
import ContentBlockRoutes from '@/router/components/contentBlockRoutes'
import PollBlockRoutes from '@/router/components/pollBlockRoutes'
import ContentBlockItemRoutes from '@/router/components/contentBlockItemRoutes'
import PollRoutes from '@/router/components/pollRoutes'
import LikeDislikeRoutes from '@/router/components/likeDislikeRoutes'
import DamRoutes from '@/router/components/damRoutes'
import DonBoxRoutes from '@/router/components/donBoxRoutes'
import DonRoutes from '@/router/components/donRoutes'
import BeUserRoutes from '@/router/components/beUser/beUserRoutes'
import BeUserKpiRoutes from '@/router/components/beUser/beUserKpiRoutes'
import KpiRoutes from '@/router/components/kpiRoutes'
import EdonProductRoutes from '@/router/components/edonProductRoutes'
import FeUserRoutes from '@/router/components/feUser/feUserRoutes'
import FeUserDeliveryAddressRoutes from '@/router/components/feUser/feUserDeliveryAddressRoutes'
import FeUserBillingAddressRoutes from '@/router/components/feUser/feUserBillingAddressRoutes'
import FeUserArticleRoutes from '@/router/components/feUser/feUserArticleRoutes'
import FeUserPollVoteRoutes from '@/router/components/feUser/feUserPollVoteRoutes'
import SiteRoutes from '@/router/components/siteRoutes'
import RubricRoutes from '@/router/components/rubricRoutes'
import UserRoutes from '@/router/components/userRoutes'
import CategoryRoutes from '@/router/components/categoryRoutes'
import TagRoutes from '@/router/components/tagRoutes'
import SafetyTopicRoutes from '@/router/components/safetyTopicRoutes'
import RedirectRoutes from '@/router/components/redirectRoutes'
import FeatureRoutes from '@/router/components/featureRoutes'
import EshopFeatureRoutes from '@/router/components/eshop/featureRoutes'
import ArticleHistoryRoutes from '@/router/components/articleHistoryRoutes'
import Sport24Routes from '@/router/components/sport24Routes'
import HelpRoutes from '@/router/components/helpRoutes'
import UserLoginAttemptRoutes from '@/router/components/userLoginAttemptRoutes'
import TasrNewsRoutes from '@/router/components/tasrNewsRoutes'
import TasrNewsCategoryRoutes from '@/router/components/tasrNewsCategoryRoutes'
import PrintRoutes from '@/router/components/printRoutes'
import VideoRoutes from '@/router/components/videoRoutes'
import VideoShowRoutes from '@/router/components/videoShowRoutes'
import VideoCategoryRoutes from '@/router/components/videoCategoryRoutes'
import VideoManagementRoutes from '@/router/components/videoManagementRoutes'
import VideoStatsRoutes from '@/router/components/videoStatsRoutes'
import PrintArticleRoutes from '@/router/components/printArticleRoutes'
import DepartmentRoutes from '@/router/components/departmentRoutes'
import AppPerformanceRoutes from '@/router/components/appPerformanceRoutes'
import ScaleRoutes from '@/router/components/scaleRoutes'
import TopicRoutes from '@/router/components/topicRoutes'
import EshopSubscriptionProductRoutes from '@/router/components/eshop/subscriptionProductRoutes'
import EshopSubscriptionOrderRoutes from '@/router/components/eshop/subscriptionOrderRoutes'
import EshopHistoryRoutes from '@/router/components/eshop/historyRoutes'
import EshopSubscriptionPaymentRoutes from '@/router/components/eshop/subscriptionPaymentRoutes'
import EshopSubscriptionRoutes from '@/router/components/eshop/subscriptionRoutes'
import EshopSubscriberRoutes from '@/router/components/eshop/subscriberRoutes'
import EshopDashboard from '@/router/components/eshop/dashboardRoutes'
import EshopTopProductSetting from '@/router/components/eshop/topProductSettingRoutes'
import CarRoutes from '@/router/components/carRoutes'
import RestaurantRoutes from '@/router/components/restaurantRoutes'
import HotelRoutes from '@/router/components/hotelRoutes'
import TopicOfferRoutes from '@/router/components/topicOfferRoutes'
import RecipeRoutes from '@/router/components/recipeRoutes'
import HpTopicOffer from '@/router/components/hpTopicOfferRoutes'
import OneSignalRoutes from '@/router/components/oneSignalRoutes'
import WoodWing from '@/router/components/woodWingRoutes'
import KpiNmh from '@/router/components/kpiNmhRoutes'
import Gallery from '@/router/components/galleryRoutes'

// Routes
const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/preview/:id',
    name: 'preview',
    component: ArticlePreview
  },
  {
    path: '/',
    component: DashView,
    meta: {
      requiresAuth: true
    },
    children: DashboardRoutes.concat(
      WelcomeRoutes,
      ArticleRoutes,
      InfoboxRoutes,
      JokeRoutes,
      QuoteRoutes,
      AuthorRoutes,
      QuizRoutes,
      StatisticRoutes,
      ContentBlockRoutes,
      PollBlockRoutes,
      ContentBlockItemRoutes,
      PollRoutes,
      LikeDislikeRoutes,
      DamRoutes,
      DonBoxRoutes,
      DonRoutes,
      BeUserRoutes,
      BeUserKpiRoutes,
      KpiRoutes,
      EdonProductRoutes,
      FeUserRoutes,
      FeUserDeliveryAddressRoutes,
      FeUserBillingAddressRoutes,
      FeUserArticleRoutes,
      FeUserPollVoteRoutes,
      SiteRoutes,
      RubricRoutes,
      UserRoutes,
      CategoryRoutes,
      TagRoutes,
      SafetyTopicRoutes,
      RedirectRoutes,
      FeatureRoutes,
      ArticleHistoryRoutes,
      Sport24Routes,
      HelpRoutes,
      UserLoginAttemptRoutes,
      TasrNewsRoutes,
      TasrNewsCategoryRoutes,
      TopicOfferRoutes,
      PrintRoutes,
      VideoRoutes,
      VideoShowRoutes,
      VideoCategoryRoutes,
      VideoManagementRoutes,
      VideoStatsRoutes,
      PrintArticleRoutes,
      DepartmentRoutes,
      AppPerformanceRoutes,
      ScaleRoutes,
      TopicRoutes,
      EshopSubscriptionProductRoutes,
      EshopSubscriptionOrderRoutes,
      EshopHistoryRoutes,
      EshopSubscriptionPaymentRoutes,
      EshopSubscriptionRoutes,
      EshopSubscriberRoutes,
      EshopFeatureRoutes,
      EshopDashboard,
      EshopTopProductSetting,
      CarRoutes,
      RestaurantRoutes,
      HotelRoutes,
      RecipeRoutes,
      HpTopicOffer,
      OneSignalRoutes,
      WoodWing,
      KpiNmh,
      Gallery
    )
  },
  {
    // not found handler
    path: '*',
    component: NotFoundView
  }
]

export default routes
