import { ROLE_SUPER_ADMIN } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'scale/:id/edit',
    component: () => import('../../components/scale/ScaleEdit'),
    name: 'scale_edit',
    meta: {
      description: 'Scale edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'scale',
    component: () => import('../../components/scale/ScaleList'),
    name: 'scale_list',
    meta: {
      description: 'Scale list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  }
]

export default routes
