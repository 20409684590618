import moment from 'moment'

const buildFilterQuery = function (filter) {
  let filterQuery = ''

  if (filter.id) {
    filterQuery += '&filter_equals[id]=' + filter.id
  }
  filterQuery += '&filter_in[restrictedSites]='
  if (filter.restrictedSites.length === 0) {
    filterQuery += filter.availableSites.map(function (availableSite) {
      return availableSite
    }).concat('none').join(',')
  } else {
    filterQuery += filter.restrictedSites.map(function (restrictedSite) {
      return restrictedSite.name
    }).join(',')
  }

  return filterQuery
}

const buildSearchQuery = function (filter, isPdf) {
  let filterQuery = `&search_match[type]=${isPdf ? 'pdf' : 'image'}`
  const formatMoment = 'YYYY-MM-DD HH:mm:ss'
  const formatElastic = 'yyyy-MM-dd HH:mm:ss'

  if (filter.fulltext) {
    let fulltextFields = 'metadata.headline,metadata.description,metadata.caption,metadata.byLine,metadata.keywords'
    if (isPdf) {
      fulltextFields = `${fulltextFields},content`
    }
    filterQuery += '&search_text[' + fulltextFields + ']=' + filter.fulltext
  }
  if (filter.size_min) {
    filterQuery += '&search_range[size]={"gt":' + filter.size_min + '}'
  }
  if (filter.resolution) {
    filterQuery += '&search_range[resolution]={"gte":' + filter.resolution + '}'
  }
  if (filter.headline) {
    filterQuery += '&search_match[metadata.headline]=' + filter.headline
  }
  if (filter.description) {
    filterQuery += '&search_match[metadata.description]=' + filter.description
  }
  if (filter.byLine) {
    filterQuery += '&search_match[metadata.byLine]=' + filter.byLine
  }
  if (filter.keywords) {
    filterQuery += '&search_match[metadata.keywords]=' + filter.keywords
  }
  if (filter.personInImage) {
    filterQuery += '&search_match[metadata.personInImage]=' + filter.personInImage
  }
  if (filter.dateFrom && filter.dateTo === '') {
    const dateFrom = moment(filter.dateFrom).utc().format(formatMoment)
    filterQuery += '&search_range[createdAt]={"gte":"' + dateFrom + '", "format":"' + formatElastic + '"}'
  }
  if (filter.dateTo && filter.dateFrom === '') {
    const dateTo = moment(filter.dateTo).add(1, 'days').add(-1, 's').utc().format(formatMoment)
    filterQuery += '&search_range[createdAt]={"lte":"' + dateTo + '", "format":"' + formatElastic + '"}'
  }
  if (filter.dateFrom && filter.dateTo) {
    const dateFrom = moment(filter.dateFrom).utc().format(formatMoment)
    const dateTo = moment(filter.dateTo).add(1, 'days').add(-1, 's').utc().format(formatMoment)
    filterQuery += '&search_range[createdAt]=' +
      '{"gte":"' + dateFrom + '", "lte":"' + dateTo + '", "format":"' + formatElastic + '"}'
  }
  if (filter.dateTimeOriginalFrom && filter.dateTimeOriginalTo === '') {
    const dateTimeOriginalFrom = moment(filter.dateTimeOriginalFrom).utc().format(formatMoment)
    filterQuery += '&search_range[metadata.dateTimeOriginal]={"gte":"' + dateTimeOriginalFrom + '", "format":"' +
      formatElastic + '"}'
  }
  if (filter.dateTimeOriginalTo && filter.dateTimeOriginalFrom === '') {
    const dateTimeOriginalTo = moment(filter.dateTimeOriginalTo).add(1, 'days').add(-1, 's').utc().format(formatMoment)
    filterQuery += '&search_range[metadata.dateTimeOriginal]={"lte":"' + dateTimeOriginalTo + '", "format":"' +
      formatElastic + '"}'
  }
  if (filter.dateTimeOriginalFrom && filter.dateTimeOriginalTo) {
    const dateTimeOriginalFrom = moment(filter.dateTimeOriginalFrom).utc().format(formatMoment)
    const dateTimeOriginalTo = moment(filter.dateTimeOriginalTo).add(1, 'days').add(-1, 's').utc().format(formatMoment)
    filterQuery += '&search_range[metadata.dateTimeOriginal]=' +
      '{"gte":"' + dateTimeOriginalFrom + '", "lte":"' + dateTimeOriginalTo + '", "format":"' + formatElastic + '"}'
  }
  if (filter.uploadSource) {
    filterQuery += '&search_match[uploadSource]=' + filter.uploadSource
  }
  if (filter.uploadSource === 'ftp') {
    if (isPdf) {
      filterQuery += '&search_match_not[createdBy]=nmh.naxos.pdf'
    } else {
      filterQuery += '&search_match_not[createdBy]=nmh.tasr'
    }
  }
  if (filter.orientation) {
    filterQuery += '&search_match[orientation]=' + filter.orientation
  }
  if (filter.createdBy) {
    filterQuery += '&search_match[createdBy]=' + filter.createdBy
  }
  if (!isPdf) {
    filterQuery += '&search_match_array[restrictedSites]='
    if (filter.restrictedSites.length === 0) {
      filterQuery += filter.availableSites.map(function (availableSite) {
        return availableSite
      }).concat('none').join(',')
    } else {
      filterQuery += filter.restrictedSites.map(function (restrictedSite) {
        return restrictedSite.name
      }).join(',')
    }
  }

  return filterQuery
}

const getQuery = function (state) {
  const query = state.isPdf ? '/pdf' : '/image'
  const filter = state.filter

  // Use standard filter if ID of asset is not empty in the filter
  if (filter.id !== '') {
    return query + '?order[createdAt]=desc&limit=' + state.limit + buildFilterQuery(filter)
  }

  // Otherwise use elasticsearch.
  return query + '/search?page=' + state.page + '&limit=' + state.limit + buildSearchQuery(filter, state.isPdf)
}

export default {
  getQuery
}
