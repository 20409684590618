import { ROLE_SUPER_ADMIN, ROLE_TOPIC_EDITOR } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'topic/new',
    component: () => import('../../views/topic/TopicNewView'),
    name: 'topic_new',
    meta: {
      description: 'Topic new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_TOPIC_EDITOR
      ]
    }
  },
  {
    path: 'topic/:id/edit',
    component: () => import('../../views/topic/TopicEditView'),
    name: 'topic_edit',
    meta: {
      description: 'Topic edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_TOPIC_EDITOR
      ]
    }
  },
  {
    path: 'topic/:id',
    component: () => import('../../views/topic/TopicView'),
    name: 'topic_detail',
    meta: {
      description: 'Topic detail',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_TOPIC_EDITOR
      ]
    }
  },
  {
    path: 'topic',
    component: () => import('../../views/topic/TopicListView'),
    name: 'topic_list',
    meta: {
      description: 'Topic list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_TOPIC_EDITOR
      ]
    }
  }
]

export default routes
