import coreApi from '../../api/core'
import HpTopicOfferFilterModel from '../../model/HpTopicOfferFilter'
import _ from 'lodash'

const API_NAME = '/hpTopicOffer'

const state = {
  error: null,
  list: [],
  filter: _.cloneDeep(HpTopicOfferFilterModel),
  view: 'minimal',
  holidays: {}
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.data
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  resetFilter (state) {
    state.filter = _.cloneDeep(HpTopicOfferFilterModel)
  },
  setAutoLogoutArticle (state, article) {
    state.autoLogoutArticle = article
  },
  setView (state, view = 'minimal') {
    state.view = view
  },
  storeHolidays (state, holidays) {
    state.holidays = holidays
  }
}

const actions = {
  fetch (store) {
    if (store.state.filter.site === 0 || store.state.filter.date === null) return
    store.commit('TOGGLE_LOADING', null, { root: true })
    const limit = 1000
    const view = store.state.view
    const date = store.state.filter.date
    const site = store.state.filter.site
    const url = `${API_NAME}?view=${view}&limit=${limit}&filter_eq[date]=${date}&filter_eq[site]=${site}`
    return coreApi().get(url)
      .then(response => {
        store.commit('storeList', response.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  async updateApproved (store, { approved, id }) {
    return await coreApi().put(`${API_NAME}/${id}/approved/${approved ? 1 : 0}`)
      .then(response => {
        if (response.status === 200) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data)
        }
      })
  },
  async deleteRecord (store, record) {
    return await coreApi().delete(API_NAME + '/' + record.id)
      .then(response => {
        if (response.status === 204) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data)
        }
      })
  },
  async fetchHolidays (store, record) {
    const holidays = {}
    const years = [(new Date()).getFullYear(), (new Date()).getFullYear() + 1]
    years.map(async (year) => {
      const res = await fetch('https://holidayapi.com/countries/sk/' + year)
      let text = await res.text()
      text = text.match(new RegExp('<table.+holidays.+</table>', 'sm'))?.[0]
      const div = document.createElement('div')
      div.innerHTML = text
      holidays[year] = [...div.querySelectorAll('tbody tr td:first-child')]
        .filter((i) => i.style.fontWeight)
        .map((i) => i.innerText.replace(/[a-z]+$/, ''))
        .map((i) => (new Date(i + ' ' + year)).toJSON())
    })
    store.commit('storeHolidays', holidays)
  }
}

const getters = {
  list (state) {
    return Array.isArray(state.list) ? state.list : []
  },
  error (state) {
    return state.error
  },
  filter (state) {
    return state.filter
  },
  holidays (state) {
    return state.holidays
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
