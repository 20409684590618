<template>
  <app-modal
    :title="$t(titleMessage)"
    modal-size="modal-md"
    @close="close"
  >
    <!-- Body -->
    <template slot="body">
      <app-input
        v-model="article.field.title"
        @blur="$v.article.field.title.$touch()"
        :error="$v.article.field.title.$error"
        :label="`${$t('article.title')}*`"
        id="article_field_title"
        class="article_field_title_wrapper"
        show-counter
      >
      </app-input>
      <app-select
        v-model="article.site"
        @blur="$v.article.site.$touch()"
        :error="$v.article.site.$error"
        :options="sites"
        id="article_site"
        :label="`${$t('article.site')}*`"
        @change="loadMainRubricsBySite"
      >
      </app-select>
      <label for="article_mainRubric">{{ $t('article.main_rubric') }}*</label>
      <div class="form-group" :class="{'has-danger': $v.article.mainRubric.$error}">
        <select
          v-model="article.mainRubric"
          @blur="$v.article.mainRubric.$touch()"
          id="article_mainRubric"
          class="form-control"
        >
          <option
            v-for="(rubric, index) in rubricsBySite"
            :key="`item-${index}`"
            :value="rubric.id"
            :selected="rubric.id == article.mainRubric"
          >
            <template v-if="rubric.lvl === 1">&nbsp;&nbsp;&nbsp;</template>
            <template v-if="rubric.lvl === 2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</template>
            {{ rubric.title }}
          </option>
        </select>
        <SvgIconArrowDown class="select-area__arrow-icon" />
      </div>
      <div class="text-center">
        <button
          type="button"
          class="btn btn-success"
          @click="create"
          :disabled="disabledCreateButton"
          data-test="article_create_modal_btn_create"
        >
          <i v-if="confirmIcon" :class="confirmIcon" />
          {{ $t(confirmButtonText) }}
        </button>
      </div>
    </template>
  </app-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { dateTimeNow } from '@/services/DateTimeService'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { NEW_ARTICLE, TRANSFER_ARTICLE, QUIZ_ARTICLE } from '@/model/ValueObject/ArticleCreateTypes'
import SvgIconArrowDown from '@/assets/img/svg/arrow-down.svg?inline'
import Input from '../form/inputs/Input'
import Select from '../form/select/Select'
import NotifyService from '../../services/NotifyService'
import Modal from '../shared/Modal'

export default {
  name: 'ArticleCreateModal',
  props: {
    titleMessage: {
      type: String,
      default: 'article.modal.create_new_modal_header'
    },
    confirmButtonText: {
      type: String,
      default: 'article.modal.create_new_modal_create'
    },
    confirmIcon: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default () {
        return {
          field: {}
        }
      }
    },
    articleToCreate: {
      type: Object,
      required: false
    },
    articleSourceType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      rubricsBySite: [],
      article: {
        site: null,
        mainRubric: null,
        type: 'article',
        field: {
          title: '',
          shortTitle: '',
          subTitle: ''
        },
        setting: {
          editorialArticle: false,
          internalAuthor: false
        },
        safetyTopics: [],
        expanded: {
          sharedUsers: [],
          authors: []
        },
        sharedUsers: [],
        authors: [],
        publishedSince: dateTimeNow(),
        orderDate: dateTimeNow(),
        originalDocumentId: null
      },
      disabledCreateButton: false
    }
  },
  computed: {
    ...mapGetters(['vlm']),
    ...mapGetters('safetyTopic', ['safetyTopicByCode']),
    ...mapGetters('user', ['currentUser']),
    ...mapGetters('authors', ['list']),
    company () {
      return this.$store.getters.company
    },
    sites () {
      return this.enabledSites()
    },

    fieldsCharsLimit () {
      return {
        nmh: {
          title: {
            min: 1,
            max: 250
          }
        },
        vlm: {
          title: {
            min: 1,
            max: 95
          }
        }
      }
    }
  },
  validations () {
    return {
      article: {
        site: {
          required
        },
        mainRubric: {
          required
        },
        field: {
          title: {
            required,
            minLength: minLength(this.fieldsCharsLimit[this.company].title.min),
            maxLength: maxLength(this.fieldsCharsLimit[this.company].title.max)
          }
        }
      }
    }
  },
  components: {
    appModal: Modal,
    appInput: Input,
    appSelect: Select,
    SvgIconArrowDown
  },
  methods: {
    close () {
      this.$emit('close')
    },
    async loadMainRubricsBySite () {
      await this.$store.dispatch('rubric/fetchRubricsBySiteId', this.article.site)
      this.rubricsBySite = this.$store.state.rubric.all.filter(item => item.site === this.article.site)
    },
    enabledSites () {
      return this.$store.getters['site/enabledSites']()
    },
    async create () {
      this.disabledCreateButton = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('article.notify.please_fill_all_required_fields'))
        this.disabledCreateButton = false
        return
      }
      this.article.field.shortTitle = this.article.field.title
      this.article.field.subTitle = this.article.field.title
      await this.setDefaultAuthor()
      await this.$store.dispatch('article/create', this.article)
        .then(() => {
          this.disabledCreateButton = false
          if (this.$store.getters['article/error']) {
            NotifyService.setErrorMessage(this.$store.getters['article/error'])
            return
          }
          this.article = this.$store.getters['article/fullArticle']
          NotifyService.setSuccessMessage(this.$t('article.notify.new_draft_created'))
          if (this.article.id > 0) {
            this.$router.push(`/article/${this.article.id}/edit`)
          }
        })
        .catch(error => {
          this.disabledCreateButton = false
          console.log(error)
        })
    },
    async setDefaultAuthor () {
      if (this.currentUser?.id) {
        const defaultAuthor = await this.$store.dispatch(
          'author/fetchAuthorByUserId', { userId: this.currentUser.id, siteId: this.article.site }
        )
        if (defaultAuthor) {
          this.article.expanded.authors.push(defaultAuthor)
          this.article.authors.push(defaultAuthor.id)
        }
      }
    },
    setupNewArticleDefaults () {
      // Default values for the new article
      this.article.expanded.sharedUsers = [this.currentUser]
      this.article.sharedUsers = [this.currentUser.id]
      this.article.setting.editorialArticle = true
      this.article.setting.internalAuthor = true
      this.article.safetyTopics = [this.safetyTopicByCode('correct').id]
    },
    setupTransferArticleDefaults () {
      // Default values for the transferred article
      this.article.authors = []
      this.article.expanded.authors = []
      this.article.sharedUsers = [this.currentUser.id]
      this.article.expanded.sharedUsers = [this.currentUser]
    },
    setupQuizArticleDefaults () {
      // Default values for the article created from the quiz
      this.setupNewArticleDefaults()
    },
    setDefaults () {
      switch (this.articleSourceType) {
        case NEW_ARTICLE:
          this.setupNewArticleDefaults()
          break

        case TRANSFER_ARTICLE:
          this.setupTransferArticleDefaults()
          break

        case QUIZ_ARTICLE:
          this.setupQuizArticleDefaults()
          break

        default:
          throw new Error(`Unknown article source type: ${this.articleSourceType}`)
      }

      this.article.site = this.currentUser.defaultSite
      this.loadMainRubricsBySite()
      this.article.mainRubric = this.currentUser.defaultRubric
    }
  },
  async created () {
    if (this.articleToCreate) {
      this.article = {
        ...this.article,
        ...this.articleToCreate
      }
    }
    if (this.data.documentId) {
      await this.$store.dispatch('article/fetchOne', this.data.id)
      const article = this.$store.getters['article/detail']
      if (this.vlm) {
        article.setting.lockedTransferredArticle = true
      } else {
        delete article.field.firstParagraph
        delete article.field.subTitle
        delete article.field.title
      }
      article.originalDocumentId = article.documentId
      article.publishedSince = dateTimeNow()
      article.orderDate = dateTimeNow()
      article.setting.internalAuthor = false
      article.setting.agencyArticle = false
      article.setting.copiedFromPrint = false
      article.setting.externalAuthor = false
      article.setting.transferedArticle = true
      article.setting.aiAssisted = false
      delete article.id
      delete article.setting.status
      delete article.documentId
      delete article.createdBy
      delete article.modifiedBy
      delete article.draftModifiedBy
      delete article.draftModifiedAt
      delete article.articleTest
      delete article.publishedUntil
      delete article.rubrics
      delete article.isTransfered
      delete article.expanded.heroImage
      delete article.expanded.listingImage
      delete article.owners
      this.article = article
      this.setDefaults()
    } else {
      this.setDefaults()
    }
  }
}

</script>
  <!-- ####### Global SCSS ####### -->
  <style lang="scss">

  .article_field_title_wrapper {
    #article_field_title {
      border-radius: 0.375rem 0 0 0.375rem;
    }

    .input-group-append span {
      border-radius: 0 0.375rem 0.375rem 0;
    }
  }
</style>

<!-- ####### Scoped SCSS ####### -->
<style lang="scss" scoped>

.form-group {
  position: relative;

  #article_mainRubric {
    appearance: none;
    padding: 0 1.875rem 0 0.625rem;
    border: solid 1px #d1dbe4;
    border-radius: 0.375rem;
    color: #465674;
    font-family: "Roboto", sans-serif;
    font-size: 0.875rem;
    font-weight: 400;

    &:focus {
      box-shadow: none;
    }
  }
  .select-area__arrow-icon {
    position: absolute;
    top: calc(50% - 6px);
    right: rem(12px);
    width: rem(11px);
    fill: #9DAAC4;
    pointer-events: none;
  }
}

button:disabled {
  background: #8e8e8e;
  border-color: #8e8e8e;
}

label {
  color: #8A96AC;
  font-family: "Roboto", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
}

</style>
