import { ROLE_SUPER_ADMIN } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'tag/:id/edit',
    component: () => import('../../views/tag/TagEditView'),
    name: 'tag_edit',
    meta: {
      description: 'Tag edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'tag/new',
    component: () => import('../../views/tag/TagNewView'),
    name: 'tag_new',
    meta: {
      description: 'Tag new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'tag',
    component: () => import('../../views/tag/TagListView'),
    name: 'tag_list',
    meta: {
      description: 'Tag list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  }
]

export default routes
