import { ROLE_SUPER_ADMIN, ROLE_DEPARTMENT_MANAGER } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'edonProduct/:id/edit',
    component: () => import('../../views/edon/EdonProductEditView'),
    name: 'edonProduct_edit',
    meta: {
      description: 'EdonProduct edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_DEPARTMENT_MANAGER
      ]
    }
  },
  {
    path: 'edonProduct/new',
    component: () => import('../../views/edon/EdonProductNewView'),
    name: 'edonProduct_new',
    meta: {
      description: 'EdonProduct new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_DEPARTMENT_MANAGER
      ]
    }
  },
  {
    path: 'edonProduct',
    component: () => import('../../views/edon/EdonProductListView'),
    name: 'edonProduct_list',
    meta: {
      description: 'EdonProduct list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_DEPARTMENT_MANAGER
      ]
    }
  }
]

export default routes
