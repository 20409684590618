import DamService from '../../services/dam/DamService'

const __window = window
window.$activeSnippet = null

const addSnippetActions = (snippetActions, iframeSelector = '#bodyText_ifr') => {
  // BUTTON FOR CONTENT EDITING
  window.jQuery(iframeSelector).contents().find('body').on('click', '.fa-edit', function (e) {
    const elemTarget = window.jQuery(e.target)
    const elemSnippet = elemTarget.closest('.snippet-cb')
    elemSnippet.addClass('active-snippet')
    __window.$activeSnippet = elemSnippet

    if (elemSnippet.hasClass('snippet-quote')) {
      // quote modal
      snippetActions.showEditorQuoteModal(elemSnippet)
    } else if (elemSnippet.hasClass('snippet-ooyala')) {
      // video list modal
      snippetActions.showVideoListModal(elemSnippet)
    } else if (elemSnippet.hasClass('snippet-livebox')) {
      // video list modal
      snippetActions.showVideoListModal(elemSnippet)
    } else if (elemSnippet.hasClass('snippet-youtube')) {
      // youtube modal
      snippetActions.showYoutubeModal(elemSnippet)
    } else if (elemSnippet.hasClass('snippet-sport-table')) {
      // sport table modal
      snippetActions.showSportTableModal(elemSnippet)
    } else if (elemSnippet.hasClass('snippet-sport-online')) {
      // sport online modal
      snippetActions.showSportOnlineModal(elemSnippet)
    } else if (elemSnippet.hasClass('snippet-gallery-link')) {
      // gallery link modal
      snippetActions.showGalleryLinkModal(elemSnippet)
    } else if (elemSnippet.hasClass('snippet-car-table')) {
      // car table modal
      snippetActions.showCarTableModal(elemSnippet)
    } else if (elemSnippet.hasClass('snippet-code')) {
      // HTML embed modal
      snippetActions.showEditorHtmlModal(elemSnippet)
    } else if (elemSnippet.hasClass('snippet-articles')) {
      // related articles
      snippetActions.showRelatedArticleModal(elemSnippet)
    } else if (elemSnippet.hasClass('snippet-disease-articles')) {
      // related disease articles
      snippetActions.showRelatedDiseaseArticleModal(elemSnippet)
    } else if (elemSnippet.hasClass('snippet-poll')) {
      // polls
      snippetActions.showPollListModal(elemSnippet)
    } else if (elemSnippet.hasClass('snippet-infobox')) {
      // infobox
      snippetActions.showInfoboxListModal(elemSnippet)
    }
  })

  // REMOVE BUTTON
  window.jQuery(iframeSelector).contents().find('body').on('click', '.fa-trash', function (e) {
    const elemTarget = window.jQuery(e.target)
    const elemSnippet = elemTarget.closest('.snippet-cb')
    if (elemSnippet.length !== 0) {
      elemSnippet.remove()
    }
  })

  // SPECIAL BUTTON ACTIONS
  window.jQuery(iframeSelector).contents().find('body').on('click', '.eagle-config-button', function (e) {
    const elemTarget = window.jQuery(e.target)
    const elemSnippet = elemTarget.closest('.snippet-cb')
    elemSnippet.addClass('active-snippet')
    __window.$activeSnippet = elemSnippet

    if (elemSnippet.hasClass('snippet-articles')) {
      // related articles
      snippetActions.showRelatedArticleConfigModal(elemSnippet)
    }
  })

  // IMAGE SNIPPET (special handling)
  window.jQuery(iframeSelector).contents().find('body').on('click', '.cb-image-btn', function (e) {
    const elemTarget = window.jQuery(e.target)
    const elemSnippet = elemTarget.closest('.snippet-cb')
    if (elemSnippet.length !== 0) {
      const imgActiveElement = elemSnippet.find('img')
      let dataCbId = parseInt(imgActiveElement.attr('data-cb-id'))
      if (isNaN(dataCbId)) {
        dataCbId = 0
      }

      __window.$imgActive = imgActiveElement

      // from dam
      if (elemTarget.hasClass('cb-search-img-btn')) {
        snippetActions.showMediaSearchModal(imgActiveElement)
      }

      // upload new image
      if (elemTarget.hasClass('cb-upload-img-btn')) {
        snippetActions.showMediaUploadModal(imgActiveElement)
      }

      // upload by Url
      if (elemTarget.hasClass('cb-upload-by-link-img-btn')) {
        snippetActions.showMediaUrlModal(imgActiveElement)
      }

      // update image info
      if (elemTarget.hasClass('update-image-info')) {
        snippetActions.showMediaEditModal(imgActiveElement)
      }

      // update image info
      if (elemTarget.hasClass('remove-image')) {
        imgActiveElement[0].src = ''
        imgActiveElement[0].setAttribute('data-mce-src', '')
        imgActiveElement[0].setAttribute('data-embed-id', '')
        imgActiveElement[0].setAttribute('data-media-id', '')
        imgActiveElement[0].parentNode.parentNode.parentNode.classList.add('hidden')
      }
    }
  })
}

const image = (media = null, embedId = '') => {
  let imageSrc = ''
  let mediaId = ''
  let mediaAuthor = '&nbsp;'
  let mediaCaption = '&nbps;'
  let hiddenClass = 'hidden'
  if (media) {
    if (media.damMediaEmbed && media.damMediaEmbed.filename !== '') {
      imageSrc = DamService.getImagePath(media.damMediaEmbed.filename, 320, 180)
    }
    mediaId = media.id
    mediaAuthor = media.imageAttribution
    mediaCaption = media.imageCaption
    hiddenClass = ''
  }
  return `
      <div class="drag-and-drop uploaded snippet-cb snippet-image" contenteditable="false">
          <div class="row ${hiddenClass}">
              <div class="file">
                  <div style="float:left; margin-right:10px;" class="image">
                      <img src="${imageSrc}" alt="" data-media-id="${mediaId}" data-embed-id="${embedId}">
                  </div>
                  <div class="meta-info">
                      <span class="imageCaption">${mediaCaption}</span>
                      <strong>
                          <span class="imageAuthor">${mediaAuthor}</span>
                      </strong>
                      <div class="center-helper">
                          <button class="btn cb-image-btn update-image-info">
                              <em class="fa fa-refresh" aria-hidden="true">&nbsp;</em>Edit
                          </button>
                          <button class="btn cb-image-btn remove-image">
                              <em class="fa fa-trash" aria-hidden="true">&nbsp;</em>Remove
                          </button>
                      </div>
                  </div>
              </div>
              <div class="controls">
                  <button class="btn green cb-image-btn cb-search-img-btn">Vybrať z banky</button>
                  <button class="btn cb-image-btn cb-upload-img-btn"><em class="fa fa-upload" aria-hidden="true">&nbsp;</em>Nahrať z PC</button>
                  <button class="btn cb-image-btn cb-upload-by-link-img-btn"><em class="fa fa-link" aria-hidden="true">&nbsp;</em>Nahrať z URL</button>
              </div>
              <div class="icons">
                  <em class="fa fa-trash" aria-hidden="true">&nbsp;</em>
              </div>
          </div>
      </div>
    `
}

const ooyala = (videoId = '&nbsp;') => {
  return `
      <div class="snippet-cb snippet-widget snippet-ooyala snippet" contenteditable="false">
          <div class="icon ooyala"></div>
          <div class="inner">
              <span class="video-provider">video</span>
              <span class="video-link">${videoId}</span>
          </div>
          <div class="icons">
              <em class="fa fa-edit" aria-hidden="true">&nbsp;</em>
              <em class="fa fa-trash" aria-hidden="true">&nbsp;</em>
          </div>
      </div>
  `
}

const livebox = (videoId = '&nbsp;') => {
  return `
      <div class="snippet-cb snippet-widget snippet-livebox snippet" contenteditable="false">
          <div class="icon livebox"></div>
          <div class="inner">
              <span class="video-provider">video</span>
              <span class="video-link">${videoId}</span>
          </div>
          <div class="icons">
              <em class="fa fa-edit" aria-hidden="true">&nbsp;</em>
              <em class="fa fa-trash" aria-hidden="true">&nbsp;</em>
          </div>
      </div>
  `
}

const youtube = (videoId = '&nbsp;') => {
  return `
      <div class="snippet-cb snippet-widget snippet-youtube snippet" contenteditable="false">
          <div class="icon youtube">&nbsp;</div>
          <div class="inner">
              <span class="video-provider">youtube</span>
              <span class="video-link">${videoId}</span>
          </div>
          <div class="icons">
              <em class="fa fa-edit" aria-hidden="true">&nbsp;</em>
              <em class="fa fa-trash" aria-hidden="true">&nbsp;</em>
          </div>
      </div>
  `
}

const sportTable = (seasonId = '&nbsp;', activeTab = '1') => {
  return `
      <div class="snippet-cb snippet-widget snippet-sport-table snippet" contenteditable="false">
          <div class="icon fa fa-futbol-o">&nbsp;</div>
          <div class="inner">
              <span class="season-id">${seasonId}</span>
              <span> - <span class="active-tab">${activeTab}</span></span>
          </div>
          <div class="icons">
              <em class="fa fa-edit" aria-hidden="true">&nbsp;</em>
              <em class="fa fa-trash" aria-hidden="true">&nbsp;</em>
          </div>
      </div>
  `
}

const sportOnline = (matchId = '&nbsp;', category = 'other') => {
  return `
      <div class="snippet-cb snippet-widget snippet-sport-online snippet" contenteditable="false">
          <div class="icon fa fa-circle">&nbsp;</div>
          <div class="inner">
              <span class="match-id">${matchId}</span>
              <span> - <span class="match-category">${category}</span></span>
          </div>
          <div class="icons">
              <em class="fa fa-edit" aria-hidden="true">&nbsp;</em>
              <em class="fa fa-trash" aria-hidden="true">&nbsp;</em>
          </div>
      </div>
  `
}

const carTable = (ids = '&nbsp;') => {
  return `
      <div class="snippet-cb snippet-widget snippet-car-table snippet" contenteditable="false">
          <div class="icon fa fa-car">&nbsp;</div>
          <div class="inner">
              <span class="car-ids">${ids}</span>
          </div>
          <div class="icons">
              <em class="fa fa-edit" aria-hidden="true">&nbsp;</em>
              <em class="fa fa-trash" aria-hidden="true">&nbsp;</em>
          </div>
      </div>
  `
}

const htmlEmbed = (embedContent = '') => {
  return `
      <div class="snippet-cb snippet-widget snippet-code snippet" contenteditable="false">
          <div class="icon">
              <em class="fa fa-code" aria-hidden="true">&nbsp;</em>
          </div>
          <div class="inner">
              <span class="embed-name" data-embed-encoded="true" data-embed-content="${embedContent}">HTML Embed</span>
          </div>
          <div class="icons">
              <em class="fa fa-edit" aria-hidden="true">&nbsp;</em>
              <em class="fa fa-trash" aria-hidden="true">&nbsp;</em>
          </div>
      </div>
  `
}

const infobox = (infobox = null) => {
  let infoboxId = ''
  let infoboxTitle = 'Infobox'
  if (infobox) {
    infoboxId = infobox.id
    infoboxTitle = infobox.title
  }
  return `
      <div class="snippet-cb snippet-widget snippet-infobox snippet" contenteditable="false">
          <div class="icon">
                <em class="fa fa-sticky-note" aria-hidden="true">&nbsp;</em>
          </div>
          <div class="inner" data-infobox-id="${infoboxId}">
                ${infoboxTitle}
          </div>
          <div class="icons">
              <em class="fa fa-edit" aria-hidden="true">&nbsp;</em>
              <em class="fa fa-trash" aria-hidden="true">&nbsp;</em>
          </div>
      </div>
  `
}

const pageBreak = () => {
  return `
      <div class="snippet-cb snippet-widget snippet-page-break snippet" contenteditable="false">
          <div class="icon"><em class="fa fa-file" aria-hidden="true">&nbsp;</em></div>
          <div class="inner">
              <span class="embed-name">Zalomenie strany</span>
          </div>
          <div class="icons">
              <em class="fa fa-trash" aria-hidden="true">&nbsp;</em>
          </div>
      </div>
  `
}

const paidContentBreak = () => {
  return `
      <div class="snippet-cb snippet-widget snippet-paid-content-break snippet" contenteditable="false">
          <div class="icon"><em class="fa fa-lock" aria-hidden="true">&nbsp;</em></div>
          <div class="inner">
              <span class="embed-name">Bod uzamknutia</span>
          </div>
          <div class="icons">
              <em class="fa fa-trash" aria-hidden="true">&nbsp;</em>
          </div>
      </div>
  `
}

const galleryLink = (pageNumber = '1', linkText = '&nbsp;') => {
  return `
      <div class="snippet-cb snippet-widget snippet-gallery-link snippet" contenteditable="false">
          <div class="icon fa fa-file-image-o">&nbsp;</div>
          <div class="inner">
              <span class="page-number">${pageNumber}</span>&nbsp;|&nbsp;
              <span class="link-text">${linkText}</span>
          </div>
          <div class="icons">
              <em class="fa fa-edit" aria-hidden="true">&nbsp;</em>
              <em class="fa fa-trash" aria-hidden="true">&nbsp;</em>
          </div>
      </div>
  `
}

const poll = (pollId = '', poll = null) => {
  let pollTitle = 'Anketa'
  if (poll) {
    pollTitle = poll.title
  }
  return `
      <div class="snippet-cb snippet-widget snippet-poll snippet" contenteditable="false">
          <div class="icon">
                <em class="fa fa-bar-chart" aria-hidden="true">&nbsp;</em>
          </div>
          <div class="inner" data-poll-id="${pollId}">
                ${pollTitle}
          </div>
          <div class="icons">
              <em class="fa fa-edit" aria-hidden="true">&nbsp;</em>
              <em class="fa fa-trash" aria-hidden="true">&nbsp;</em>
          </div>
      </div>
  `
}

const quote = (text = '&nbsp;', author = '&nbsp;') => {
  return `
      <div class="snippet-cb snippet-widget snippet-quote snippet" contenteditable="false">
        <div class="icon"><em class="fa fa-quote-left">&nbsp;</em></div>
        <div class="inner">
            <span class="quoted-text">${text}</span>
            <span class="author">- <span class="author-name">${author}</span></span>
        </div>
        <div class="icons">
            <em class="fa fa-edit" aria-hidden="true">&nbsp;</em>
            <em class="fa fa-trash" aria-hidden="true">&nbsp;</em>
        </div>
      </div>
  `
}

const relatedArticle = (article = null, articleDocumentId = '', articleTag = '&nbsp;') => {
  let articleTitle = 'Related article title'
  if (articleDocumentId !== '') {
    articleTitle = articleDocumentId
  }
  if (articleTag !== '') {
    articleTitle = articleDocumentId
  }
  if (article) {
    articleDocumentId = article.documentId
    articleTitle = article.field.title
  }
  return `
      <div class="snippet-cb snippet-widget snippet-articles snippet" contenteditable="false">
          <div class="icon">
              <em class="fa fa-file-text-o" aria-hidden="true" role="button">&nbsp;</em>
          </div>
          <div class="inner" data-article-tag="${articleTag}" data-article-id="${articleDocumentId}">
              <span class="inner-articleTag">${articleTag}</span>
              <br class="inner-break ${articleTag === '&nbsp;' ? 'hide' : ''}">
              <span class="inner-articleTitle">${articleTitle}</span>
          </div>
          <div class="icons">
              <em class="fa fa-cog eagle-config-button" aria-hidden="true" role="button">&nbsp;</em>
              <em class="fa fa-edit" aria-hidden="true" role="button">&nbsp;</em>
              <em class="fa fa-trash" aria-hidden="true" role="button">&nbsp;</em>
          </div>
      </div>
  `
}

const relatedDiseaseArticle = (article = null, articleDocumentId = '', articleTag = '&nbsp;') => {
  let articleTitle = 'Related disease article title'
  if (articleDocumentId !== '') {
    articleTitle = articleDocumentId
  }
  if (articleTag !== '') {
    articleTitle = articleDocumentId
  }
  if (article) {
    articleDocumentId = article.documentId
    articleTitle = article.field.title
  }
  return `
      <div class="snippet-cb snippet-widget snippet-disease-articles snippet" contenteditable="false">
          <div class="icon">
              <em class="fa fas fa-stethoscope" aria-hidden="true" role="button">&nbsp;</em>
          </div>
          <div class="inner" data-article-tag="${articleTag}" data-article-id="${articleDocumentId}">
              <span class="inner-articleTag">${articleTag}</span>
              <br class="inner-break ${articleTag === '&nbsp;' ? 'hide' : ''}">
              <span class="inner-articleTitle">${articleTitle}</span>
          </div>
          <div class="icons">
              <em class="fa fa-cog eagle-config-button" aria-hidden="true" role="button">&nbsp;</em>
              <em class="fa fa-edit" aria-hidden="true" role="button">&nbsp;</em>
              <em class="fa fa-trash" aria-hidden="true" role="button">&nbsp;</em>
          </div>
      </div>
  `
}

export default {
  addSnippetActions,
  image,
  ooyala,
  livebox,
  youtube,
  sportTable,
  sportOnline,
  carTable,
  htmlEmbed,
  infobox,
  pageBreak,
  paidContentBreak,
  galleryLink,
  poll,
  quote,
  relatedArticle,
  relatedDiseaseArticle
}
