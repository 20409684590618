import { ROLE_EDITOR, ROLE_JOURNALIST, ROLE_SUPER_ADMIN } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'quiz/:id/edit',
    component: () => import('../../views/quiz/QuizEditView'),
    name: 'quiz_edit',
    meta: {
      description: 'Quiz edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'quiz/:id',
    component: () => import('../../views/quiz/QuizView'),
    name: 'quiz_detail',
    meta: {
      description: 'Quiz detail',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'quiz/new',
    component: () => import('../../views/quiz/QuizNewView'),
    name: 'quiz_new',
    meta: {
      description: 'Quiz new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'quiz',
    component: () => import('../../views/quiz/QuizListView'),
    name: 'quiz_list',
    meta: {
      description: 'Quiz list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'oneQuestion/:id/edit',
    component: () => import('../../views/quiz/QuizOneQuestionEditView'),
    name: 'oneQuestion_edit',
    meta: {
      description: 'One question edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'oneQuestion/:id',
    component: () => import('../../views/quiz/QuizOneQuestionView'),
    name: 'oneQuestion_detail',
    meta: {
      description: 'One question detail',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'oneQuestion/new',
    component: () => import('../../views/quiz/QuizOneQuestionNewView'),
    name: 'oneQuestion_new',
    meta: {
      description: 'One question new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'oneQuestion',
    component: () => import('../../views/quiz/QuizOneQuestionListView'),
    name: 'oneQuestion_list',
    meta: {
      description: 'One question list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  }
]

export default routes
