import Config from '../../config/'

const mimeTypeFileExtensionMap = {
  'application/pdf': 'pdf',
  'application/x-pdf': 'pdf'
}

const getPdfPath = function (basename) {
  return Config.dam.baseUrl() + '/pdf/' + basename
}

const getPdfPreviewPath = function (basename) {
  return Config.dam.baseUrl() + '/pdf/preview/' + basename
}

const getFileExtensionByMimeType = function (mimeType) {
  if (!Object.prototype.hasOwnProperty.call(mimeTypeFileExtensionMap, mimeType)) {
    return null
  }
  return mimeTypeFileExtensionMap[mimeType]
}

export default {
  getPdfPath,
  getPdfPreviewPath,
  getFileExtensionByMimeType
}
