/* eslint no-unused-vars: 0 */

import TinyMceSnippet from './TinyMceSnippet'

const parseEmbeddedImage = (mediaId, article) => {
  const mediaEmbed = article.articleEmbeds.find(function (embed) {
    if (embed.discriminator === 'media' && embed.media.discriminator === 'widen' && embed.media.id === parseInt(mediaId)) {
      return embed
    }
  })
  if (mediaEmbed === undefined) {
    return ''
  }
  return TinyMceSnippet.image(mediaEmbed.media, mediaEmbed.id)
}

const parseEmbeddedHTML = (embedContent) => {
  return TinyMceSnippet.htmlEmbed(embedContent)
}

const parseEmbeddedArticle = (articleDocumentIdAndTag, article, embedsRelatedArticles) => {
  const parts = articleDocumentIdAndTag.split('|')
  let tag = '&nbsp;'
  if (parts.length === 1 || parts.length === 2) {
    const relatedArticleEmbed = article.articleEmbeds.find(function (embed) {
      return embed.discriminator === 'relatedArticle' && embed.relatedArticleDocId === parts[0]
    })
    if (relatedArticleEmbed === undefined) {
      return ''
    }
    const relatedArticle = embedsRelatedArticles.find(function (article) {
      return article.documentId === relatedArticleEmbed.relatedArticleDocId
    })
    if (parts.length === 2 && parts[1].length > 0) {
      tag = parts[1]
    }
    return TinyMceSnippet.relatedArticle(relatedArticle, parts[0], tag)
  } else {
    return ''
  }
}

const parseEmbeddedDiseaseArticle = (articleDocumentIdAndTag, article, embedsRelatedDiseaseArticles) => {
  const parts = articleDocumentIdAndTag.split('|')
  let tag = '&nbsp;'
  if (parts.length === 1 || parts.length === 2) {
    const relatedDiseaseArticleEmbed = article.articleEmbeds.find(function (embed) {
      return embed.discriminator === 'relatedDiseaseArticle' && embed.relatedDiseaseArticleDocId === parts[0]
    })
    if (relatedDiseaseArticleEmbed === undefined) {
      return ''
    }
    const relatedDiseaseArticle = embedsRelatedDiseaseArticles.find(function (article) {
      return article.documentId === relatedDiseaseArticleEmbed.relatedDiseaseArticleDocId
    })
    if (parts.length === 2 && parts[1].length > 0) {
      tag = parts[1]
    }
    return TinyMceSnippet.relatedDiseaseArticle(relatedDiseaseArticle, parts[0], tag)
  } else {
    return ''
  }
}

const parseEmbededPoll = (pollId, article) => {
  return TinyMceSnippet.poll(pollId)
}

const parseEmbededInfobox = (infoboxId, article) => {
  const infoboxEmbed = article.articleEmbeds.find(function (embed) {
    return embed.discriminator === 'infobox' && embed.infobox.id === parseInt(infoboxId)
  })
  return TinyMceSnippet.infobox(infoboxEmbed.infobox)
}

const parseEmbeddedYoutubeVideo = (videoId) => {
  return TinyMceSnippet.youtube(videoId)
}

const parseEmbeddedSportTable = (seasonIdAndActiveTab) => {
  const parts = seasonIdAndActiveTab.split('|')
  return TinyMceSnippet.sportTable(parts[0], parts[1])
}

const parseEmbeddedSportOnline = (matchIdAndCategory) => {
  const parts = matchIdAndCategory.split('|')
  return TinyMceSnippet.sportOnline(parts[0], parts[1])
}

const parseEmbeddedCarTable = (carIds) => {
  return TinyMceSnippet.carTable(carIds)
}

const parseEmbeddedOoyalaVideo = (videoId) => {
  return TinyMceSnippet.ooyala(videoId)
}

const parseEmbeddedLiveboxVideo = (videoId) => {
  return TinyMceSnippet.livebox(videoId)
}

const parsePageBreak = () => {
  return TinyMceSnippet.pageBreak()
}

const parsePaidContentBreak = () => {
  return TinyMceSnippet.paidContentBreak()
}

const parseEmbeddedGalleryLink = (pageAndText) => {
  const parts = pageAndText.split('|')
  return TinyMceSnippet.galleryLink(parts[0], parts[1])
}

const parseAll = (content, article, embedsRelatedArticles) => {
  // media_widen
  content = content.replace(
    /###{EMBED_MEDIA_WIDEN:([^}]+)}###/g, function (match, capture) {
      return parseEmbeddedImage(capture, article)
    }
  )

  // article
  content = content.replace(
    /###{EMBED_ARTICLE:([^}]+)}###/g, function (match, capture) {
      return parseEmbeddedArticle(capture, article, embedsRelatedArticles)
    }
  )

  // disease article
  content = content.replace(
    /###{EMBED_DISEASE_ARTICLE:([^}]+)}###/g, function (match, capture) {
      return parseEmbeddedDiseaseArticle(capture, article, embedsRelatedArticles)
    }
  )

  // infobox
  content = content.replace(
    /###{EMBED_INFOBOX:([^}]+)}###/g, function (match, capture) {
      return parseEmbededInfobox(capture, article)
    }
  )

  // poll
  content = content.replace(
    /###{EMBED_POLL:([^}]+)}###/g, function (match, capture) {
      return parseEmbededPoll(capture, article)
    }
  )

  // page break
  content = content.replace(
    /###{PAGEBREAK}###/g, function (match, capture) {
      return parsePageBreak()
    }
  )

  // paid content break
  content = content.replace(
    /###{PAIDCONTENTBREAK}###/g, function (match, capture) {
      return parsePaidContentBreak()
    }
  )

  // gallery link
  content = content.replace(
    /###{EMBED_GALLERY_LINK:([^}]+)}###/g, function (match, capture) {
      return parseEmbeddedGalleryLink(capture)
    }
  )

  // quote
  const contentNode = document.createElement('div')
  contentNode.innerHTML = content
  const blockQuoteNodes = contentNode.querySelectorAll('blockquote')
  blockQuoteNodes.forEach(blockQuoteNode => {
    let bodyText = ''
    if (blockQuoteNode.querySelector('span')) {
      bodyText = blockQuoteNode.querySelector('span').innerHTML
    }
    let footerText = ''
    if (blockQuoteNode.querySelector('footer')) {
      footerText = blockQuoteNode.querySelector('footer').innerHTML
    }
    content = content.replace(
      blockQuoteNode.outerHTML,
      TinyMceSnippet.quote(bodyText, footerText)
    )
  })

  // HTML
  content = content.replace(
    /###{EMBED_HTML:([^}]+)}###/g, function (match, capture) {
      return parseEmbeddedHTML(capture, article)
    }
  )

  // youtube
  content = content.replace(
    /###{EMBED_YOUTUBE:([^}]+)}###/g, function (match, capture) {
      return parseEmbeddedYoutubeVideo(capture)
    }
  )

  // sport table
  content = content.replace(
    /###{EMBED_SPORT_TABLE:([^}]+)}###/g, function (match, capture) {
      return parseEmbeddedSportTable(capture)
    }
  )

  // sport online
  content = content.replace(
    /###{EMBED_SPORT_ONLINE:([^}]+)}###/g, function (match, capture) {
      return parseEmbeddedSportOnline(capture)
    }
  )

  // car table
  content = content.replace(
    /###{EMBED_CAR_TABLE:([^}]+)}###/g, function (match, capture) {
      return parseEmbeddedCarTable(capture)
    }
  )

  // ooyala
  content = content.replace(
    /###{EMBED_OOYALA:([^}]+)}###/g, function (match, capture) {
      return parseEmbeddedOoyalaVideo(capture)
    }
  )

  // livebox
  content = content.replace(
    /###{EMBED_LIVEBOX:([^}]+)}###/g, function (match, capture) {
      return parseEmbeddedLiveboxVideo(capture)
    }
  )

  // remove unsupported embeds
  // media_widen_ez
  content = content.replace(
    /###{EMBED_MEDIA_WIDEN_EZ:([^}]+)}###/g,
    ''
  )

  // vju
  content = content.replace(
    /###{EMBED_VJU:([^}]+)}###/g,
    ''
  )

  return content
}

export default {
  parseAll,
  parseEmbeddedYoutubeVideo,
  parseEmbeddedSportTable,
  parseEmbeddedSportOnline,
  parseEmbeddedOoyalaVideo,
  parseEmbeddedLiveboxVideo
}
