import { ROLE_SUPER_ADMIN, ROLE_ESHOP_ADMIN } from '../../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'shop/dashboard',
    component: () => import('../../../components/eshop/Dashboard'),
    name: 'eshopDashboard',
    meta: {
      description: 'Dashboard',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_ESHOP_ADMIN
      ]
    }
  }
]

export default routes
