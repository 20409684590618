import config from '@/config'
import OneSignalModel from '@/model/OneSignalModel'
import OneSignalApi from '@/api/oneSignal'
import NotifyService from '@/services/NotifyService'

const API_NAME = '/api/oneSignal/settings'

const callApi = async (store, callback) => {
  try {
    store.commit('TOGGLE_LOADING', null, { root: true })
    return callback()
  } catch (error) {
    console.error(error)
    NotifyService.setErrorMessage(error)
    return null
  } finally {
    store.commit('TOGGLE_LOADING', null, { root: true })
  }
}

const state = {
  list: [],
  detail: OneSignalModel,
  totalCount: 0,
  page: 1
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.results
    state.totalCount = responseData.count
    state.page = responseData.page
  },
  storeDetail (state, item) {
    state.detail = item
  },
  setPage (state, page) {
    state.page = page
  }
}

const actions = {
  async fetch (store) {
    return callApi(store, async () => {
      const { limit } = config.defaults.list
      const url = `api/onesignal/settings?limit=${limit}&page=${this.page}`
      const response = await OneSignalApi().get(url)
      store.commit('storeList', response.data)
      return response.data
    })
  },
  async fetchOne (store, siteUuid) {
    return callApi(store, async () => {
      const url = `api/onesignal/settings/${siteUuid}`
      const response = await OneSignalApi().get(url)
      store.commit('storeDetail', response.data)
      return response.data
    })
  },
  async deleteRecord (store, { siteUuid }) {
    await callApi(store, async () => {
      const url = `${API_NAME}/${siteUuid}`
      await OneSignalApi().delete(url)
      NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
    })
  },
  async getRemainingNotification (store, siteUuid) {
    return callApi(store, async () => {
      const url = `${API_NAME}/${siteUuid}/limit`
      return OneSignalApi().get(url)
    })
  }
}

const getters = {
  detail (state) {
    return state.detail
  },
  list (state) {
    return state.list
  },
  totalCount (state) {
    return state.totalCount
  },
  page (state) {
    return state.page
  },
  error (state) {
    return state.page
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
