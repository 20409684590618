import { ROLE_SUPER_ADMIN, ROLE_PRINT_PUBLICATION } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'printTitle/:id/edit',
    component: () => import('../../components/print/PrintTitleEdit'),
    name: 'printTitle_edit',
    meta: {
      description: 'Print Title edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'printTitle/:id',
    component: () => import('../../components/print/PrintTitle'),
    name: 'printTitle_detail',
    meta: {
      description: 'Print Title detail',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'printTitle/new',
    component: () => import('../../components/print/PrintTitleNew'),
    name: 'printTitle_new',
    meta: {
      description: 'Print Title new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'printTitle',
    component: () => import('../../components/print/PrintTitleList'),
    name: 'printTitle_list',
    meta: {
      description: 'Print Title',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'printPublicationPreview',
    component: () => import('../../components/print/PrintPublicationPreview'),
    name: 'printPublication_preview',
    meta: {
      description: 'Print Publication preview',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_PRINT_PUBLICATION
      ]
    }
  },
  {
    path: 'printPublication/:id/edit',
    component: () => import('../../components/print/PrintPublicationEdit'),
    name: 'printPublication_edit',
    meta: {
      description: 'Print Publication edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_PRINT_PUBLICATION
      ]
    }
  },
  {
    path: 'printPublication/:id',
    component: () => import('../../components/print/PrintPublication'),
    name: 'printPublication_detail',
    meta: {
      description: 'Print Publication detail',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_PRINT_PUBLICATION
      ]
    }
  },
  {
    path: 'printPublication/new',
    component: () => import('../../components/print/PrintPublicationNew'),
    name: 'printPublication_new',
    meta: {
      description: 'Print Publication new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_PRINT_PUBLICATION
      ]
    }
  },
  {
    path: 'printPublication',
    component: () => import('../../components/print/PrintPublicationList'),
    name: 'printPublication_list',
    meta: {
      description: 'Print Publication',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_PRINT_PUBLICATION
      ]
    }
  }
]

export default routes
