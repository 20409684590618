import { ROLE_SUPER_ADMIN } from '../../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'eshopFeature/:id/edit',
    component: () => import('../../../components/eshop/feature/FeatureEditView'),
    name: 'eshopFeature_edit',
    meta: {
      description: 'Eshop feature edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'eshopFeature',
    component: () => import('../../../components/eshop/feature/FeatureListView'),
    name: 'eshopFeature_list',
    meta: {
      description: 'Eshop feature list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  }
]

export default routes
