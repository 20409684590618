export const QUIZ_TYPE_PERSONALITY = 'personality'
export const QUIZ_TYPE_CORRECT_ANSWER = 'correct-answer'
export const QUIZ_TYPE_ONE_QUESTION = 'one-question'

export const QUIZ_TYPE_DEFAULT = QUIZ_TYPE_CORRECT_ANSWER

export default {
  computed: {
    quizTypeValues () {
      return [
        {
          id: QUIZ_TYPE_PERSONALITY,
          title: this.$t('quiz.quiz_type.personality')
        },
        {
          id: QUIZ_TYPE_CORRECT_ANSWER,
          title: this.$t('quiz.quiz_type.correct_answer')
        },
        {
          id: QUIZ_TYPE_ONE_QUESTION,
          title: this.$t('quiz.quiz_type.one_question')
        }
      ]
    }
  }
}
