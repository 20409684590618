const messages = {
  filter: {
    complete: 'Dokončeno',
    fulltext_search: 'Vyhledávání',
    fulltext_search_placeholder: 'Hledat všude (titulek, popis, klíčová slova)',
    date_from: 'Nahrané od',
    date_to: 'Nahrané do',
    description: 'Popis',
    keywords: 'Klíčová slova',
    author: 'Autor',
    brand: 'Nahrané redakcí',
    id: 'Id',
    reset_filter: 'Zrušit filtr',
    upload_source: 'Zdroj nahrání',
    orientation: 'Orientace',
    orientation_landscape: 'Na šířku',
    orientation_portrait: 'Na výšku',
    orientation_square: 'Čtvercové',
    size: 'Velikost',
    size_gt: 'Větší než {size}MB',
    resolution: 'Rozlišení',
    resolution_gt: 'Větší než {resolution} Mpx',
    created_by: 'Nahrál',
    show_by: 'Zobrazit podla',
    show_by_upload_date: 'Dátum nahrání do DAM',
    show_by_original_date: 'Dátum vyfocení',
    search: 'Hledat',
    restricted_sites_placeholder: 'Omezení na weby',
    restricted_sites: 'Povolené použití',
    show_thumbnail_placeholder: 'Zapnout náhled první stránky',
    person_in_image: 'Člověk na obrázku',
    date_origin_from: 'Vyfoceno od',
    date_origin_to: 'Vyfoceno do',
    photo_series: 'Série fotografií',
    pdf: {
      date_origin_from: 'Vytvořeno od',
      date_origin_to: 'Vytvořeno do'
    }
  },
  selected_photos_count: 'Počet vybraných fotek',
  display_type_compact: 'Kompaktní zobrazení',
  display_type_full: 'Plné zobrazení',
  load_more: 'Načíst více',
  asset_image_info: 'Detail fotky',
  asset_pdf_info: 'Detail PDF',
  dam_generic_info: 'DAM Všeobecné informace',
  update_asset_metadata: 'Upravit informace',
  system_info: 'Systémové informace',
  article_reference: 'Použití v článcích',
  id: 'Id',
  filename: 'Jméno souboru',
  file_location: 'Umístění souboru',
  created: 'Nahráno do DAM',
  upload_source: 'Zdroj nahrání',
  restricted_sites: 'Povolené použití',
  person_in_image: 'Člověk na obrázku',
  size: 'Velikost',
  dimensions: 'Rozměry',
  datetime_original: 'Datum vyfocení',
  datetime_original_pdf: 'Datum vytvoření',
  location_show: 'Zobrazení polohy',
  category: 'Kategorie',
  published: 'Publikované',
  brand_safety: 'Brand safety',
  persion_in_image: 'Člověk na obrázku',
  metadata: 'Metadata',
  headline: 'Titulek',
  headline_seo_info: '<b>Obrázky</b><br>Název obrázku by měl jednou větou popisovat to, co obrázek reálně zobrazuje.',
  by_line: 'Autor',
  caption: 'Nadpis',
  content: 'Obsah',
  content_load: 'Načíst obsah',
  copy_id: 'Kopírovat ID',
  caption_writer: 'Autor titulku',
  city: 'Město',
  country: 'Země',
  country_code: 'Kód země',
  description: 'Popis',
  keywords: 'Klíčová slova',
  credit: 'Kredit',
  rights: 'Práva',
  source: 'Zdroj',
  delete_modal_header: 'Odstranit tento záznam?',
  delete_more_modal_image: 'fotky',
  delete_more_modal_documents: 'dokumenty',
  delete_more_modal_header: 'Vymazat {recordTypeName}',
  delete_more_modal_body: 'Opravdu si přejete vymazat {recordTypeName} z Eagle CMS? Tato operace je nevratná a {recordTypeName} budou vymazány z DAM a ze všech článků, kde jsou použity.<br>' +
    '<b>Tato operace se dotkne těchto článků.</b> V rámci korektnosti prosím informujte autory článků, že dané {recordTypeName} byly smazány!',
  delete_modal_close: 'Ne',
  delete_modal_yes: 'Ano',
  info_modal_header: 'Základní informace',
  crop_modal_header: 'Ořezat fotku',
  crop_modal_face_detect: 'Detekce obličeje',
  crop_modal_save: 'Uložit bod zájmu',
  crop_modal_was_set: 'Bod zájmu byl nastaven.',
  image_check_info_max_size: 'Obrázek přesahuje maximální povolenou velikost 99 MB.',
  image_check_info_low_quality: 'Fotografie má nízkou kvalitu.',
  drop_files_here: 'Drop files here',
  rotate_plus_90: 'Otočit o +90°',
  rotate_minus_90: 'Otočit o -90°',
  rotate_error: 'Chyba při otáčení.',
  download_original: 'Stáhnout originál',
  insert_into_article: 'Vložit fotky do článku',
  create_gallery_insert_into_article: 'Vytvořit galerii a vložit do článku',
  asset_exists_in_gallery: 'Fotka {title} se již v galerii nachází a nebude přidána.',
  public_info: 'Galerie bude po vypublikování článku dostupná pro celé vydavatelství NMH (do vypublikování článku je neveřejná). V případě, že se rozhodnete nezveřejnit galerii, bude dostupná pouze pro redaktory z vašeho Titulu.'
}

export default messages
