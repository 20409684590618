import { ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'topicOffer/:id',
    component: () => import('../../components/topic/TopicOffer'),
    name: 'topicOffer_detail',
    meta: {
      description: 'topicOffer detail',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'topicOffer',
    component: () => import('../../components/topic/TopicOfferList'),
    name: 'topicOffer_list',
    meta: {
      description: 'TopicOffer list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  }
]

export default routes
