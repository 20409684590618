const DEFAULT_TYPE = 'internal'

export default {
  id: null,
  source: null,
  name: '',
  originName: '',
  customText: '',
  user: null,
  url: '',
  shortcut: '',
  sex: null,
  function: '',
  type: DEFAULT_TYPE,
  typeAuthor: false,
  typeEditor: false,
  typeSportEditor: false,
  typeReaderReporter: false,
  typePhotographer: false,
  typeExternalAuthor: false,
  phone: '',
  email: '',
  showContactForm: false,
  defaultSite: null,
  siteIds: [],
  editorSiteIds: [],
  editorInChiefSiteIds: [],
  otherFunction: null,
  hiddenOnPage: false,
  disabled: false,
  image: null,
  showCompleteProfile: false,
  briefProfile: '',
  completeProfile: '',
  social: {
    twitter: '',
    linkedin: '',
    facebook: '',
    instagram: '',
    pinterest: ''
  }
}
