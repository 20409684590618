import { dateTimeNow } from '../services/DateTimeService'

export default {
  id: null,
  title: '',
  author: '',
  bodyText: '',
  publishedSince: dateTimeNow(),
  voting: {
    enabled: false,
    likes: 0,
    dislikes: 0,
    total: 0
  },
  sites: [],
  expanded: {
    sites: []
  }
}
