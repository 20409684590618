const routes = [
  {
    path: '/help',
    component: () => import('../../views/video/VideoHelpListView'),
    name: 'help_video_list',
    meta: {
      description: 'Help',
      requiresAuth: true
    }
  }
]

export default routes
