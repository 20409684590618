import { ROLE_SUPER_ADMIN, ROLE_ESHOP_ADMIN } from '../../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'feUserArticle/:id/edit',
    component: () => import('../../../components/feUser/article/FeUserArticleEdit'),
    name: 'feUserArticle_edit',
    meta: {
      description: 'FeUser Article edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_ESHOP_ADMIN
      ]
    }
  },
  {
    path: 'feUserArticle/new',
    component: () => import('../../../components/feUser/article/FeUserArticleNew'),
    name: 'feUserArticle_new',
    meta: {
      description: 'FeUser Article new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_ESHOP_ADMIN
      ]
    }
  },
  {
    path: 'feUserArticle',
    component: () => import('../../../components/feUser/article/FeUserArticleList'),
    name: 'feUserArticle_list',
    meta: {
      description: 'FeUser Article list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_ESHOP_ADMIN
      ]
    }
  }
]

export default routes
