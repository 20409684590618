import { ROLE_SUPER_ADMIN } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'site/:id/edit',
    component: () => import('../../views/site/SiteEditView'),
    name: 'site_edit',
    meta: {
      description: 'Site edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'site',
    component: () => import('../../views/site/SiteListView'),
    name: 'site_list',
    meta: {
      description: 'Site list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  }
]

export default routes
