import { ROLE_SUPER_ADMIN, ROLE_VIDEO_USER, ROLE_VIDEO_ADMIN } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'video/new',
    component: () => import('../../views/video/VideoNewView'),
    name: 'video_new',
    meta: {
      description: 'Video new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_VIDEO_USER, ROLE_VIDEO_ADMIN
      ]
    }
  },
  {
    path: 'video/:id',
    component: () => import('../../views/video/VideoView'),
    name: 'video_detail',
    meta: {
      description: 'Video detail',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_VIDEO_USER, ROLE_VIDEO_ADMIN
      ]
    }
  },
  {
    path: 'video/:id/edit',
    component: () => import('../../views/video/VideoEditView'),
    name: 'video_edit',
    meta: {
      description: 'Video edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_VIDEO_USER, ROLE_VIDEO_ADMIN
      ]
    }
  },
  {
    path: 'video/:id/annotation/new',
    component: () => import('../../views/video/VideoAnnotationNewView'),
    name: 'videoAnnotation_new',
    meta: {
      description: 'Video annotation new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_VIDEO_USER, ROLE_VIDEO_ADMIN
      ]
    }
  },
  {
    path: 'video/:id/annotation/:annotationId/edit',
    component: () => import('../../views/video/VideoAnnotationEditView'),
    name: 'videoAnnotation_edit',
    meta: {
      description: 'Video annotation edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_VIDEO_USER, ROLE_VIDEO_ADMIN
      ]
    }
  },
  {
    path: 'video',
    component: () => import('../../views/video/VideoListView'),
    name: 'video_list',
    meta: {
      description: 'Video list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_VIDEO_USER, ROLE_VIDEO_ADMIN
      ]
    }
  },
  {
    path: 'videoOld',
    component: () => import('../../views/video/VideoListView'),
    name: 'video_list_old',
    meta: {
      description: 'Video list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_VIDEO_USER, ROLE_VIDEO_ADMIN
      ]
    }
  },
  {
    path: 'videoOld/:id/annotation/:annotationId/edit',
    component: () => import('../../views/video/VideoAnnotationEditView'),
    name: 'videoAnnotation_edit_old',
    meta: {
      description: 'Video annotation edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_VIDEO_USER, ROLE_VIDEO_ADMIN
      ]
    }
  },
  {
    path: 'videoOld/new',
    component: () => import('../../views/video/old/VideoNewViewOld'),
    name: 'video_new_old',
    meta: {
      description: 'Video new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_VIDEO_USER, ROLE_VIDEO_ADMIN
      ]
    }
  },
  {
    path: 'videoOld/:id',
    component: () => import('../../views/video/old/VideoViewOld'),
    name: 'video_detail_old',
    meta: {
      description: 'Video detail',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_VIDEO_USER, ROLE_VIDEO_ADMIN
      ]
    }
  },
  {
    path: 'videoOld/:id/edit',
    component: () => import('../../views/video/old/VideoEditViewOld'),
    name: 'video_edit_old',
    meta: {
      description: 'Video edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_VIDEO_USER, ROLE_VIDEO_ADMIN
      ]
    }
  },
  {
    path: 'videoOld/:id/annotation/new',
    component: () => import('../../views/video/VideoAnnotationNewView'),
    name: 'videoAnnotation_new_old',
    meta: {
      description: 'Video annotation new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_VIDEO_USER, ROLE_VIDEO_ADMIN
      ]
    }
  }
]

export default routes
