import { ROLE_SUPER_ADMIN, ROLE_ESHOP_ADMIN } from '../../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'feUserDeliveryAddress/:id/edit',
    component: () => import('../../../components/feUser/address/FeUserDeliveryAddressEdit'),
    name: 'feUserDeliveryAddress_edit',
    meta: {
      description: 'FeUser Delivery Address edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_ESHOP_ADMIN
      ]
    }
  },
  {
    path: 'feUserDeliveryAddress/new/:feUserId?',
    component: () => import('../../../components/feUser/address/FeUserDeliveryAddressNew'),
    name: 'feUserDeliveryAddress_new',
    meta: {
      description: 'FeUser Delivery Address new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_ESHOP_ADMIN
      ]
    }
  },
  {
    path: 'feUserDeliveryAddress',
    component: () => import('../../../components/feUser/address/FeUserDeliveryAddressList'),
    name: 'feUserDeliveryAddress_list',
    meta: {
      description: 'FeUser Delivery Address list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_ESHOP_ADMIN
      ]
    }
  }
]

export default routes
