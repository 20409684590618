export default {
  id: null,
  name: '',
  title: '',
  domain: '',
  meta: {
    title: '',
    description: '',
    keywords: ''
  },
  shortTitle: '',
  enabled: 0,
  woodWingId: ''
}
