const getBlockCommonProps = (type) => {
  return {
    $type: type, // only needed for .NET core backend // MUST BE FIRST IN JSON!
    type, // mostly needed for Admin
    id: '',
    position: {
      x: 0,
      y: 0
    }
  }
}

export default {
  blocks: [
    {
      ...getBlockCommonProps('headingBlock'),
      properties: {
        headingType: 'h2',
        text: '',
        htmlId: ''
      }
    },
    {
      ...getBlockCommonProps('paragraphBlock'),
      properties: {
        text: '',
        htmlId: ''
      }
    },
    {
      ...getBlockCommonProps('unorderedListBlock'),
      properties: {
        items: [],
        htmlId: ''
      }
    },
    {
      ...getBlockCommonProps('orderedListBlock'),
      properties: {
        items: [],
        htmlId: ''
      }
    },
    {
      ...getBlockCommonProps('quoteBlock'),
      properties: {
        author: '',
        text: '',
        htmlId: ''
      }
    },
    {
      ...getBlockCommonProps('tableBlock'),
      properties: {
        tableContent: {
          html: '',
          json: {}
        },
        htmlId: ''
      }
    },
    {
      ...getBlockCommonProps('sourceBlock'),
      properties: {
        items: [], // [{ name, url }]
        htmlId: ''
      }
    },
    {
      ...getBlockCommonProps('codeBlock'),
      properties: {
        code: '',
        htmlId: ''
      }
    },
    {
      ...getBlockCommonProps('linkToGalleryBlock'),
      properties: {
        page: 1,
        text: '',
        htmlId: ''
      }
    },
    {
      ...getBlockCommonProps('infoBoxBlock'),
      properties: {
        infoBoxId: '',
        htmlId: ''
      }
    },
    {
      ...getBlockCommonProps('relatedArticleBlock'),
      properties: {
        articleId: '',
        articleUuid: '',
        htmlId: ''
      }
    },
    // TODO put themes, serials and specials here
    {
      ...getBlockCommonProps('imageBlock'),
      properties: {
        damMediaId: '',
        damMediaUuid: '',
        htmlId: ''
      }
    },
    {
      ...getBlockCommonProps('videoBlock'),
      properties: {
        videoId: '',
        videoUuid: '',
        htmlId: ''
      }
    },
    {
      ...getBlockCommonProps('quizBlock'),
      properties: {
        quizId: '',
        quizUuid: '',
        htmlId: ''
      }
    },
    {
      ...getBlockCommonProps('pollBlock'),
      properties: {
        pollId: '',
        htmlId: ''
      }
    },
    // TODO put online reports and interviews here
    {
      ...getBlockCommonProps('pageBreakBlock'),
      properties: {
        pageBreak: true,
        htmlId: ''
      }
    },
    {
      ...getBlockCommonProps('paidContentBreakBlock'),
      properties: {
        paidContentBreak: true,
        htmlId: ''
      }
    },
    {
      ...getBlockCommonProps('youtubeBlock'),
      properties: {
        youtubeLink: '',
        htmlId: ''
      }
    },
    {
      ...getBlockCommonProps('thirdPartyEmbedBlock'),
      properties: {
        type: '',
        originalContent: '',
        embedId: '',
        htmlId: ''
      }
    },
    {
      ...getBlockCommonProps('infographicBlock'),
      properties: {
        type: '', // flourish | tableau | infogram
        originalContent: '',
        infographicId: '',
        htmlId: ''
      }
    },
    {
      ...getBlockCommonProps('carTableBlock'),
      properties: {
        testIds: [],
        htmlId: ''
      }
    },
    {
      ...getBlockCommonProps('onlineSportMatchBlock'),
      properties: {
        matchId: '',
        matchType: '',
        htmlId: ''
      }
    },
    {
      ...getBlockCommonProps('sportTableBlock'),
      properties: {
        seasonId: '',
        activeTab: 1,
        htmlId: ''
      }
    }
  ]
}
