const messages = {
  id: 'ID',
  username: 'Uživatelské jméno',
  email: 'Email',
  last_login: 'Poslední přihlášení',
  yes: 'Ano',
  no: 'Ne',
  enabled: 'Uživatel aktivován',
  firstName: 'Jméno',
  lastName: 'Příjmení',
  name: 'Jméno',
  save: 'Uložit',
  created_at_from: 'Vytvořeno od',
  created_at_to: 'Vytvořeno do',
  last_login_from: 'Poslední přihlášení od',
  last_login_to: 'Poslední přihlášení do',
  defaultSite: 'Stránka',
  defaultRubric: 'Rubrika',
  department: 'Oddělení',
  settings: 'Nastavení',
  details: 'Detaily',
  authorization: 'Autorizace',
  roles: 'Role',
  userSites: 'Povolené stránky',
  resetPassword: 'Resetuj heslo',
  copyResetLink: 'Kopíruj reset linku',
  filter: {
    search: 'Hledat',
    reset_filter: 'Zrušit filtr'
  },
  error: {
    required_fields: 'Prosím, vyplňte všechna povinná pole',
    user_has_relations: 'Nelze smazat, uživatel má relace.'
  },
  message: {
    updated_record: 'Záznam byl změněn!',
    deleted_record: 'Záznam byl vymazán!',
    password_reset: 'Heslo resetováno!',
    resetLinkCopied: 'Reset linka zkopírována!'
  }
}

export default messages
