const messages = {
  list: {
    id: 'Id',
    title: 'Titulek',
    slug: 'Slug',
    site: 'Stránka',
    parent: 'Parent',
    created_at: 'Datum vytvoření',
    modified_at: 'Datum poslední změny',
    created_by: 'Vytvořil',
    absolute_url: 'Absolutní url',
    sorting: 'Seřazení subrubrik'
  },
  filter: {
    title: 'Titulek',
    id: 'Id',
    slug: 'Slug',
    site: 'Stránka',
    parent: 'Parent',
    search: 'Hledat'
  },
  id: 'Id',
  title: 'Titulek',
  slug: 'Slug',
  site: 'Stránka',
  parent: 'Rodič',
  iptcCategory: 'IPTC kategorie',
  meta_title: 'Meta titulek',
  meta_description: 'Meta popis',
  meta_keywords: 'Meta klíčová slova',
  setting_advertCategory: 'ITM area parametr (advertCategory)',
  setting_chartbeatSection: 'Chartbeat section',
  setting_gemiusId: 'Gemius Id',
  setting_prRubric: 'PR rubric',
  setting_special: 'Special rubric',
  setting_advertSection: 'Unit section for Google ads (advertSection)',
  sportTable_seasonId: 'Sport table Season Id',
  sportTable_activeTab: 'Sport table Active Tab (first = 1)',
  rubric_without_articles: 'Rubric without articles (disable possibility to set this rubric to articles, used for custom listing rubrics)',
  hiddenInMenu: 'Hidden in autogenerated menu',
  sorting: 'Seřazení subrubrik',
  product: 'Produkt'
}

export default messages
