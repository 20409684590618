import { ROLE_EDITOR, ROLE_JOURNALIST, ROLE_SUPER_ADMIN } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'likeDislike/new',
    component: () => import('../../views/poll/PollLikeDislikeNewView'),
    name: 'likeDislike_new',
    meta: {
      description: 'Like dislike new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'likeDislike/:id/edit',
    component: () => import('../../views/poll/PollLikeDislikeEditView'),
    name: 'likeDislike_edit',
    meta: {
      description: 'Like dislike edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'likeDislike/:id',
    component: () => import('../../views/poll/PollLikeDislikeView'),
    name: 'likeDislike_detail',
    meta: {
      description: 'Like dislike detail',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'likeDislike',
    component: () => import('../../views/poll/PollLikeDislikeListView'),
    name: 'likeDislike_list',
    meta: {
      description: 'Like dislike list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  }
]

export default routes
