import { ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'kpi-nmh',
    component: () => import('../../components/kpiNmh/KpiNmh'),
    name: 'kpi_nmh',
    meta: {
      description: 'NMH KPI',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN,
        ROLE_JOURNALIST,
        ROLE_EDITOR
      ]
    }
  },
  {
    path: 'kpi-nmh-old',
    component: () => import('../../components/kpiNmh/KpiNmhOld'),
    name: 'kpi_nmh_old',
    meta: {
      description: 'NMH KPI old',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN,
        ROLE_JOURNALIST,
        ROLE_EDITOR
      ]
    }
  },
  {
    path: 'kpi-nmh-settings',
    component: () => import('../../components/kpiNmh/KpiNmhSettings'),
    name: 'kpi_nmh_settings',
    meta: {
      description: 'NMH KPI SETTINGS',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  }
]

export default routes
