import axios from 'axios'
import appConfig from '../config'
import VueCookies from 'vue-cookies'

const sport24Api = function () {
  const _client = axios.create({
    baseURL: appConfig.sport24.apiUrl(),
    timeout: appConfig.sport24.apiTimeout * 1000
  })

  /*
   * Axios Request Interceptor.
   * Before each request, use the values in local storage to set new headers.
   */
  _client.interceptors.request.use(
    config => {
      if (config.baseURL === appConfig.sport24.apiUrl()) {
        config.headers.Authorization = VueCookies.get('access_token')
      }
      return config
    },
    error => Promise.reject(error)
  )

  return _client
}

export default sport24Api
