import { ROLE_SUPER_ADMIN, ROLE_DEPARTMENT_MANAGER } from '../../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'beUserKpi/:id/edit',
    component: () => import('../../../views/beUser/BeUserKpiEditView'),
    name: 'beUserKpi_edit',
    meta: {
      description: 'BeUser KPI edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN,
        ROLE_DEPARTMENT_MANAGER
      ]
    }
  },
  {
    path: 'beUserKpi_list',
    component: () => import('../../../views/beUser/BeUserKpiListView'),
    name: 'beUserKpi_list',
    meta: {
      description: 'BeUser KPI list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN,
        ROLE_DEPARTMENT_MANAGER
      ]
    }
  }
]

export default routes
