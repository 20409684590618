import moment from 'moment'

const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.userId) {
    filterQuery += '&filter_equals[userId]=' + filter.userId
  }
  if (filter.username) {
    filterQuery += '&filter_regex[username]=' + filter.username
  }
  if (filter.responseStatus) {
    filterQuery += '&filter_equals[responseStatus]=' + filter.responseStatus
  }
  if (filter.clientIp) {
    filterQuery += '&filter_regex[clientIp]=' + filter.clientIp
  }
  if (filter.createdAtFrom) {
    const date = moment(filter.createdAtFrom).add(-1, 'hours').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filterQuery += '&filter_gte[createdAt]=' + date
  }
  if (filter.createdAtTo) {
    const date = moment(filter.createdAtTo).add(23, 'hours').add(-1, 's').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filterQuery += '&filter_lte[createdAt]=' + date
  }
  return filterQuery
}

const buildPaginationQuery = function (state) {
  let paginationQuery = ''
  if (state.pagination.lastCreatedAt !== '') {
    paginationQuery = paginationQuery + '&pagination_next[createdAt]=' + state.pagination.lastCreatedAt
  }
  if (state.pagination.firstCreatedAt !== '') {
    paginationQuery = paginationQuery + '&pagination_prev[createdAt]=' + state.pagination.firstCreatedAt
  }
  return paginationQuery
}

export default {
  buildFilterQuery,
  buildPaginationQuery
}
