import { ROLE_SUPER_ADMIN, ROLE_DEPARTMENT_MANAGER, ROLE_EDITORIAL_PERF_ADMIN } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'kpi_comparison',
    component: () => import('../../components/kpi/KpiComparison'),
    name: 'kpi_comparison',
    meta: {
      description: 'KPI comparison',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN,
        ROLE_DEPARTMENT_MANAGER,
        ROLE_EDITORIAL_PERF_ADMIN
      ]
    }
  },
  {
    path: 'performance_product',
    component: () => import('../../components/kpi/ProductPerformance'),
    name: 'performance_product',
    meta: {
      description: 'Product performance',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN,
        ROLE_DEPARTMENT_MANAGER,
        ROLE_EDITORIAL_PERF_ADMIN
      ]
    }
  },
  {
    path: 'performance_department',
    component: () => import('../../components/kpi/DepartmentPerformance'),
    name: 'performance_department',
    meta: {
      description: 'Department performance',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN,
        ROLE_DEPARTMENT_MANAGER,
        ROLE_EDITORIAL_PERF_ADMIN
      ]
    }
  },
  {
    path: 'historical_performance',
    component: () => import('../../components/kpi/HistoricalPerformance'),
    name: 'historical_performance',
    meta: {
      description: 'Historical performance',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN,
        ROLE_DEPARTMENT_MANAGER,
        ROLE_EDITORIAL_PERF_ADMIN
      ]
    }
  },
  {
    path: 'performance_weights',
    component: () => import('../../components/kpi/WeightsSettings'),
    name: 'performance_weights',
    meta: {
      description: 'Performance weights settings',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN,
        ROLE_DEPARTMENT_MANAGER,
        ROLE_EDITORIAL_PERF_ADMIN
      ]
    }
  },
  {
    path: 'performance_kpi_settings',
    component: () => import('../../components/kpi/KpiSettings'),
    name: 'performance_kpi_settings',
    meta: {
      description: 'Performance KPI settings',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN,
        ROLE_DEPARTMENT_MANAGER,
        ROLE_EDITORIAL_PERF_ADMIN
      ]
    }
  }
]

export default routes
