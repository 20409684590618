<template>
  <div class="login">
    <div class="login__dialog-wr">
      <LoginLogo />
      <form class="login__form" id="loginform" @submit.prevent="checkCreds">
        <h2 class="login__sub-heading">{{ $t('login.sign_in') }}</h2>
        <input
          class="login__form-input"
          v-model="username"
          id="username"
          type="text"
          required=""
          :placeholder="$t('login.username')"
        >
        <input
          class="login__form-input"
          v-model="password"
          id="password"
          type="password"
          required=""
          :placeholder="$t('login.password')"
        >
        <button
          class="login__form-submit"
          type="submit"
          data-test="login_btn"
        >
          {{ $t('login.log_in') }}
        </button>
        <a
          class="login__forgot-password"
          :href="resetPasswordLink"
          target="_blank"
          data-test="forgot_password_link"
        >
          <i class="login__forgot-password--icon fa fa-lock"></i>
          {{ $t('login.forgot_password') }}
        </a>
      </form>
      <Preloader v-if="loading" />
      <p v-if="errorResponse" class="login__error">
        {{ errorResponse }}
      </p>
      <p class="login__version">
        {{ $t('login.version') }}: {{ version }}
      </p>
    </div>
  </div>
</template>

<script>
import appConfig from '@/config'
import { mapGetters, mapState } from 'vuex'
import Preloader from '@/components/preloader/Preloader'
import LoginLogo from '@/components/user/LoginLogo'

export default {
  name: 'Login',
  components: {
    Preloader,
    LoginLogo
  },
  data () {
    return {
      section: 'Login',
      loading: '',
      username: '',
      password: '',
      errorResponse: '',
      version: appConfig.appVersion(),
      resetPasswordLink: `${appConfig.core.passwordResetUrl()}/request`
    }
  },
  computed: {
    ...mapState({
      user: state => state.user
    }),
    ...mapGetters(['vlm'])
  },
  methods: {
    async checkCreds () {
      this.toggleLoading()
      this.resetResponse()
      this.$store.commit('TOGGLE_LOADING')
      const formData = {
        username: this.username,
        password: this.password
      }
      await this.$store.dispatch('user/login', formData)
        .then(response => {
          this.toggleLoading()
          if (this.$store.getters['user/isAuthenticated']) {
            // Redirect if current user is fetched
            this.$store.dispatch('user/fetchAndStoreCurrentUser')
              .then(() => {
                const roles = this.user.currentUser.roles
                if (this.$route.query.redirect && this.$route.query.redirect !== '/') {
                  this.$router.push(this.$route.query.redirect)
                } else if (roles.includes('ROLE_SUPER_ADMIN') || roles.includes('ROLE_JOURNALIST') ||
                  roles.includes('ROLE_EDITOR')
                ) {
                  this.$router.push('/article')
                } else if (roles.includes('ROLE_DAM_ADMIN') || roles.includes('ROLE_DAM_USER')) {
                  this.$router.push('/eagle-dam/image')
                } else if (roles.includes('ROLE_DAM_PDF_ADMIN') || roles.includes('ROLE_DAM_PDF_USER')) {
                  this.$router.push('/eagle-dam/pdf')
                } else if (roles.includes('ROLE_ESHOP_ADMIN')) {
                  this.$router.push('/feUser')
                } else if (roles.includes('ROLE_ANALYST')) {
                  this.$router.push('/historicalDashboard')
                } else if (roles.includes('ROLE_PRINT_PUBLICATION')) {
                  this.$router.push('/printPublication')
                } else {
                  this.$router.push('/welcome')
                }
                this.$store.dispatch('department/fetchAll')
                this.$store.commit('TOGGLE_LOADING')
              })
          } else {
            this.$store.commit('TOGGLE_LOADING')
            this.errorResponse = this.$t('login.error_message')
          }
        })
        .catch((error) => {
          this.$store.commit('TOGGLE_LOADING')
          this.errorResponse = error.response
        })
    },
    toggleLoading () {
      this.loading = this.loading === '' ? 'loading' : ''
    },
    resetResponse () {
      this.errorResponse = ''
    }
  }
}
</script>

<style scoped lang="scss">
  .login {
    @include size(100% 100vh);
    @include fixed(0, 100);
    @include size(100% 100vh);
    min-height: 100vh;
    overflow-y: scroll;
    background: #f3f4f8;
    display: flex;
    justify-content: center;
    @include bp(7) {
      align-items: center;
    }
    &__dialog-wr {
      @include padding(_ 15px);
      @include size(500px _);
      @include bp(0 7) {
        @include padding(50px _ _);
      }
    }
    &__sub-heading {
      @include font(400 30px "Roboto");
      @include margin(_ _ 30px);
      text-align: center;
      color: #465674;
    }
    &__form {
      @include margin(_ _ 50px);
      @include padding(30px);
      @include radius(7px);
      @include grid-gap(25px);
      box-shadow: 4px 4px 20px 0 rgba(71, 134, 255, 0.1);
      background: #fff;
      display: grid;
      @include bp(7) {
        @include padding(60px);
      }
    }
    &__form-input {
      @include font(400 16px "Roboto");
      @include padding(_ _ 5px _);
      color: rgba(0,0,0, .8);
      border: none;
      border-bottom: 1px solid #465674;
      &::placeholder {
        color: rgba(0,0,0, .4);
      }
    }
    &__form-submit {
      @include font(500 20px "Roboto");
      @include padding(10px _);
      @include radius(4px);
      color: #fff;
      background: #6599fe;
      border: none;
      cursor: pointer;
      transition: background 150ms;
      &:hover {
        background: darken(#6599fe, 10%);
      }
    }
    &__forgot-password {
      @include font(500 15px "Roboto");
      color: #6599fe;
      text-align: center;
      &--icon {
        @include margin(_ 5px _ _);
      }
    }
    &__error {
      @include font(500 16px "Roboto");
      @include margin(20px 0 0 0);
      color: #f74a21;
      text-align: center;
    }
    &__version {
      @include font(500 15px "Roboto");
      color: #b8c0cd;
      text-align: center;
    }
  }
</style>
