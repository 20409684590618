export default {
  headline: '',
  description: '',
  byLine: '',
  keywords: '',
  personInImage: '',
  id: '',
  dateFrom: '',
  dateTo: '',
  dateTimeOriginalFrom: '',
  dateTimeOriginalTo: '',
  fulltext: '',
  uploadSource: '',
  size_min: '',
  resolution: '',
  orientation: '',
  createdBy: '',
  restrictedSites: [],
  availableSites: []
}
