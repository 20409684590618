const routes = [
  {
    path: 'oneSignal/new',
    component: () => import('../../views/oneSignal/OneSignalNewView'),
    name: 'oneSignal_new',
    meta: {
      description: 'OneSignal new',
      requiresAuth: true
    }
  },
  {
    path: 'oneSignal/:id/edit',
    component: () => import('../../views/oneSignal/OneSignalEditView'),
    name: 'oneSignal_edit',
    meta: {
      description: 'OneSignal edit',
      requiresAuth: true
    }
  },
  {
    path: 'oneSignal/:id',
    component: () => import('../../views/oneSignal/OneSignalView'),
    name: 'oneSignal_detail',
    meta: {
      description: 'OneSignal detail',
      requiresAuth: true
    }
  },
  {
    path: 'oneSignal',
    component: () => import('../../views/oneSignal/OneSignalListView'),
    name: 'oneSignal_list',
    meta: {
      description: 'OneSignal list',
      requiresAuth: true
    }
  }
]

export default routes
