import { dateTimeNow } from '../../services/DateTimeService'

export default {
  id: null,
  username: '',
  email: '',
  enabled: true,
  firstName: '',
  lastName: '',
  defaultSite: null,
  defaultRubric: null,
  lastLogin: null,
  salt: '',
  plainPassword: '',
  roles: [],
  confirmationToken: '',
  kpi: {
    articleCnt: 0,
    articleEditCnt: 0,
    imagesCnt: 0,
    photostoryCnt: 0,
    quizCnt: 0,
    videoCnt: 0,
    videoViewCnt: 0,
    videosCnt: 0,
    viewCnt: 0
  },
  createdAt: dateTimeNow(),
  modifiedAt: dateTimeNow(),
  createdBy: 0,
  modifiedBy: 0
}
