import { ROLE_SUPER_ADMIN, ROLE_ESHOP_ADMIN } from '../../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'shop/subscription/payment/:id',
    component: () => import('../../../components/eshop/SubscriptionPayment'),
    name: 'eshopSubscriptionPayment_detail',
    meta: {
      description: 'Subscription Payment detail',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_ESHOP_ADMIN
      ]
    }
  },
  {
    path: 'shop/subscription/payment',
    component: () => import('../../../components/eshop/SubscriptionPaymentList'),
    name: 'eshopSubscriptionPayment_list',
    meta: {
      description: 'Subscription Payment list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_ESHOP_ADMIN
      ]
    }
  }
]

export default routes
