export default {
  username: '',
  firstName: '',
  lastName: '',
  email: '',
  enabled: 'none',
  department: null,
  managedDepartments: [],
  createdAtFrom: '',
  createdAtTo: '',
  lastLoginFrom: '',
  lastLoginTo: '',
  id: ''
}
