import { ROLE_SUPER_ADMIN } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'app_performance',
    component: () => import('../../views/app/AppPerformanceView'),
    name: 'app_performance',
    meta: {
      description: 'AppPerformance',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  }
]

export default routes
