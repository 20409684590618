import { ROLE_SUPER_ADMIN, ROLE_VIDEO_ADMIN } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'videoCategory/:id/edit',
    component: () => import('../../views/video/VideoCategoryEditView'),
    name: 'videoCategory_edit',
    meta: {
      description: 'Video category edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_VIDEO_ADMIN
      ]
    }
  },
  {
    path: 'videoCategory/new',
    component: () => import('../../views/video/VideoCategoryNewView'),
    name: 'videoCategory_new',
    meta: {
      description: 'Video category new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_VIDEO_ADMIN
      ]
    }
  },
  {
    path: 'videoCategory',
    component: () => import('../../views/video/VideoCategoryListView'),
    name: 'videoCategory_list',
    meta: {
      description: 'Video category list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_VIDEO_ADMIN
      ]
    }
  }
]

export default routes
