const messages = {
  list: {
    id: 'Id',
    title: 'Titulek',
    short_title: 'Krátký popis',
    name: 'Název',
    enabled: 'Povoleno',
    created_at: 'Datum vytvoření',
    modified_at: 'Datum poslední změny',
    created_by: 'Vytvořil'
  },
  filter: {
    title: 'Titulek',
    name: 'Název',
    id: 'Id',
    search: 'Hledat'
  },
  id: 'Id',
  title: 'Titulek',
  name: 'Název',
  enabled: 'Povoleno',
  production: 'Produkční provoz',
  enabled_sites: 'Seznam povolených webů',
  shortTitle: 'Krátký popis',
  meta_title: 'Meta název',
  meta_description: 'Meta popis',
  meta_keywords: 'Meta klíčová slova'
}

export default messages
