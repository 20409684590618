export const VIDEO_TYPE_OOYALA = 'ooyala'
export const VIDEO_TYPE_YOUTUBE = 'youtube'
export const VIDEO_TYPE_LIVEBOX = 'livebox'

export default {
  id: null,
  type: VIDEO_TYPE_LIVEBOX,
  ooyalaId: '',
  brightcoveId: 0,
  liveboxAssetId: '',
  fileName: '',
  fileSize: '',
  status: 'new',
  statusMessage: '',
  videoDuration: 0,
  videoWidth: 0,
  videoHeight: 0,
  defaultSite: null,
  setting: {
    editorialContent: false,
    agencyContent: false,
    marketingContent: false,
    nativeContent: false,
    internal: false,
    social: false,
    agency: false,
    camera: false,
    mobilePhone: false,
    slideshow: false,
    show: false,
    forAdults: false,
    productPlacement: false,
    exclusive: false,
    noAds: false,
    dynamicPreview: true,
    dynamicPreviewStart: 10,
    dynamicPreviewDuration: 6,
    episodeNumber: 1
  },
  migrationSetting: {
    migrated: false
  },
  meta: {
    title: '',
    description: '',
    keywords: '',
    author: '',
    camera: '',
    editing: '',
    gdprSource: ''
  },
  previewImage: null,
  annotations: [],
  authorUsers: [],
  cameraUsers: [],
  editingUsers: [],
  videoShows: [],
  safetyTopics: [],
  categories: [],
  articles: [],
  expanded: {
    defaultSite: null,
    videoShows: [],
    videoCategories: [],
    authorUsers: [],
    cameraUsers: [],
    editingUsers: [],
    safetyTopics: [],
    categories: [],
    previewImage: null
  }
}
