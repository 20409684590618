import { ROLE_SUPER_ADMIN, ROLE_ESHOP_ADMIN } from '../../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'feUser/:id/edit',
    component: () => import('../../../components/feUser/FeUserEdit'),
    name: 'feUser_edit',
    meta: {
      description: 'FeUser edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_ESHOP_ADMIN
      ]
    }
  },
  {
    path: 'feUser/new',
    component: () => import('../../../components/feUser/FeUserNew'),
    name: 'feUser_new',
    meta: {
      description: 'FeUser new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_ESHOP_ADMIN
      ]
    }
  },
  {
    path: 'feUser',
    component: () => import('../../../components/feUser/FeUserList'),
    name: 'feUser_list',
    meta: {
      description: 'FeUser list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_ESHOP_ADMIN
      ]
    }
  }
]

export default routes
