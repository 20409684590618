import { ROLE_EDITOR, ROLE_JOURNALIST, ROLE_SUPER_ADMIN } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'recipe/new',
    component: () => import('../../views/recipe/RecipeNewView'),
    name: 'recipe_new',
    meta: {
      description: 'Recipe new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'recipe/:id/edit',
    component: () => import('../../views/recipe/RecipeEditView'),
    name: 'recipe_edit',
    meta: {
      description: 'Recipe edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'recipe/:id',
    component: () => import('../../views/recipe/RecipeView'),
    name: 'recipe_detail',
    meta: {
      description: 'Recipe detail',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'recipe',
    component: () => import('../../views/recipe/RecipeListView'),
    name: 'recipe_list',
    meta: {
      description: 'Recipe list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'recipeCategory/:id/edit',
    component: () => import('../../views/recipe/RecipeCategoryEditView'),
    name: 'recipe_category_edit',
    meta: {
      description: 'Recipe category edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'recipeCategory/new',
    component: () => import('../../views/recipe/RecipeCategoryNewView'),
    name: 'recipe_category_new',
    meta: {
      description: 'Recipe category new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'recipeCategory',
    component: () => import('../../views/recipe/RecipeCategoryListView'),
    name: 'recipe_category_list',
    meta: {
      description: 'Recipe category list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  }
]

export default routes
