import { ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'joke/new',
    component: () => import('../../views/joke/JokeNewView'),
    name: 'joke_new',
    meta: {
      description: 'Joke new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'joke/:id/edit',
    component: () => import('../../views/joke/JokeEditView'),
    name: 'joke_edit',
    meta: {
      description: 'Joke edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'joke/:id',
    component: () => import('../../views/joke/JokeView'),
    name: 'joke_detail',
    meta: {
      description: 'Joke detail',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'joke',
    component: () => import('../../views/joke/JokeListView'),
    name: 'joke_list',
    meta: {
      description: 'Joke list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  }
]

export default routes
