import { ROLE_EDITOR, ROLE_JOURNALIST, ROLE_SUPER_ADMIN } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'poll/new',
    component: () => import('../../views/poll/PollNewView'),
    name: 'poll_new',
    meta: {
      description: 'Poll new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'poll/:id/edit',
    component: () => import('../../views/poll/PollEditView'),
    name: 'poll_edit',
    meta: {
      description: 'Poll edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'poll/:id',
    component: () => import('../../views/poll/PollView'),
    name: 'poll_detail',
    meta: {
      description: 'Poll detail',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'poll',
    component: () => import('../../views/poll/PollListView'),
    name: 'poll_list',
    meta: {
      description: 'Poll list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'pollManagement',
    component: () => import('../../views/poll/PollManagementView'),
    name: 'poll_management',
    meta: {
      description: 'Poll management',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_EDITOR
      ]
    }
  }
]

export default routes
