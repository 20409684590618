import { ROLE_SUPER_ADMIN, ROLE_PRINT_ASSISTANT } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'printArticle/:id/edit',
    component: () => import('../../components/print/PrintArticleEdit'),
    name: 'printArticle_edit',
    meta: {
      description: 'Print Article edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_PRINT_ASSISTANT
      ]
    }
  },
  {
    path: 'printArticle/new',
    component: () => import('../../components/print/PrintArticleNew'),
    name: 'printArticle_new',
    meta: {
      description: 'Print Article new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_PRINT_ASSISTANT
      ]
    }
  },
  {
    path: 'printArticle',
    component: () => import('../../components/print/PrintArticleList'),
    name: 'printArticle_list',
    meta: {
      description: 'Print Article',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_PRINT_ASSISTANT
      ]
    }
  }
]

export default routes
