import _ from 'lodash'
import DamApi from '@/api/dam'
import DamFilter from '@/model/DamFilter'
import DamFilterService from '@/services/dam/DamFilterService'
import DamService from '@/services/dam/DamService'
import DamPdfService from '@/services/dam/DamPdfService'
import config from '@/config'
import NotifyService from '@/services/NotifyService'

const state = {
  error: null,
  detail: {},
  list: [],
  prevPage: { page: 0, list: [] },
  currentPage: { page: 1, list: [] },
  nextPage: { page: 2, list: [] },
  totalCount: 0,
  page: 1,
  filter: _.cloneDeep(DamFilter),
  limit: config.defaults.list.limit,
  maxCreatedAt: '',
  cropPositionEmbed: {
    upperLeftCoords: {
      xCoord: 0,
      yCoord: 0
    },
    lowerRightCoords: {
      xCoord: 0,
      yCoord: 0
    }
  },
  restrictedSites: '',
  isPdf: false
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.data
  },
  storePageList (state, { pageKey, page, list }) {
    state[pageKey] = { page, list }
  },
  pageShiftLeft (state) {
    state.nextPage = { page: state.currentPage.page, list: state.currentPage.list }
    state.currentPage = { page: state.prevPage.page, list: state.prevPage.list }
  },
  pageShiftRight (state) {
    state.prevPage = { page: state.currentPage.page, list: state.currentPage.list }
    state.currentPage = { page: state.nextPage.page, list: state.nextPage.list }
  },
  addToList (state, responseData) {
    state.list = state.list.concat(responseData.data)
  },
  storeTotalCount (state, responseData) {
    state.totalCount = responseData.totalCount
  },
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  setPage (state, page) {
    state.page = page
  },
  setLimit (state, limit) {
    state.limit = limit
  },
  setMaxCreatedAt (state, maxCreatedAt) {
    state.maxCreatedAt = maxCreatedAt
  },
  incrementPage (state) {
    state.page++
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
    state.page = 1
  },
  setCropPositionEmbed (state, cropPositionEmbed) {
    state.cropPositionEmbed = cropPositionEmbed
  },
  setIsPdf (state, isPdf) {
    state.isPdf = isPdf
  }
}

const actions = {
  async fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    await DamApi().get(DamFilterService.getQuery(store.state))
      .then(res => {
        if (store.state.maxCreatedAt === '') {
          store.commit('storeList', res.data)
          store.commit('storeTotalCount', res.data)
        } else {
          store.commit('addToList', res.data)
        }
      }).catch(error => console.error(error))
      .finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
  },
  async fetchCurrentPage (store) {
    await DamApi().get(DamFilterService.getQuery(store.state))
      .then(result => {
        store.commit('storePageList', { pageKey: 'currentPage', page: store.state.page, list: result.data.data })
        store.commit('storeTotalCount', result.data)
      })
      .catch(error => {
        console.error(error)
        store.commit('storePageList', { pageKey: 'currentPage', page: store.state.page, list: [] })
        if (error.response.status === 500) {
          NotifyService.setErrorMessage(error.response.status)
        } else {
          NotifyService.setErrorMessage(error.response.data.error)
        }
      })
  },
  async fetchPrevPage (store) {
    if (store.state.page > 1) {
      await DamApi().get(DamFilterService.getQuery({ ...store.state, page: store.state.page - 1 }))
        .then(prevResult => {
          store.commit('storePageList', { pageKey: 'prevPage', page: store.state.page - 1, list: prevResult.data.data })
        })
        .catch(error => console.error(error))
    }
  },
  async fetchNextPage (store) {
    if (store.state.page < store.getters.pageCount) {
      await DamApi().get(DamFilterService.getQuery({ ...store.state, page: store.state.page + 1 }))
        .then(nextResult => {
          store.commit('storePageList', { pageKey: 'nextPage', page: store.state.page + 1, list: nextResult.data.data })
        })
        .catch(error => console.error(error))
    }
  },
  async fetchPage (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const shiftLeft = store.state.page === store.state.currentPage.page - 1
    const shiftRight = store.state.page === store.state.currentPage.page + 1
    if (shiftLeft) {
      store.commit('pageShiftLeft')
    } else if (shiftRight) {
      store.commit('pageShiftRight')
    }
    await store.dispatch('fetchCurrentPage')
    store.commit('TOGGLE_LOADING', null, { root: true })
    if (!shiftRight) {
      store.dispatch('fetchPrevPage')
    }
    if (!shiftLeft) {
      store.dispatch('fetchNextPage')
    }
  },
  async fetchOne (store, id) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    await DamApi().get('/asset/' + id)
      .then(response => {
        store.commit('storeDetail', response.data)
      }).catch(error => console.error(error))
      .finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
  },
  async create (store, record) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const url = store.state.isPdf ? '/pdf' : '/image'
    return await DamApi().post(url, JSON.stringify(record))
      .then(response => {
        if (response.status === 201) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
      .finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
  },
  async deleteRecord (store, record) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    return await DamApi().delete('/asset/' + record)
      .then(response => {
        if (response.status === 204) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
      .finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
  },
  async deleteRecords (store, assets) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    return await DamApi().delete('/assets', { data: JSON.stringify(assets) })
      .then(response => {
        if (response.status === 204) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
      .finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
  },
  async updateMetadata (store, record) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const url = `/metadata/${record.id}${store.state.isPdf ? '/pdf' : ''}`
    return await DamApi().put(url, JSON.stringify(record.metadata))
      .then(response => {
        if (response.status === 200) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
      .finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
  },
  async download (store, asset) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    return await DamApi(0).get('/asset/download/' + asset.id, { responseType: 'blob' })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        let filename = asset.basename
        const damService = store.state.isPdf ? DamPdfService : DamService
        const fileExtension = damService.getFileExtensionByMimeType(asset.mimeType)
        if (fileExtension) {
          filename = asset.filename + '.' + fileExtension
        }
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
  },
  async downloadZip (store, assets) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    return await DamApi(0).post('/asset/download/zip', JSON.stringify(assets), { responseType: 'blob' })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        const filename = 'dam_download_' + new Date().getTime() + '.zip'
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
  },
  saveCrop (store, record) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    return DamApi().post('/crop/' + record.assetId, JSON.stringify(record.cropPositionEmbed))
      .then(response => {
        if (response.status === 200) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
      .finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
  },
  async getCrop (store, id) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    await DamApi().get('/crop/' + id)
      .then(response => {
        store.commit('setCropPositionEmbed', response.data)
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => store.commit('TOGGLE_LOADING', null, { root: true }))
  }
}

const getters = {
  detail (state) {
    return state.detail
  },
  list (state) {
    return state.list
  },
  prevPage (state) {
    return state.prevPage.list
  },
  currentPage (state) {
    return state.currentPage.list
  },
  nextPage (state) {
    return state.nextPage.list
  },
  totalCount (state) {
    return state.totalCount
  },
  page (state) {
    return state.page
  },
  limit (state) {
    return state.limit
  },
  pageCount (state) {
    return Math.ceil(state.totalCount / state.limit)
  },
  maxCreatedAt (state) {
    return state.maxCreatedAt
  },
  error (state) {
    return state.error
  },
  filter (state) {
    return state.filter
  },
  cropPositionEmbed (state) {
    return state.cropPositionEmbed
  },
  isPdf (state) {
    return state.isPdf
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
