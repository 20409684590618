import { ROLE_SUPER_ADMIN } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'redirect/:id/edit',
    component: () => import('../../views/redirect/RedirectEditView'),
    name: 'redirect_edit',
    meta: {
      description: 'Redirect edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'redirect/new',
    component: () => import('../../views/redirect/RedirectNewView'),
    name: 'redirect_new',
    meta: {
      description: 'Redirect new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'redirect',
    component: () => import('../../views/redirect/RedirectListView'),
    name: 'redirect_list',
    meta: {
      description: 'Redirect list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  }
]

export default routes
