<template>
  <div v-if="canCreateArticles">
    <button
      class="dash--new-article-btn"
      type="button"
      @click="showCreateModal"
    >
      <SvgIconPenToSquare />
      <span>{{ $t('buttons.new_article') }}</span>
    </button>
    <ArticleCreateModal
      v-if="createModal"
      :articleSourceType="NEW_ARTICLE"
      @close="closeCreateModal"
    />
  </div>
</template>

<script>
import SvgIconPenToSquare from '@/assets/img/svg/pen-to-square.svg?inline'
import ArticleCreateModal from '@/components/article/ArticleCreateModal'
import { ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR } from '@/model/ValueObject/UserRole'
import { NEW_ARTICLE } from '@/model/ValueObject/ArticleCreateTypes'
import { mapGetters } from 'vuex'

export default {
  name: 'DashHeaderNewArticleBtn',
  components: {
    SvgIconPenToSquare,
    ArticleCreateModal
  },
  data () {
    return {
      NEW_ARTICLE,
      createModal: false,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  computed: {
    ...mapGetters('user', ['currentUser']),
    canCreateArticles () {
      return this.currentUser.roles.some(role =>
        this.requiredRoles.includes(role))
    }
  },
  methods: {
    showCreateModal () {
      this.createModal = true
    },
    closeCreateModal () {
      this.createModal = false
    }
  }
}
</script>

<style lang="scss" scoped>

.dash--new-article-btn {
    padding-left: rem(15px);
    padding-right: rem(15px);
    margin-right: rem(15px);
    font-family: "Roboto", sans-serif;
    font-size: rem(13px);
    font-weight: 500;
    color: #6599FE;
    background: transparent;
    border: none;
    border-right: 1px solid #d1dbe4;
    cursor: pointer;
    height: rem(40px);
    transition: background 100ms;
    display: flex;
    align-items: center;
    span {
      margin-left: rem(5px);
      transform: translateY(1px);
    }
    svg {
      fill: #6599FE;
    }

    @include bp(0 7) {
      span {
        display: none;
      }
    }
}

</style>
