import { prettyDateTime } from '@/filters'
import NotifyService from '@/services/NotifyService'
import Store from '@/store'

const whiteListEndpoints = [
  'woodWing/existsArticle',
  'activity',
  'articleLog' // errors for 'articleLog' are handled by ArticleEditView.vue component
]

export const isOfflineError = (error) => {
  return (!error.response &&
    (
      !window.navigator.onLine ||
      error.message === 'Network Error' ||
      (error.message.includes('timeout') && error.message.includes('exceeded'))
    ))
}

export const notifyServiceSetGeneralErrorMsg = (error, { includingWhiteList } = { includingWhiteList: false }) => {
  if (isOfflineError(error)) {
    // no pop-up error message on offline state here - DashHeader.vue has 'offline-state' info
    //   otherwise the user could get spammed with pop-up error messages from repetitive requests e.g. '/activity' etc.
    Store.commit('SET_OFFLINE', true)
    return
  }
  const method = error.response?.config?.method?.toUpperCase()
  const baseUrl = error.response?.config?.baseURL
  const configUrl = error.response?.config?.url
  let apiUrl = `${baseUrl}${configUrl}`
  if (baseUrl && configUrl) {
    if (baseUrl[baseUrl.length - 1] !== '/' && configUrl[0] !== '/') {
      apiUrl = `${baseUrl}/${configUrl}`
    }
  }
  const statusCode = error.response?.status
  let errorMessage = error.response?.data?.error
  errorMessage = errorMessage && typeof errorMessage === 'object' ? JSON.stringify(errorMessage, null, 2) : errorMessage
  if (!errorMessage) {
    errorMessage = JSON.stringify(error, null, 2)
  }

  if (!includingWhiteList && configUrl && whiteListEndpoints.some(endpoint => configUrl.includes(endpoint))) {
    return
  }

  const timestamp = new Date().getTime()
  const prettyDate = prettyDateTime(timestamp)
  const currentUser = Store.getters['user/currentUser']
  const user = `id: ${currentUser?.id}; username: ${currentUser?.username}; email: ${currentUser?.email}`

  return NotifyService.setApiErrorMessage(
    `
      Browser URL: ${window?.location?.href} <br>
      User: ${user} <br>
      API Endpoint: ${method} ${apiUrl} <br>
      Date: ${prettyDate} <br>
      Error Code: ${statusCode} <br>
      Error Message: ${errorMessage}
    `
  )
}
