import UserLoginAttemptList from '../../components/tracking/UserLoginAttemptList'
import { ROLE_SUPER_ADMIN } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'userLoginAttempt',
    component: UserLoginAttemptList,
    name: 'userLoginAttempt_list',
    meta: {
      description: 'User Login Attempt list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  }
]

export default routes
