import { ROLE_SUPER_ADMIN, ROLE_ESHOP_ADMIN } from '../../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'shop/subscription/product/:id/edit',
    component: () => import('../../../components/eshop/SubscriptionProductEdit'),
    name: 'eshopSubscriptionProduct_edit',
    meta: {
      description: 'Subscription Product edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_ESHOP_ADMIN
      ]
    }
  },
  {
    path: 'shop/subscription/product/new',
    component: () => import('../../../components/eshop/SubscriptionProductNew'),
    name: 'eshopSubscriptionProduct_new',
    meta: {
      description: 'Subscription Product new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_ESHOP_ADMIN
      ]
    }
  },
  {
    path: 'shop/subscription/product',
    component: () => import('../../../components/eshop/SubscriptionProductList'),
    name: 'eshopSubscriptionProduct_list',
    meta: {
      description: 'Subscription Product list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_ESHOP_ADMIN
      ]
    }
  }
]

export default routes
