import { ROLE_SUPER_ADMIN } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'articleHistory',
    component: () => import('../../views/article/ArticleHistoryListView'),
    name: 'articleHistory_list',
    meta: {
      description: 'Article history list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  }
]

export default routes
