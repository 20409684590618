import { ROLE_SUPER_ADMIN } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'tasrNewsCategory/:id/edit',
    component: () => import('../../components/tasr/TasrNewsCategoryEdit'),
    name: 'tasrNewsCategory_edit',
    meta: {
      description: 'Tasr News Category edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'tasrNewsCategory/new',
    component: () => import('../../components/tasr/TasrNewsCategoryNew'),
    name: 'tasrNewsCategory_new',
    meta: {
      description: 'Tasr News Category new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'tasrNewsCategory',
    component: () => import('../../components/tasr/TasrNewsCategoryList'),
    name: 'tasrNewsCategory_list',
    meta: {
      description: 'Tasr News Category list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  }
]

export default routes
