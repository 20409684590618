const messages = {
  id: 'ID',
  username: 'Username',
  email: 'Email',
  last_login: 'Last login',
  yes: 'Yes',
  no: 'No',
  enabled: 'User enabled',
  firstName: 'Name',
  lastName: 'Surname',
  name: 'Name',
  save: 'Save',
  created_at_from: 'Created since',
  created_at_to: 'Created until',
  last_login_from: 'Logged in since',
  last_login_to: 'Logged in until',
  defaultSite: 'Default site',
  defaultRubric: 'Default rubric',
  department: 'Department',
  settings: 'Settings',
  details: 'Details',
  authorization: 'Authorization',
  roles: 'Roles',
  userSites: 'Enabled sites',
  resetPassword: 'Reset password',
  copyResetLink: 'Copy reset link',
  filter: {
    search: 'Search',
    reset_filter: 'Reset filter'
  },
  error: {
    required_fields: 'Please fill all required fields.',
    user_has_relations: 'Cannot delete, user still has relations.'
  },
  message: {
    updated_record: 'Record updated!',
    deleted_record: 'Record deleted!',
    password_reset: 'Password reset!',
    resetLinkCopied: 'Reset link copied!'
  }
}

export default messages
