import { ROLE_SUPER_ADMIN } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'contentBlock/new',
    component: () => import('../../views/contentBlock/ContentBlockNewView'),
    name: 'contentBlock_new',
    meta: {
      description: 'ContentBlock new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'contentBlock/:id/edit',
    component: () => import('../../views/contentBlock/ContentBlockEditView'),
    name: 'contentBlock_edit',
    meta: {
      description: 'ContentBlock edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'contentBlock/:id',
    component: () => import('../../views/contentBlock/ContentBlockView'),
    name: 'contentBlock_detail',
    meta: {
      description: 'ContentBlock detail',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'contentBlock',
    component: () => import('../../views/contentBlock/ContentBlockListView'),
    name: 'contentBlock_list',
    meta: {
      description: 'ContentBlock list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  }
]

export default routes
