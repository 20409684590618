import { ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR } from '@/model/ValueObject/UserRole'

const routes = [
  {
    path: 'contentBlockItem',
    component: () => import('@/views/hp/HpManagementView'),
    name: 'contentBlockItem_list',
    meta: {
      description: 'HP management',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'contentBlockItemOld',
    component: () => import('../../components/contentBlockItem/ContentBlockItemListOld'),
    name: 'contentBlockItem_list_old',
    meta: {
      description: 'HP management(old)',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  }

]

export default routes
