import CoreApi from '../../api/core'
import UserLoginAttemptDocumentFilterService from '../../services/tracking/UserLoginAttemptFilterService'
import config from '../../config'

const API_NAME = '/userLoginAttemptDocument'

const state = {
  error: null,
  list: [],
  totalCount: 0,
  page: 1,
  pagination: {
    lastCreatedAt: '',
    firstCreatedAt: '',
    firstPage: false,
    lastPage: false
  },
  filter: {
    userId: '',
    username: '',
    responseStatus: '',
    clientIp: '',
    createdAtFrom: '',
    createdAtTo: ''
  }
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.data
  },
  storeFirstPage (state, responseData) {
    state.pagination.firstPage = responseData.firstPage
  },
  storeLastPage (state, responseData) {
    state.pagination.lastPage = responseData.lastPage
  },
  storeTotalCount (state, responseData) {
    state.totalCount = responseData.totalCount
  },
  setPage (state, page) {
    state.page = page
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  setLastCreatedAt (state, lastCreatedAt) {
    state.pagination.lastCreatedAt = lastCreatedAt
  },
  setFirstCreatedAt (state, firstCreatedAt) {
    state.pagination.firstCreatedAt = firstCreatedAt
  }
}

const actions = {
  fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const url = API_NAME +
      '?limit=' + config.defaults.list.limit +
      UserLoginAttemptDocumentFilterService.buildFilterQuery(store.state.filter) +
      UserLoginAttemptDocumentFilterService.buildPaginationQuery(store.state)
    CoreApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
        store.commit('storeFirstPage', res.data)
        store.commit('storeLastPage', res.data)
        if (store.state.pagination.lastCreatedAt === '' && store.state.pagination.firstCreatedAt === '') {
          store.commit('storeTotalCount', res.data)
        }
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  }
}

const getters = {
  detail (state) {
    return state.detail
  },
  list (state) {
    return state.list
  },
  totalCount (state) {
    return state.totalCount
  },
  page (state) {
    return state.page
  },
  error (state) {
    return state.error
  },
  filter (state) {
    return state.filter
  },
  firstPage (state) {
    return state.pagination.firstPage
  },
  lastPage (state) {
    return state.pagination.lastPage
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
