const messages = {
  original: {
    body_text: 'Text pôvodného článku',
    title: 'Titulok pôvodného článku',
    first_paragraph: 'Perex pôvodného článku',
    content_ratio: 'Zhoda',
    tooltip: {
      content_ratio: 'V percentách vyjadrená zhoda medzi originálnym článkom a tým súčasným-prebratým. Podobnosť sa vypočítava iba z titulku a textu, pričom prepočet berie do úvahy iba zmeny znakov (slov). V prepočte majú titulok a text rovnakú váhu. Do výpočtu zhody nespadajú aktivity ako pridanie súvisiacich článkov, úpravy galérie či zmeny perexu. Percento zhody rozrátava spravodlivo výkonnostné metriky z prebratého článku medzi pôvodného autora (originálu) a súčasného.',
      shared_users: 'Vpisovať iba autorov súčasného (prebratého) článku. Výkonnosť z prebratého článku sa automaticky prerozdelí medzi originálneho autora a autora súčasného (prebratého) článku. A to v závislosti od percentuálnej zhody medzi originálnym článkom a prebratým.'
    }
  },
  show: 'Zobraziť',
  status: {
    new: 'Nový',
    transfered: 'Prebratý'
  },
  transfer: 'Prebrať',
  transfer_article: 'Prebrať článok',
  transfered_articles: 'Prebraté články'
}

export default messages
