import CoreApi from '../../api/core'
import { aiServiceApiV2 } from '../../api/aiService'
import QuizModel from '../../model/QuizModel.js'
import QuizFilterService from '../../services/quiz/QuizFilterService'
import QuizOneQuestionFilterService from '../../services/quiz/QuizOneQuestionFilterService'
import config from '../../config'
import QuizOneQuestionFilter from '../../model/QuizOneQuestionFilter'
import QuizFilter from '../../model/QuizFilter'
import _ from 'lodash'

const API_NAME = '/quiz'

const state = {
  error: null,
  detail: QuizModel,
  list: [],
  totalCount: 0,
  page: 1,
  filter: _.cloneDeep(QuizFilter),
  oneQuestionFilter: _.cloneDeep(QuizOneQuestionFilter),
  previousTabIndex: 0,
  generatedQuiz: {},
  quizTheme: '',
  quizGenerating: false
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  },
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  storeGeneratedQuiz (state, responseData) {
    state.generatedQuiz = responseData
  },
  setPage (state, page) {
    state.page = page
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  setOneQuestionFilter (state, filter) {
    state.oneQuestionFilter = filter
  },
  setPreviousTabIndex (state, index) {
    state.previousTabIndex = index
  },
  setQuizTheme (state, theme) {
    state.quizTheme = theme
  },
  setQuizGenerating (state, status) {
    state.quizGenerating = status
  }
}

const actions = {
  fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.page * config.defaults.list.limit) - config.defaults.list.limit
    const url = API_NAME +
      '?limit=' + config.defaults.list.limit +
      '&offset=' + offset +
      '&order[id]=desc&filter_neq[type]=one-question' + QuizFilterService.buildFilterQuery(store.state.filter)
    CoreApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  fetchOneQuestionList (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.page * config.defaults.list.limit) - config.defaults.list.limit
    const url = API_NAME +
      '?limit=' + config.defaults.list.limit +
      '&offset=' + offset +
      '&order[id]=desc&filter_eq[type]=one-question' + QuizOneQuestionFilterService.buildFilterQuery(store.state.oneQuestionFilter)
    CoreApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.log(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  async fetchOne (store, id) {
    await CoreApi().get(API_NAME + '/' + id)
      .then(response => {
        store.commit('storeDetail', response.data)
      })
      .catch(error => console.log(error))
  },
  async create (store, record) {
    return await CoreApi().post(API_NAME, JSON.stringify(record))
      .then(response => {
        if (response.status === 201) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async update (store, record) {
    return await CoreApi().put(API_NAME + '/' + record.id, JSON.stringify(record))
      .then(response => {
        if (response.status === 200) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async deleteRecord (store, record) {
    return await CoreApi().delete(API_NAME + '/' + record.id)
      .then(response => {
        if (response.status === 204) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async fetchByTheme (store, theme) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    store.commit('setQuizGenerating', true)
    try {
      const response = await aiServiceApiV2().post('/generator/quiz', { theme })
      store.commit('storeGeneratedQuiz', response.data)
      store.commit('setQuizTheme', theme)
      store.commit('setQuizGenerating', false)
      store.commit('TOGGLE_LOADING', null, { root: true })
      return response.data
    } catch (error) {
      console.error(error)
      store.commit('TOGGLE_LOADING', null, { root: true })
      store.commit('setQuizGenerating', false)
    }
  }
}

const getters = {
  detail (state, getters, rootState, rootGetters) {
    const quiz = state.detail
    quiz.categories = quiz.categories.map(filterSelectedCategories, this)

    function filterSelectedCategories (id) {
      return rootGetters['category/categoryById'](id)
    }

    return quiz
  },
  list (state) {
    return state.list
  },
  totalCount (state) {
    return state.totalCount
  },
  page (state) {
    return state.page
  },
  error (state) {
    return state.error
  },
  filter (state) {
    return state.filter
  },
  oneQuestionFilter (state) {
    return state.oneQuestionFilter
  },
  previousTabIndex (state) {
    return state.previousTabIndex
  },
  generatedQuiz (state) {
    return state.generatedQuiz
  },
  quizTheme (state) {
    return state.quizTheme
  },
  quizGenerating (state) {
    return state.quizGenerating
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
