import moment from 'moment'

const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
  }
  if (filter.title) {
    filterQuery += '&filter_contains[title]=' + filter.title
  }
  if (filter.layout) {
    filterQuery += '&filter_eq[setting.layout]=' + filter.layout
  }
  if (filter.createdAtFrom) {
    const date = moment(filter.createdAtFrom).add(-1, 'hours').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filterQuery += '&filter_gte[createdAt]=' + date
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
