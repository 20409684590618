import { ROLE_SUPER_ADMIN, ROLE_VIDEO_ADMIN } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'videoShow/:id/edit',
    component: () => import('../../views/video/VideoShowEditView'),
    name: 'videoShow_edit',
    meta: {
      description: 'Video show edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_VIDEO_ADMIN
      ]
    }
  },
  {
    path: 'videoShow/new',
    component: () => import('../../views/video/VideoShowNewView'),
    name: 'videoShow_new',
    meta: {
      description: 'Video show new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_VIDEO_ADMIN
      ]
    }
  },
  {
    path: 'videoShow',
    component: () => import('../../views/video/VideoShowListView'),
    name: 'videoShow_list',
    meta: {
      description: 'Video show list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_VIDEO_ADMIN
      ]
    }
  }
]

export default routes
