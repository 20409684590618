import { ROLE_SUPER_ADMIN } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'pollBlock/new',
    component: () => import('../../views/poll/PollBlockNewView'),
    name: 'pollBlock_new',
    meta: {
      description: 'PollBlock new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'pollBlock/:id/edit',
    component: () => import('../../views/poll/PollBlockEditView'),
    name: 'pollBlock_edit',
    meta: {
      description: 'PollBlock edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'pollBlock/:id',
    component: () => import('../../views/poll/PollBlockView'),
    name: 'pollBlock_detail',
    meta: {
      description: 'PollBlock detail',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'pollBlock',
    component: () => import('../../views/poll/PollBlockListView'),
    name: 'pollBlock_list',
    meta: {
      description: 'PollBlock list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  }
]

export default routes
