import { ROLE_CAR_EDITOR, ROLE_SUPER_ADMIN } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'articleTest/car/new',
    component: () => import('../../views/article/ArticleTestCarNewView'),
    name: 'car_new',
    meta: {
      description: 'Car new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_CAR_EDITOR
      ]
    }
  },
  {
    path: 'articleTest/car/:id/edit',
    component: () => import('../../views/article/ArticleTestCarEditView'),
    name: 'car_edit',
    meta: {
      description: 'Car edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_CAR_EDITOR
      ]
    }
  },
  {
    path: 'articleTest/car/:id',
    component: () => import('../../views/article/ArticleTestCarView'),
    name: 'car_detail',
    meta: {
      description: 'Car detail',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_CAR_EDITOR
      ]
    }
  },
  {
    path: 'articleTest/car',
    component: () => import('../../views/article/ArticleTestCarListView'),
    name: 'car_list',
    meta: {
      description: 'Car list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_CAR_EDITOR
      ]
    }
  }
]

export default routes
