import { ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'tasrNews/:id',
    component: () => import('../../components/tasr/TasrNews'),
    name: 'tasrNews_detail',
    meta: {
      description: 'TasrNews detail',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'tasrNews',
    component: () => import('../../components/tasr/TasrNewsList'),
    name: 'tasrNews_list',
    meta: {
      description: 'TasrNews list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  }
]

export default routes
