import { ROLE_SUPER_ADMIN } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'category/:id/edit',
    component: () => import('../../views/category/CategoryEditView'),
    name: 'category_edit',
    meta: {
      description: 'Category edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'category/new',
    component: () => import('../../views/category/CategoryNewView'),
    name: 'category_new',
    meta: {
      description: 'Category new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'category',
    component: () => import('../../views/category/CategoryListView'),
    name: 'category_list',
    meta: {
      description: 'Category list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  }
]

export default routes
