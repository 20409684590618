import { ROLE_SUPER_ADMIN } from '../../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'beUser/:id/edit',
    component: () => import('../../../views/beUser/BeUserEditView'),
    name: 'beUser_edit',
    meta: {
      description: 'BeUser edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'beUser/new',
    component: () => import('../../../views/beUser/BeUserNewView'),
    name: 'beUser_new',
    meta: {
      description: 'BeUser new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  },
  {
    path: 'beUser',
    component: () => import('../../../views/beUser/BeUserListView'),
    name: 'beUser_list',
    meta: {
      description: 'BeUser list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN
      ]
    }
  }
]

export default routes
