const messages = {
  playability: 'Dohranost videí',
  reach: 'Dosah videí',
  video_performance: 'Výkonnost videí',
  ad_performance: 'Výkonnost video reklamy',
  avgVideosPerUser: 'Průměr videí na uživatele',
  concurrentUsers: 'Unikátní uživatelé',
  playConversionRate: 'In view play conv. rate',
  adConversionRate: 'Ad conv. rate',
  videoStarts: 'Video začíná',
  adStarts: 'Spuštění reklamy',
  adSkipRate: 'Přeskočení reklamy',
  adFinishRate: 'Zhlédnutí reklamy',
  vidFinishRate: 'Zhlédnutí videa',
  videoPlays: 'Spuštění videa',
  dailyAvg: 'Denní průměr',
  total: 'Celkem',
  inViewDisplays: 'Zobrazení přehrávače',
  concurrent_users: 'Realtime počet sledujících v {company}',
  videoviews: 'Videoviews',
  detail: 'Detail',
  devices: 'Devices',
  desktop: 'Desktop',
  mobile: 'Mobile',
  tablet: 'Tablet',
  os: 'OS',
  windows: 'Windows',
  ios: 'iOS',
  android: 'Android',
  top_assets: 'Top videa',
  players_performance: 'Výkonnost přehrávačů podle pozic',
  sources: 'Sources',
  sources_domain: 'Zdroj doména',
  sources_url: 'Top články s videem',
  type: 'Type',
  video_id: 'Video ID',
  title: 'Title',
  displays: 'Displays',
  in_view_displays: 'In view displays',
  videoplays: 'Videoplays',
  videoplays_portfolio: 'Počet přehrání videa v {company}',
  all_sites: 'Celé {company} portfólio',
  ad_starts: 'AD starts',
  video_starts: 'Video starts',
  play_conv_rate: 'In view play conv rate %',
  conversion_rate_info: 'Conversion rate = Video plays / In view displays',
  replays: 'Replays',
  totalVideoViews: 'Video views',
  pass25Plays: 'Dohranost 25%',
  pass50Plays: 'Dohranost 50%',
  pass75Plays: 'Dohranost 75%',
  pass100Plays: 'Dohranost 100%',
  avgPassPerc: 'Průměrná dohranost',
  unique_users_count: 'Unikátní uživatelé',
  characterType: {
    title: 'Povaha videa',
    agencycontent: 'Agenturní',
    editorialcontent: 'Redakční',
    marketingcontent: 'Selfpromo (marketing)',
    nativecontent: 'Nativní'
  },
  load_data: 'Načíst data',
  displayed: 'zobrazených',
  filter: {
    site: 'Stránka',
    department: 'Oddělení',
    date_from: 'Od',
    date_to: 'Do',
    dateFrom: 'Zobrazit od',
    dateTo: 'Zobrazit do',
    reset_filter: 'Resetovat filtr'
  },
  no_data_found: 'Nebyla nalezena žádná data.',
  playlist_performance_today: 'Playlist performance today',
  other: 'other',
  daily_report: {
    author: 'Autor videa',
    title: 'Titulek videa',
    play_request: 'Počet spuštění',
    site: 'Stránka',
    unique_users: 'Unikátní uživatelé',
    average: 'Průměrné zhlédnutí',
    hero: 'Hero',
    article: 'Článek',
    gallery: 'Galerie',
    video_duration: 'Délka videa',
    filter: {
      date: 'Datum'
    }
  },
  header: {
    onRequest: 'On Request',
    shows: 'Relace',
    totalVideos: 'Celkem videí',
    youtube: 'Youtube',
    publishedArticlesCount: 'Počet vypublikovaných článků',
    videosArticlesRate: 'Pokrytí článků videi'
  },
  list: {
    department: '',
    departmentStat: 'Spolu',
    socialNetworks: 'Sociální sítě',
    agencies: 'Agentury',
    mobilePhone: 'Mobil',
    slideShow: 'SlideShow',
    onRequest: 'Realita',
    shows: 'Realita',
    videosCount: 'Realita'
  }
}

export default messages
