const messages = {
  list: {
    id: 'Id',
    title: 'Title',
    slug: 'Slug',
    site: 'Site',
    parent: 'Parent',
    created_at: 'Created at',
    modified_at: 'Modified at',
    created_by: 'Created by',
    absolute_url: 'Absolute url',
    sorting: 'Subrubric Sorting'
  },
  filter: {
    title: 'Title',
    id: 'Id',
    slug: 'Slug',
    site: 'Site',
    parent: 'Parent',
    search: 'Search'
  },
  id: 'Id',
  title: 'Title',
  slug: 'Slug',
  site: 'Site',
  parent: 'Parent',
  iptcCategory: 'IPTC categories',
  meta_title: 'Meta title',
  meta_description: 'Meta description',
  meta_keywords: 'Meta keywords',
  setting_advertCategory: 'ITM area parameter (advertCategory)',
  setting_chartbeatSection: 'Chartbeat section',
  setting_gemiusId: 'Gemius Id',
  setting_prRubric: 'PR rubric',
  setting_special: 'Special rubric',
  setting_advertSection: 'Unit section for Google ads (advertSection)',
  sportTable_seasonId: 'Sport table Season Id',
  sportTable_activeTab: 'Sport table Active Tab (first = 1)',
  rubric_without_articles: 'Rubric without articles (disable possibility to set this rubric to articles, used for custom listing rubrics)',
  hiddenInMenu: 'Hidden in autogenerated menu',
  sorting: 'Subrubric Sorting',
  product: 'Product'
}

export default messages
