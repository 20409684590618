<template>
  <div class="app" :class="`${this.$route.name}`">
    <!-- with the :key in router-view any change to the path will trigger a reload of the component with the new data -->
    <router-view :key="$route.fullPath"></router-view>
    <AppNotifications />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import AppNotifications from '@/AppNotifications.vue'
import EnvironmentService from '@/services/EnvironmentService'

export default {
  name: 'App',
  components: { AppNotifications },
  metaInfo () {
    return {
      title: this.appHeadTitle,
      link: [
        { rel: 'icon', href: this.appFavicon }
      ]
    }
  },
  data () {
    return {
      appHeadTitle: '',
      appFavicon: ''
    }
  },
  computed: {
    ...mapState({
      articlePreviewUrl: state => state.config.articlePreviewUrl
    }),
    ...mapGetters(['vlm'])
  },
  methods: {
    clientDimensionChange () {
      const windowWidth = window.innerWidth
      this.$store.commit('site/setWidth', windowWidth)
      if (windowWidth <= 600) {
        this.notificationWidth = '100%'
      } else if (windowWidth > 1200) {
        this.notificationWidth = 800
      } else {
        this.notificationWidth = 600
      }
    },
    setAppHeadTitle () {
      const { name } = EnvironmentService.getEnvironmentVisualSettings()
      this.appHeadTitle = this.vlm
        ? `Aqua${name ? ' - ' + name : ''}`
        : `Eagle ADMIN${name ? ' - ' + name : ''}`
    },
    setAppFavicon () {
      this.appFavicon = this.vlm ? '/favicon/vlm/favicon.ico' : '/favicon/nmh/favicon.ico'
    },
    /**
     * See https://community.atlassian.com/t5/Jira-Service-Management/Dynamically-Embedding-Service-Desk-Widget/qaq-p/899686
     */
    appendJiraSupportScript () {
      const jiraSupportScript = document.createElement('script')
      const jiraSupportDataKey = this.vlm ? 'd9aea4e6-4dd6-4fbe-90ab-7cb144b14165' : 'dc480a2a-bfa2-4519-a9df-1b1b621cfb0c'

      jiraSupportScript.type = 'text/javascript'
      jiraSupportScript.src = 'https://jsd-widget.atlassian.com/assets/embed.js'
      jiraSupportScript.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com')
      jiraSupportScript.setAttribute('data-key', jiraSupportDataKey)
      jiraSupportScript.setAttribute('data-jsd-embedded', '')
      jiraSupportScript.onload = () => {
        const domContentLoadedEvent = new Event('DOMContentLoaded', { bubbles: true, cancelable: true })
        window.document.dispatchEvent(domContentLoadedEvent)
      }

      document.body.appendChild(jiraSupportScript)
    }
  },
  mounted () {
    this.appendJiraSupportScript()
    this.setAppHeadTitle()
    this.setAppFavicon()
    this.clientDimensionChange()
    window.addEventListener('resize', this.clientDimensionChange)
  },
  destroyed () {
    window.removeEventListener('resize', this.clientDimensionChange)
  }
}
</script>

<style scoped lang="scss">
 .app {
   background: #f5f6fa;
   min-height: 100vh;
 }
</style>
