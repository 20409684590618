import { ROLE_RESTAURANT_HOTEL_EDITOR, ROLE_SUPER_ADMIN } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'articleTest/hotel/new',
    component: () => import('../../views/article/ArticleTestHotelNewView'),
    name: 'hotel_new',
    meta: {
      description: 'Hotel new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_RESTAURANT_HOTEL_EDITOR
      ]
    }
  },
  {
    path: 'articleTest/hotel/:id/edit',
    component: () => import('../../views/article/ArticleTestHotelEditView'),
    name: 'hotel_edit',
    meta: {
      description: 'Hotel edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_RESTAURANT_HOTEL_EDITOR
      ]
    }
  },
  {
    path: 'articleTest/hotel/:id',
    component: () => import('../../views/article/ArticleTestHotelView'),
    name: 'hotel_detail',
    meta: {
      description: 'Hotel detail',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_RESTAURANT_HOTEL_EDITOR
      ]
    }
  },
  {
    path: 'articleTest/hotel',
    component: () => import('../../views/article/ArticleTestHotelListView'),
    name: 'hotel_list',
    meta: {
      description: 'Hotel list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_RESTAURANT_HOTEL_EDITOR
      ]
    }
  }
]

export default routes
