const messages = {
  id: 'ID',
  username: 'Užívateľské meno',
  email: 'Email',
  last_login: 'Posledné prihlásenie',
  yes: 'Áno',
  no: 'Nie',
  enabled: 'Užívateľ aktivovaný',
  firstName: 'Meno',
  lastName: 'Priezvisko',
  name: 'Meno',
  save: 'Uložiť',
  created_at_from: 'Vytvorené od',
  created_at_to: 'Vytvorené do',
  last_login_from: 'Posledné prihlásenie od',
  last_login_to: 'Posledné prihlásenie do',
  defaultSite: 'Stránka',
  defaultRubric: 'Rubrika',
  department: 'Oddelenie',
  settings: 'Nastavenia',
  details: 'Detaily',
  authorization: 'Autorizácia',
  roles: 'Role',
  userSites: 'Povolené stránky',
  resetPassword: 'Resetuj heslo',
  copyResetLink: 'Kopíruj reset linku',
  filter: {
    search: 'Hľadať',
    reset_filter: 'Zrušiť filter'
  },
  error: {
    required_fields: 'Prosím, vyplňte všetky povinné polia',
    user_has_relations: 'Nedá sa zmazať, užívateľ má relácie.'
  },
  message: {
    updated_record: 'Záznam bol zmenený!',
    deleted_record: 'Záznam bol vymazaný!',
    password_reset: 'Heslo resetované!',
    resetLinkCopied: 'Reset linka skopírovaná!'
  }
}

export default messages
