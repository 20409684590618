export default {
  id: null,
  title: '',
  slug: '',
  subTitle: '',
  image: null,
  imageInfo: null,
  enabled: 0,
  videoCount: 0
}
