<template>
  <div class="sidebar-li-main">
    <div
      v-if="!item.routeName.length"
      class="sidebar-li-main__item"
      :data-test="item.id"
    >
      <ArticleListIcon v-if="item.id === 'listOfArticles'" class="sidebar-li-main__main-icon" />
      <ArticleOfferIcon v-if="item.id === 'articleOffers'" class="sidebar-li-main__main-icon" />
      <EditorPerformanceIcon v-if="item.id === 'editorPerformance'" class="sidebar-li-main__main-icon" />
      <EshopIcon v-if="item.id === 'eshop'" class="sidebar-li-main__main-icon" />
      <DamIcon v-if="item.id === 'dam'" class="sidebar-li-main__main-icon" />
      <HomepageIcon v-if="item.id === 'homepage'" class="sidebar-li-main__main-icon" />
      <LanguageIcon v-if="item.id === 'language'" class="sidebar-li-main__main-icon" />
      <ModuleIcon v-if="item.id === 'modules'" class="sidebar-li-main__main-icon" />
      <PrintIcon v-if="item.id === 'print'" class="sidebar-li-main__main-icon" />
      <SponsoredArticlesIcon v-if="item.id === 'sponsoredArticles'" class="sidebar-li-main__main-icon" />
      <SystemIcon v-if="item.id === 'system'" class="sidebar-li-main__main-icon" />
      <StatisticsIcon v-if="item.id === 'statistics'" class="sidebar-li-main__main-icon" />
      <TestIcon v-if="item.id === 'tests'" class="sidebar-li-main__main-icon" />
      <UserIcon v-if="item.id === 'users'" class="sidebar-li-main__main-icon" />
      <VideoIcon v-if="item.id === 'video'" class="sidebar-li-main__main-icon" />
      <span class="sidebar-li-main__text">{{ $t(item.displayName) }}</span>
      <ArrowDownIcon class="sidebar-li-main__arrow-icon" />
    </div>
    <router-link
      v-if="item.routeName.length"
      tag="div"
      :to="{name: item.routeName}"
      class="sidebar-li-main__item"
      :data-name="item.id"
      :data-test="item.dataTest ? item.dataTest : item.id"
    >
      <RealtimeStatisticsIcon v-if="item.id.startsWith('dashboard')" class="sidebar-li-main__main-icon" />
      <VideoTutorialIcon v-if="item.id === 'videoTutorial'" class="sidebar-li-main__main-icon" />
      <span class="sidebar-li-main__text">{{ $t(item.displayName) }}</span>
    </router-link>
  </div>
</template>

<script>
import ArrowDownIcon from '@/assets/img/svg/arrow-down.svg?inline'
import ArticleListIcon from '@/assets/img/svg/article-list.svg?inline'
import ArticleOfferIcon from '@/assets/img/svg/article-offers.svg?inline'
import DamIcon from '@/assets/img/svg/dam.svg?inline'
import EditorPerformanceIcon from '@/assets/img/svg/editor-performance.svg?inline'
import EshopIcon from '@/assets/img/svg/eshop.svg?inline'
import HomepageIcon from '@/assets/img/svg/homepage.svg?inline'
import LanguageIcon from '@/assets/img/svg/language.svg?inline'
import ModuleIcon from '@/assets/img/svg/modules.svg?inline'
import PrintIcon from '@/assets/img/svg/print.svg?inline'
import RealtimeStatisticsIcon from '@/assets/img/svg/realtime-statistics.svg?inline'
import SponsoredArticlesIcon from '@/assets/img/svg/sponsored-articles.svg?inline'
import StatisticsIcon from '@/assets/img/svg/statistics.svg?inline'
import SystemIcon from '@/assets/img/svg/system.svg?inline'
import TestIcon from '@/assets/img/svg/test.svg?inline'
import UserIcon from '@/assets/img/svg/users.svg?inline'
import VideoIcon from '@/assets/img/svg/video.svg?inline'
import VideoTutorialIcon from '@/assets/img/svg/video-tutorial.svg?inline'
export default {
  name: 'DashSidebarListItemMain',
  components: {
    ArrowDownIcon,
    ArticleListIcon,
    ArticleOfferIcon,
    DamIcon,
    EditorPerformanceIcon,
    EshopIcon,
    HomepageIcon,
    LanguageIcon,
    ModuleIcon,
    PrintIcon,
    RealtimeStatisticsIcon,
    SponsoredArticlesIcon,
    StatisticsIcon,
    SystemIcon,
    TestIcon,
    UserIcon,
    VideoIcon,
    VideoTutorialIcon
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
  .sidebar-li-main {
    &__item {
      @include size(100% _);
      @include padding(11px 15px);
      @include radius(6px);
      @include grid-gap(10px);
      background: #fff;
      color: #465674;
      display: grid;
      grid-template-columns: min-content auto min-content;
      align-items: center;
      .sidebar__list-item--submenu & {
        background: #ecf2ff;
        color: #6599fe;
      }
      .sidebar__list-item--active & {
        .dash--compact.dash--nav-not-hover & {
          @include bp(7) {
            background: #ecf2ff;
            color: #6599fe;
          }
        }
      }
      .dash--nav-not-hover:not(.dash--sidebar-mob) & {
        @include size(100% 40px);
        display: flex;
        align-items: center;
        grid-template-columns: 1fr;
        @include bp(7) {
          justify-content: center;
          padding: 0;
        }
      }
      .dash--compact.dash--nav-not-hover & {
        @include bp(7) {
          background: #fff;
          color: #465674;
        }
      }
      &.router-link-active {
        &:hover {
          .sidebar-li-main__main-icon {
            fill: #6599fe;
          }
          .sidebar-li-main__text {
            color: #6599fe;
          }
        }
      }
    }
    .router-link-active {
      .sidebar-li-main__main-icon {
        fill: #6599fe;
      }
      .sidebar-li-main__text {
        color: #6599fe;
      }
    }
    &__main-icon {
      @include size(14px);
      fill: #abbdcc;
      pointer-events: none;
      .sidebar__list-item--submenu & {
        fill: #6599fe;
      }
      .sidebar__list-item--active & {
        .dash--compact.dash--nav-not-hover & {
          @include bp(7) {
            fill: #6599fe;
          }
        }
      }
      .dash--compact.dash--nav-not-hover & {
        @include bp(7) {
          fill: #abbdcc;
        }
      }
    }
    &__text {
      pointer-events: none;
      .dash--compact & {
        display: none;
      }
      .dash--nav-hover &,
      .dash--sidebar-mob & {
        display: block;
      }
    }
    &__arrow-icon {
      @include size(11px);
      fill: #abbdcc;
      transform: rotate(90deg);
      transition: all 300ms;
      pointer-events: none;
      .sidebar__list-item--submenu & {
        fill: #6599fe;
        transform: rotate(0deg);
      }
      .dash--compact & {
        display: none;
      }
      .dash--nav-hover &,
      .dash--sidebar-mob & {
        display: block;
      }
    }
  }
</style>
