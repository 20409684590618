export const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN'
export const ROLE_DEPARTMENT_MANAGER = 'ROLE_DEPARTMENT_MANAGER'
export const ROLE_EDITORIAL_PERF_ADMIN = 'ROLE_EDITORIAL_PERF_ADMIN'
export const ROLE_EDITOR = 'ROLE_EDITOR'
export const ROLE_JOURNALIST = 'ROLE_JOURNALIST'
export const ROLE_USER = 'ROLE_USER'
export const ROLE_DAM_USER = 'ROLE_DAM_USER'
export const ROLE_DAM_ADMIN = 'ROLE_DAM_ADMIN'
export const ROLE_DAM_PDF_USER = 'ROLE_DAM_PDF_USER'
export const ROLE_DAM_PDF_ADMIN = 'ROLE_DAM_PDF_ADMIN'
export const ROLE_DON_USER = 'ROLE_DON_USER'
export const ROLE_PRINT_PUBLICATION = 'ROLE_PRINT_PUBLICATION'
export const ROLE_PRINT_ASSISTANT = 'ROLE_PRINT_ASSISTANT'
export const ROLE_TOPIC_EDITOR = 'ROLE_TOPIC_EDITOR'
export const ROLE_ANALYST = 'ROLE_ANALYST'
export const ROLE_VIDEO_USER = 'ROLE_VIDEO_USER'
export const ROLE_VIDEO_ADMIN = 'ROLE_VIDEO_ADMIN'
export const ROLE_VIDEO_ANALYST = 'ROLE_VIDEO_ANALYST'
export const ROLE_VIDEO_STATS = 'ROLE_VIDEO_STATS'
export const ROLE_CAR_EDITOR = 'ROLE_CAR_EDITOR'
export const ROLE_RESTAURANT_HOTEL_EDITOR = 'ROLE_RESTAURANT_HOTEL_EDITOR'
export const ROLE_ESHOP_ADMIN = 'ROLE_ESHOP_ADMIN'

export const AVAILABLE_VALUES = [
  {
    id: ROLE_SUPER_ADMIN,
    title: 'Super Admin'
  },
  {
    id: ROLE_DEPARTMENT_MANAGER,
    title: 'Department manager'
  },
  {
    id: ROLE_EDITORIAL_PERF_ADMIN,
    title: 'Editorial performance Admin'
  },
  {
    id: ROLE_EDITOR,
    title: 'Editor'
  },
  {
    id: ROLE_JOURNALIST,
    title: 'Journalist'
  },
  {
    id: ROLE_USER,
    title: 'User'
  },
  {
    id: ROLE_DAM_USER,
    title: 'DAM User'
  },
  {
    id: ROLE_DAM_ADMIN,
    title: 'DAM Admin'
  },
  {
    id: ROLE_DAM_PDF_USER,
    title: 'DAM Documents User'
  },
  {
    id: ROLE_DAM_PDF_ADMIN,
    title: 'DAM Documents Admin'
  },
  {
    id: ROLE_DON_USER,
    title: 'DON User'
  },
  {
    id: ROLE_ANALYST,
    title: 'Analyst export'
  },
  {
    id: ROLE_VIDEO_USER,
    title: 'Video user'
  },
  {
    id: ROLE_VIDEO_ADMIN,
    title: 'Video admin'
  },
  {
    id: ROLE_VIDEO_ANALYST,
    title: 'Video analyst'
  },
  {
    id: ROLE_VIDEO_STATS,
    title: 'Video statistics'
  },
  {
    id: ROLE_CAR_EDITOR,
    title: 'Car Editor'
  },
  {
    id: ROLE_RESTAURANT_HOTEL_EDITOR,
    title: 'Restaurant/Hotel Editor'
  },
  {
    id: ROLE_TOPIC_EDITOR,
    title: 'Topic editor'
  },
  {
    id: ROLE_PRINT_PUBLICATION,
    title: 'Print Publication'
  },
  {
    id: ROLE_PRINT_ASSISTANT,
    title: 'Print Assistant'
  },
  {
    id: ROLE_ESHOP_ADMIN,
    title: 'E-shop Admin'
  }
]
