import Configuration from './configuration'

export default {
  appVersion: () => Configuration.appVersion(),
  environment: () => Configuration.environment(),
  customerSpecific: {
    vlm: () => Configuration.customerSpecific.vlm(),
    nmh: () => Configuration.customerSpecific.nmh()
  },
  core: {
    authUrl: () => Configuration.core.authUrl(),
    apiUrl: () => Configuration.core.apiUrl(),
    passwordResetUrl: () => Configuration.core.passwordResetUrl(),
    apiTimeout: 30
  },
  sso: {
    baseUrl: () => Configuration.sso.baseUrl(),
    apiUrl: () => Configuration.sso.apiUrl(),
    apiTimeout: 30,
    oidcManager: {
      authority: () => Configuration.sso.oidcManager.authority(),
      clientId: () => Configuration.sso.oidcManager.clientId(),
      scope: () => Configuration.sso.oidcManager.scope(),
      responseType: () => Configuration.sso.oidcManager.responseType(),
      responseMode: () => Configuration.sso.oidcManager.responseMode(),
      redirectUri: () => Configuration.sso.oidcManager.redirectUri(),
      postLogoutRedirectUri: () => Configuration.sso.oidcManager.postLogoutRedirectUri()
    }
  },
  nativeCampaignManagement: {
    apiUrl: () => Configuration.nativeCampaignManagement.apiUrl(),
    xApiKey: () => Configuration.nativeCampaignManagement.xApiKey(),
    apiTimeout: 60
  },
  don: {
    apiUrl: () => Configuration.don.apiUrl(),
    apiTimeout: 5
  },
  edon: {
    apiUrl: () => Configuration.edon.apiUrl(),
    downloadUrl: () => Configuration.edon.apiUrl().replace(/v1$/, 'pub/download/'),
    apiTimeout: 60
  },
  dam: {
    baseUrl: () => Configuration.dam.baseUrl(),
    apiUrl: () => Configuration.dam.apiUrl(),
    apiTimeout: 60,
    apiExtractTimeout: 1800,
    apiUploadTimeout: 1800
  },
  covers: {
    baseUrl: () => Configuration.covers.baseUrl()
  },
  antares: {
    apiUrl: () => Configuration.antares.apiUrl(),
    apiToken: () => Configuration.antares.apiToken(),
    apiTimeout: 15
  },
  eshop: {
    apiUrl: () => Configuration.eshop.apiUrl(),
    apiTimeout: 60
  },
  video: {
    apiUrl: () => Configuration.video.apiUrl(),
    apiTimeout: 15
  },
  sport24: {
    apiUrl: () => Configuration.sport24.apiUrl(),
    apiTimeout: 60
  },
  ooyala: {
    apiTimeout: 15
  },
  livebox: {
    apiTimeout: 1000
  },
  analytics: {
    apiUrl: () => Configuration.analytics.apiUrl(),
    apiTimeout: 30
  },
  notificationService: {
    apiUrl: () => Configuration.notificationService.apiUrl(),
    apiTimeout: 60
  },
  dataHub: {
    apiUrl: () => Configuration.dataHub.apiUrl(),
    apiTimeout: 60,
    xApiKey: {
      abn: () => Configuration.dataHub.xApiKey.abn(),
      realtime: () => Configuration.dataHub.xApiKey.realtime(),
      hpctr: () => Configuration.dataHub.xApiKey.hpctr(),
      video: () => Configuration.dataHub.xApiKey.video(),
      universalDashboarding: () => Configuration.dataHub.xApiKey.universalDashboarding(),
      recommend: () => Configuration.dataHub.xApiKey.recommend()
    },
    recommend: {
      apiUrl: () => Configuration.dataHub.recommend.apiUrl(),
      xApiKey: () => Configuration.dataHub.recommend.xApiKey()
    },
    editPerf: {
      apiUrl: () => Configuration.dataHub.editPerf.apiUrl(),
      xApiKey: () => Configuration.dataHub.editPerf.xApiKey()
    }
  },
  aiService: {
    apiUrl: () => Configuration.aiService.apiUrl(),
    apiUrlV2: () => Configuration.aiService.apiUrlV2(),
    apiTimeout: 60
  },
  linkPreview: {
    apiUrl: () => Configuration.linkPreview.apiUrl(),
    apiToken: () => Configuration.linkPreview.apiToken()
  },
  eagleVideoPlayer: {
    baseUrl: () => Configuration.eagleVideoPlayer.baseUrl()
  },
  articleSetting: {
    elasticArticleListing: () => Configuration.articleSetting.elasticArticleListing(),
    articlePreviewUrl: () => Configuration.articleSetting.articlePreviewUrl(),
    articleLogInterval: () => Configuration.articleSetting.articleLogInterval()
  },
  hotjar: {
    hotjarAccountId: () => Configuration.hotjar.hotjarAccountId()
  },
  mediaUrl: 'https://images.nmhmedia.sk',
  fixedLayout: false,
  hideLogoOnMobile: false,
  defaults: {
    list: {
      limit: 10
    }
  },
  article: {
    list: {
      limit: 10
    }
  },
  contentBlock: {
    list: {
      limit: 30
    }
  },
  /**
   * Use this when vuex store is not ready yet (e.g. in routes)
   */
  isVlm: () => Configuration.customerSpecific.vlm() === 'true'
}
