const messages = {
  csvExport: 'Export CSV',
  problemsContact: 'V případě problémů kontaktujte',
  annotationsFor: 'Anotace pro',
  numberOfViews: 'Počet zobrazení',
  numberOfClicks: 'Počet kliků',
  ctr: 'CTR',
  previewPosition: 'Pozice náhledu',
  checkAndUpdateStatus: 'Kontrola a aktualizace stavu',
  checkingStatusInLivebox: 'Kontrola stavu ve službě Livebox',
  isComplete: 'Video je KOMPLETNÍ',
  currentStatusInLivebox: 'Aktuální stav ve službě Livebox',
  isNotComplete: 'Video není KOMPLETNÍ',
  unableToGetLiveboxAssetInfo: 'Nelze získat informace o aktivech Livebox',
  unableToGetStatusFromLivebox: 'Nelze získat stav ze služby Livebox',
  canNotRetrieveGeneratedPreviewImages: 'Nelze načíst vygenerované náhledové obrázky',
  statistics: 'Statistiky videa',
  playerPerformance: 'Výkonnost přehrávače',
  player: 'Přehrávač',
  time_section: 'Čas. úsek',
  annotation: 'Anotace',
  annotation_visualization: 'Vizuální styl',
  annotation_range: 'Úsek zobrazení anotace',
  annotation_info: 'Informace o anotaci',
  new_annotation: 'Nová anotace',
  preview_annotations: 'Náhled videa s anotacemi',
  video_required_upload: 'Pro přidání anotace je třeba nejdříve nahrát video',
  video_content: 'Video obsah',
  upload_video: 'Nahrajte video',
  uploaded_video: 'Nahrané video',
  tabs: {
    main: 'Hlavní informace',
    video: 'Video',
    annotations: 'Anotace',
    stats: 'Statistiky'
  },
  list: {
    id: 'Id',
    type: 'Type',
    preview_image: 'Video',
    video: 'Video',
    ooyala_id: 'Ooyala Id',
    title: 'Titulek',
    default_site: 'Hlavní stránka',
    status: 'Stav',
    used_in_article: 'Použití v článku',
    article_count: 'Použití v článku',
    video_duration: 'Délka videa',
    created_at: 'Datum vytvoření',
    created_by: 'Vytvořil'
  },
  filter: {
    default_site: 'Hlavní stránka',
    type: 'Typ',
    title: 'Titulek',
    keywords: 'Klíčová slova',
    description: 'Perex',
    all: 'All',
    search: 'Hledat',
    id: 'Id',
    ids: 'Id / Brightcove Id / Ooyala Id / Livebox Id',
    ooyala_id: 'Ooyala Id',
    brightcove_id: 'Brightcove Id',
    livebox_asset_id: 'Livebox Id',
    author: 'Autor',
    camera: 'Kamera',
    editing: 'Střih',
    site: 'Hlavní stránka',
    video_show: 'PLUS TV pořady',
    video_nature: 'Povaha videa',
    video_source: 'Původ videa',
    video_type: 'Typ videa',
    status: 'Stav',
    flags: 'Flags',
    setting_editorial_content: 'Redakční obsah',
    setting_agency_content: 'Agenturní obsah',
    setting_marketing_content: 'Selfpromo (marketing)',
    setting_internal: 'Interní',
    setting_social: 'Social',
    setting_agency: 'Agentura',
    setting_camera: 'On request',
    setting_mobile_phone: 'Mobil',
    setting_slideshow: 'Slideshow (obrázky)',
    setting_show: 'Relace',
    setting_for_adults: 'Obsah 18+',
    setting_product_placement: 'Product placement',
    setting_exclusive: 'Exkluzivní',
    date_from: 'Vytvořené od',
    date_to: 'Vytvořené od'
  },
  id: 'Id',
  type: 'Typ',
  file_name: 'File name',
  file_size: 'Velikost',
  status: 'Stav',
  video_duration: 'Délka',
  video_width: 'Video width',
  video_height: 'Video height',
  default_site: 'Hlavní stránka',
  video_show: 'PLUS TV pořady',
  video_category: 'PLUS TV kategorie',
  video_episode_number: 'PLUS TV číslo epizody',
  setting_editorial_content: 'Redakční obsah',
  setting_agency_content: 'Agenturní obsah',
  setting_marketing_content: 'Selfpromo (marketing)',
  setting_native_content: 'Nativní obsah',
  setting_internal: 'Interní',
  setting_social: 'Social',
  setting_agency: 'Agentura',
  setting_camera: 'On request',
  setting_mobile_phone: 'Mobil',
  setting_slideshow: 'Slideshow (obrázky)',
  setting_show: 'Relace',
  setting_for_adults: 'Obsah 18+',
  setting_product_placement: 'Komerční video / Product placement',
  setting_exclusive: 'Exkluzivní',
  setting_no_ads: 'No ads',
  setting_dynamic_preview: 'Dynamic preview',
  setting_dynamic_preview_start: 'Dynamic preview start (sekunda)',
  setting_dynamic_preview_duration: 'Dynamic preview délka trvání (sekundy)',
  video_thumbnail_position: 'Náhledový obrázek - procentní hodnota mezi 5 a 95',
  meta_title: 'Titulek',
  meta_description: 'Perex',
  meta_keywords: 'Klíčová slova',
  author: 'Autor',
  camera: 'Kamera',
  editing: 'Střih',
  preview_image: 'Náhledový obrázek',
  annotations: 'Anotace',
  annotation_web: 'na web',
  annotation_video: 'na video',
  annotation_article: 'na článek',
  categories: 'Obsahové kategorie',
  safety_topics: 'Téma (brand safety)',
  video_nature: 'Povaha videa',
  video_source: 'Původ videa',
  video_source_gdpr: 'Zdroj videa (GDPR)',
  video_type: 'Typ videa (zařízení)',
  ooyala_id: 'Ooyala ID',
  brightcove_id: 'Brightcove ID',
  livebox_asset_id: 'Livebox ID',
  youtube_id: 'Youtube ID',
  annotation_type: 'Typ',
  annotation_title: 'Titulek',
  annotation_perex: 'Krátký obsah',
  annotation_url: 'Url',
  annotation_video_id: 'Video ID',
  annotation_image: 'Image',
  annotation_image_mobile: 'Image Mobile',
  annotation_bg_color: 'Barva pozadí',
  annotation_color: 'Barva textu',
  annotation_border: 'Oramování',
  annotation_start: 'Začátek',
  annotation_end: 'Konec',
  annotation_position: 'Pozice',
  status_new: 'Nové',
  status_uploading: 'Nahrává se',
  status_processing: 'Připravuje sa',
  status_live: 'Nahrané',
  status_published: 'Publikované',
  status_error: 'Chyba',
  created_by: 'Vytvořil',
  modal: {
    set_preview_image: 'Opravdu chcete nastavit tento náhledový obrázek?',
    video_header: 'Vybrat video'
  },
  title_is_too_short: 'Titulek je krátký.',
  title_is_too_long: 'Titulek je příliš dlouhý.',
  description_is_too_short: 'Perex je krátký.',
  description_is_too_long: 'Perex je příliš dlouhý.',
  video_type_title: 'Typ',
  video_type_value: {
    ooyala: 'Ooyala / Brightcove',
    youtube: 'Youtube',
    livebox: 'Livebox'
  },
  video_status_title: 'Stav',
  video_status_value: {
    new: 'New',
    uploading: 'Uploading',
    processing: 'Processsing',
    live: 'Uploaded',
    published: 'Published',
    error: 'Error'
  },
  main_content: 'Hlavní obsah',
  main_settings: 'Hlavní nastavení',
  headline_preview_image: 'Náhledový obrázek',
  headline_video_parameters: 'Parametry videa',
  headline_used_in_articles: 'Použito v článku',
  video_upload_required_fields_message: 'Pro nahrání videa je třeba vyplnit všechny povinné údaje',
  video_article_not_found: 'Video se nenachází v žádném článku.',
  video_article_exists: 'Pro video již existuje článek',
  range_slider_info_1: 'vyznačený úsek pro přidání',
  annotations_not_found: 'Video neobsahuje žádné anotace.',
  video_is_processing: 'Video se zpracovává na straně Livebox.',
  video_upload_success_message: 'Vaše video bylo úspěšně nahráno a bude zprocesováno na straně Livebox.',
  video_upload_error_message: 'Problem s nahráváním na straně Livebox.',
  error_delete_video_article_exists: 'Video nelze smazat. Video je již použito v článku.',
  error_accesss_denied: 'Nemáte oprávnění k provedení dané akce.',
  none: 'N/A',
  download: 'Stáhnout',
  preparing: 'Připravuji video',
  sending: 'Posílám do systému informace o novém videu, které se bude nahrávat (titulek, perex apod.)',
  process: 'Průběh nahrávání',
  uploadIsStarting: 'Vaše video jsme začali nahrávat.',
  closeWindowInfo: 'Prosím, nezavírejte toto okno, ani nevypínejte počítač',
  finalization: 'Finalizuji nahravání.',
  finalizationBrightcove: 'Vaše video finalizujeme na straně Brightcove.',
  finalizationLivebox: 'Vaše video finalizujeme na straně Livebox.',
  finalizationOoyala: 'Vaše video finalizujeme na straně Ooyala.',
  ytToOoyalaErr: 'Chyba. Nepodařilo se nahrát youtube video do Ooaly.',
  ytDuplication: 'Chyba. Duplicitní Youtube ID.',
  videoDuplication: 'V systému již zadané video existuje.',
  select: 'Vyberte si video',
  editVideo: 'Upravit video',
  help: {
    sections: {
      intro: 'Intro',
      filter: 'Filtrování a hledání článků v CMS',
      articleCreationAndEditing: 'Tvorba a úprava článku',
      workWithPhotos: 'Práce s fotkami',
      workWithVideos: 'Práce s videi',
      modules: 'Moduly',
      print: 'Print',
      boxManagement: 'Spravování boxů na úvodních stránkách webů',
      sponsoredArticles: 'Sponzorované články',
      statisticsAndPerformance: 'Statistiky a výkonnost'
    },
    v_13961: {
      title: 'Přihlašovací obrazovka',
      item1: 'URL, přihlašovací obrazovka',
      item2: 'Obnovení zapomenutého hesla',
      item3: 'Menu',
      item4: 'Načtení nové verze'
    },
    v_13960: {
      title: 'Stavy článku',
      item1: 'Popsané stavy článku',
      item2: 'Přechody mezi stavy článku'
    },
    v_26427: {
      title: 'Realtime statistiky',
      item1: 'Ukazatel výkonnosti stránky',
      item2: 'Grafové ukazatele výkonu stránky za posledních 24 hod.',
      item3: 'Nejvýkonnější, nejčitannější články v aktuálním momentu'
    },
    v_13948: {
      title: 'Seznam článků',
      item1: 'Vyhledávací filtry',
      item2: 'Karty pro stavy článků',
      item3: 'Tlačítka pro vstup do úpravy, náhledu článku a kopírování ID článku',
      item4: 'Informační karta článku'
    },
    v_13962: {
      title: 'Vyhledávání článku a Chrome plugin',
      item1: 'Vyhledávání článku podle URL',
      item2: 'Chrome plugin',
      item3: 'Odkaz na rozšíření pro Google Chrome'
    },
    v_14926: {
      title: 'TASR agenturní články',
      item1: 'Vyhledávání zprávy z TASR',
      item2: 'Vytvoření článku z TASR zprávy'
    },
    v_24702: {
      title: 'Nabídka obsahu',
      item1: 'Vyhledávání článků v Nabídce obsahu',
      item2: 'Převzetí článku z Nabídky obsahu',
      item3: 'Shoda článků'
    },
    v_52695: {
      title: 'Nový Article editor',
      item1: 'Nový Article editor, práce s ním a rozdíly oproti starému editoru'
    },
    v_13943: {
      title: 'Hlavní obsah',
      item1: 'Jak vytvořit nový článek',
      item2: 'Hlavní lišta v článku a práce s tlačítky',
      item3: 'Titulek, titulek pro URL, perex'
    },
    v_13942: {
      title: 'Hlavní video',
      item1: 'Práce s videem namísto hlavní fotky článku'
    },
    v_17483: {
      title: 'Autorský podíl',
      item1: 'Jakým způsobem vyplňovat nové povinné pole autorský podíl v hlavních nastaveních článku'
    },
    v_13945: {
      title: 'Související články pod textem',
      item1: 'Nastavení a řazení souvisejících článků pod tělem článku'
    },
    v_13944: {
      title: 'Nastavení',
      item1: 'Rubriky, Plánování publikování, Autoři na webu',
      item2: 'Povaha článku a zdroj, Témata, Obsahové kategorie',
      item3: 'URL článku a galerie, Další nastavení článku'
    },
    v_13940: {
      title: 'Další typy článků',
      item1: 'Externí článek',
      item2: 'Kvíz',
      item3: 'Video článek'
    },
    v_13946: {
      title: 'Fotostory',
      item1: 'Vytváření fotostory'
    },
    v_28655: {
      title: 'Onemocnění'
    },
    v_13941: {
      title: 'Tělo článku',
      item1: 'Vrchní lišta pro úpravy textu',
      item2: 'Práce s moduly v těle článku (obrázek, videa, citát, anketa, související článek, HTML embed, stránkování, infobox)'
    },
    v_13958: {
      title: 'Hlavní fotografie',
      item1: 'Vyhledávání fotek',
      item2: 'Nahrávání fotek z PC/URL',
      item3: 'Ořezávání fotky',
      item4: 'Úprava popisu fotky'
    },
    v_13955: {
      title: 'Fotka v těle článku',
      item1: 'přidávání nebo vyhledávání fotek v těle článku'
    },
    v_13956: {
      title: 'Galerie',
      item1: 'Přidávání fotek do galerie'
    },
    v_48883: {
      title: 'Galerie',
      item1: 'Vkládání obrázků z veřejných Instagram a Facebook postů do galerií článků'
    },
    v_13957: {
      title: 'DAM (banka fotek)',
      item1: 'Zobrazení fotobanky a filtrace fotek',
      item2: 'Nahrávání nových fotek',
      item3: 'Úprava ořezu nebo metadat fotky',
      item4: 'Vodoznak - návod k vložení vodoznaku do fotografii'
    },
    v_15946: {
      title: 'DAM - pokročilé vyhledávání fotek',
      item1: 'Tipy pro pokročilé vyhledávání fotek',
      item2: 'Nastavení filtrů'
    },
    v_18941: {
      title: 'TASR fotky',
      item1: 'Filtrování fotek z tasr',
      item2: 'Stahování fotek z tasr',
      item3: 'Použití fotek z tasr v článku, galerií'
    },
    v_18956: {
      title: 'Hromadné operace',
      item1: 'Hromadné stahování fotek z DAM',
      item2: 'Omezení',
      item3: 'Vymazávání'
    },
    v_18172: {
      title: 'Seznam videí - vyhledávání',
      item1: 'Vyhledávání videí',
      item2: 'Používání filtrů',
      item3: 'Značky u videa'
    },
    v_18796: {
      title: 'Nahrávání nových videí',
      item1: 'Nahrání videa do Eagle',
      item2: 'Stavy videa',
      item3: 'Úprava videa',
      item4: 'Anotace'
    },
    v_18173: {
      title: 'Video management',
      item1: 'Spravování video boxů a endscreenů',
      item2: 'Přepínání mezi stránkami',
      item3: 'Nastavení časového rozmezí'
    },
    v_13949: {
      title: 'Infoboxy'
    },
    v_13953: {
      title: 'Vtipy'
    },
    v_61595: {
      title: 'Myšlenka týdne'
    },
    v_13950: {
      title: 'Ankety'
    },
    v_13951: {
      title: 'Anketa na HP'
    },
    v_13954: {
      title: 'Líbí / Nelíbí'
    },
    v_13952: {
      title: 'Kvízy',
      item1: 'Prezentace ke kvízům'
    },
    v_14925: {
      title: 'Sportovní tabulky'
    },
    v_17482: {
      title: 'Témata',
      item1: 'Dynamický listing',
      item2: 'Příležitostné akce - Např. Vánoce, Autosalon, sezónní recepty, profily sportovců',
      item3: 'Výběr metadat a klíčových slov vhodných z hlediska SEO'
    },
    v_17484: {
      title: 'Vozidla',
      item1: 'Modul vhodný nejen pro Autobild, ale i ostatní tituly',
      item2: 'Možnost psaní recenzí do tabulek',
      item3: 'Vytváření dalšího typu článku s názvem Testy'
    },
    v_28656: {
      title: 'Recepty'
    },
    v_50823: {
      title: 'Galerie',
      item1: 'Vytváření galerií v modulech a jejich vkládání do článků (i pro opětovné použití ve více článcích)'
    },
    v_17538: {
      title: 'Printové publikace',
      item1: 'postup nahrávání printových publikací grafiky do Eagle Admin',
      item2: 'vyhledávání a filtrování v seznamu',
      item3: 'vyplňování povinných polí'
    },
    v_17537: {
      title: 'Printové články',
      item1: 'hodnocení printových článků printovými asistentkami',
      item2: 'postup pro vyplňování jednotlivých povinných polí',
      item3: 'filtrování v seznamu stávajících hodnocených printových článků'
    },
    v_13947: {
      title: 'HP management',
      item1: 'Přepínání mezi stránkami',
      item2: 'Viditelné boxy, pohyb a granularita časové osy',
      item3: 'Interní a externí článek'
    },
    v_26426: {
      title: 'HP management - Topbox CTR',
      item1: 'Historické CTR pro Topbox pozice',
      item2: 'Realtime CTR pro články v Topbox pozicích'
    },
    v_28651: {
      title: 'HP nabídka článků',
      item1: 'PDF prezentace',
      item2: 'Rozhraní pro zařazení článku do Homepage nabídky článků',
      item3: 'Rozhraní schvalování článků pro Vedoucí jednotlivých oddělení',
      item4: 'Homepage nabídka článků (feed) pro OCM'
    },
    v_26425: {
      title: 'Management a vývoj kampaně',
      item1: 'Přidání/editace kampaně',
      item2: 'Ovládání a vývoj kampaně'
    },
    v_36247: {
      title: 'NMH KPI',
      item1: 'Plnění KPI',
      item2: 'Výkonnost v čase'
    },
    v_36206: {
      title: 'Statistiky produktu',
      item1: 'Porovnání s konkurencí na trhu',
      item2: 'Zdroje návštěv',
      item3: 'Produkce obsahu'
    },
    v_42377: {
      title: 'KPI',
      item1: 'KPI',
      item2: 'Výkony oddělení a autorů'
    }
  }
}

export default messages
