const messages = {
  list: {
    id: 'Id',
    title: 'Titulek',
    published_since: 'Publikováno od',
    published_until: 'Publikováno do',
    status: 'Stav',
    layout: 'Typ designu',
    active: 'Aktivní',
    inactive: 'Neaktivní',
    type: 'Typ',
    category: 'Kategorie',
    hidden: 'Skrytý',
    created_at: 'Datum vytvoření',
    created_by: 'Vytvořil'
  },
  filter: {
    title: 'Titulek',
    id: 'Id',
    status: 'Stav',
    layout: 'Typ designu',
    created_at_from: 'Vytvořené od',
    active: 'Aktivní',
    inactive: 'Neaktivní',
    category: 'Kategorie',
    search: 'Hledat'
  },
  enabled: 'Aktivovat',
  main_tab: 'Hlavní obsah',
  questions_results_tab: 'Kvízové otázky a hodnocení kvízu',
  one_question_tab: 'Otázka a odpovědi',
  question_number: '{number}. Otázka',
  answer_number: 'Odpověď {number}',
  id: 'Id',
  title: 'Titulek',
  title_placeholder: 'Napište název kvízu',
  published_since: 'Publikováno od',
  published_until: 'Publikováno do',
  description: 'Popis',
  image: 'Hlavní obrázek (zobrazený také v rubrice)',
  type: 'Typ hodnocení',
  category: 'Kategorie',
  layout: 'Typ designu',
  show_image: 'Zobrazení obrázku v detailu kvízu',
  rewriteArticle: 'Přepsat data v článku s daty z kvízu',
  questions_per_page: 'Počet otázek na stránce',
  categories: 'Obsahové kategorie',
  question_info_text: 'Pro přidání otázky je třeba kvíz nejprve uložit.',
  questions_info_text: 'Pro přidání otázek je třeba kvíz nejprve uložit.',
  settings: 'Nastavení',
  results_header: 'Hodnocení kvízu',
  result_number: '{number}. Hodnocení',
  result: 'Hodnocení',
  result_title: 'Text hodnocení',
  result_score: 'Minimální počet bodů',
  result_description: 'Popis',
  result_image: 'Obrázek k hodnocení',
  enter_minimum_two_answers: 'Prosím zadejte aspoň 2 odpovědi !',
  select_date: 'Vyberte datum',
  delete_result: 'Smazat hodnocení',
  add_result: 'Přidat hodnocení',
  question_list: 'Seznam otázek',
  question_title: 'Název otázky',
  question_description: 'Popis',
  question_image: 'Obrázek dotazy',
  answers: 'Odpovědi',
  answer: 'Odpověď',
  answer_title: 'Odpověď',
  answer_description: 'Popis',
  answer_points: 'Počet bodů',
  answer_image: 'Obrázek k odpovědi',
  add_question: 'Přidat otázku',
  remove_question: 'Smazat otázku',
  add_answer: 'Přidat odpověď',
  remove_answer: 'Smazat odpověď',
  create_quiz_article: 'Vytvořit kvízový článek',
  quiz_article_created: 'Kvízový článek byl vytvořen !',
  quiz_article_created_error: 'Chyba. Problem s vytvořením kvízového článku.',
  quiz_article_modal_header: 'Pro vytvoření článku je třeba kvíz aktivovat. Aktivovat teď?',
  yes: 'Ano',
  no: 'Ne',
  save_and_create_new: 'Uložit a vytvořit novou otázku',
  max_possible_number_of_points: 'Maximální možný počet bodů',
  main_content: 'Hlavní obsah',
  headline_used_in_articles: 'Použito v článku',
  quiz_article_not_found: 'Kvíz se nenachází v žádném článku.',
  quiz_article_exists: 'Pro kvíz již existuje článek!',
  quiz_type: {
    personality: 'Personalizovaný',
    correct_answer: 'Pravda/Nepravda',
    one_question: 'Jedna otázka s detailem'
  },
  deprecated_quiz_type_info: 'Po uložení nebude možné znovu nastavit "Personalizovaný", protože je tento typ zakázaný.',
  deprecated_quiz_layout_info: 'Po uložení nebude možné znovu nastavit "Image answers", protože je tento design zakázaný.',
  set_published_until: 'Nastavit vypnutí z webu',
  set_order_date_to_now: 'Nastav na teď',
  quiz_topic: 'Téma pro generování kvízu',
  quiz_topic_placeholder: 'Např. Hrady a zámky v Česku',
  quiz_generate: 'Generovat kvíz',
  quiz_generate_label: '20-30 sekund',
  notify: {
    quiz_fill_topic: 'Prosím, vyplňte téma kvízu.'
  }
}

export default messages
