const messages = {
  list: {
    id: 'Id',
    title: 'Titulok',
    short_title: 'Krátky popis',
    name: 'Názov',
    enabled: 'Povolené',
    created_at: 'Dátum vytvorenia',
    modified_at: 'Dátum poslednej zmeny',
    created_by: 'Vytvoril'
  },
  filter: {
    title: 'Titulok',
    name: 'Názov',
    id: 'Id',
    search: 'Hľadať'
  },
  id: 'Id',
  title: 'Titulok',
  name: 'Názov',
  enabled: 'Povolené',
  production: 'Produkčná prevádzka',
  enabled_sites: 'Zoznam povolených webov',
  shortTitle: 'Krátky popis',
  meta_title: 'Meta názov',
  meta_description: 'Meta popis',
  meta_keywords: 'Meta kľúčové slová'
}

export default messages
