import { ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR } from '../../model/ValueObject/UserRole'

const routes = [
  {
    path: 'infobox/new',
    component: () => import('../../views/infobox/InfoboxNewView'),
    name: 'infobox_new',
    meta: {
      description: 'Infobox new',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'infobox/:id/edit',
    component: () => import('../../views/infobox/InfoboxEditView'),
    name: 'infobox_edit',
    meta: {
      description: 'Infobox edit',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'infobox/:id',
    component: () => import('../../views/infobox/InfoboxView'),
    name: 'infobox_detail',
    meta: {
      description: 'Infobox detail',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  },
  {
    path: 'infobox',
    component: () => import('../../views/infobox/InfoboxListView'),
    name: 'infobox_list',
    meta: {
      description: 'Infobox list',
      requiresAuth: true,
      requiredRoles: [
        ROLE_SUPER_ADMIN, ROLE_JOURNALIST, ROLE_EDITOR
      ]
    }
  }
]

export default routes
